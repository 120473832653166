import { ProjectionLike } from 'ol/proj';

import { projectionCode, reprojectGeometry } from '../reproject';
import { mercatorPerimeter } from './perimeter-mercator';
import { sphericalPerimeter } from './perimeter-spherical';

export function perimeter(feature: GeoJSON.Feature | GeoJSON.Geometry, from: ProjectionLike, to?: ProjectionLike) {
  const projection = to ? projectionCode(to) : projectionCode(from);

  let geometry: GeoJSON.Geometry = feature['geometry'] ? feature['geometry'] : feature;

  if (projectionCode(from) !== projection) {
    geometry = reprojectGeometry(geometry, from, to);
  }

  return projection === 'EPSG:4326' ? sphericalPerimeter(geometry) : mercatorPerimeter(geometry);
}
