export type AREA_UNITS = 'ha' | 'ac' | 'm2' | 'ft2' | 'km2';

const SQUARE_METER_TO_HECTARE = 0.0001;
const SQUARE_METER_TO_ACRE = 0.000247105381;
const SQUARE_METER_TO_SQUARE_KILOMETER = 0.000001;
const SQUARE_METER_TO_SQUARE_FOOT = 10.763910417;

function round(value: number, precision: number): number {
  const multiplicator = Math.pow(10, precision);
  return Math.round(value * multiplicator) / multiplicator;
}

export function squareMeterToHectare(value: number): number {
  return value * SQUARE_METER_TO_HECTARE;
}

function squareMeterToAcre(value: number): number {
  return value * SQUARE_METER_TO_ACRE;
}

function squareMeterToSquareKilometer(value: number): number {
  return value * SQUARE_METER_TO_SQUARE_KILOMETER;
}

function squareMeterToSquareFoot(value: number): number {
  return value * SQUARE_METER_TO_SQUARE_FOOT;
}

function isInputValid(value: number): boolean {
  return !(value < 0 || typeof value !== 'number');
}

export function convertAreaTo(value: number, unit: AREA_UNITS, precision: number = 2): string {
  if (!isInputValid(value)) {
    return '';
  }

  let recalculatedArea = value;
  let formattedUnit: string = unit;

  switch (unit) {
    case 'ha':
      recalculatedArea = squareMeterToHectare(value);
      break;
    case 'ac':
      recalculatedArea = squareMeterToAcre(value);
      break;
    case 'm2':
      formattedUnit = 'm²';
      break;
    case 'ft2':
      recalculatedArea = squareMeterToSquareFoot(value);
      formattedUnit = 'ft²';
      break;
    case 'km2':
      recalculatedArea = squareMeterToSquareKilometer(value);
      formattedUnit = 'km²';
      break;
  }

  return `${round(recalculatedArea, precision).toFixed(precision)} ${formattedUnit}`;
}
