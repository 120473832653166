import { Pipe, PipeTransform } from '@angular/core';
import { PERIMETER_UNITS } from '@core/api';
import { convertPerimeterTo } from '../services/perimeter-converter.service';

@Pipe({ name: 'laPerimeter' })
export class PerimeterPipe implements PipeTransform {
  transform(value: string | number, unit: PERIMETER_UNITS = 'm', decimalPlaces: number = 2): string | number {
    const parsedInput = this.parseInput(value);
    return parsedInput !== null ? convertPerimeterTo(parsedInput, unit, decimalPlaces) : value;
  }

  private parseInput(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }

    const parsedFloat = parseFloat(value);
    if (!isNaN(parsedFloat)) {
      return parsedFloat;
    }

    return null;
  }
}
