import { Component, Input } from '@angular/core';
import { AppError, AppErrorCodes } from '@core/api';

@Component({
  selector: 'la-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
})
export class PageErrorComponent {
  @Input() error: AppError;

  public readonly CODES = AppErrorCodes;
}
