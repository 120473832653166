import { ProjectionLike } from 'ol/proj';
import { AREA_UNITS } from '../../geometry/area';
import { PERIMETER_UNITS } from '../../geometry/perimeter';
import { StyleConfig } from './style.model';

export interface MetricUnits {
  area?: AREA_UNITS;
  perimeter?: PERIMETER_UNITS;
}

export interface FeatureProperties {
  name?: string;
  description?: string;
  area?: number;
  perimeter?: number;
  laFeatureType?: string;
  laStyle?: StyleConfig;
  laStyleType?: 'custom' | 'default';
  laCentroid?: boolean;
  laCenterPointVisible?: boolean; // @deprecated
  laNotes?: string[];
  laUnits?: MetricUnits;
  sheetId?: string;
  parcelId?: string;
  laPeatUnit: string;
  laCodeEmis: string;
  laEmissVal: string;
  laEmission: string;
  [key: string]: any;
}

export type AnnotateMapperFunc = (
  key: string,
  feature: GeoJSON.Feature,
  fp?: ProjectionLike,
  cp?: ProjectionLike,
  units?: MetricUnits
) => string;
