<h1 mat-dialog-title>{{ error.title }}</h1>

<mat-dialog-content>
  <p>{{ error.message }}</p>

  <section *ngIf="error.details" class="details">
    <label>Details</label>
    {{ error.details }}
  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
