import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import Mask from 'ol-ext/filter/Mask';
import Transform from 'ol-ext/interaction/Transform';
import Collection from 'ol/Collection';
import { always } from 'ol/events/condition';
import { getCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Subject } from 'rxjs';
import { delay, takeUntil, throttleTime } from 'rxjs/operators';
import { area as calculateArea } from '~/libs/geometry/area';
import { extentDimensions, extentToGeometry, getExtentCoordinates } from '~/libs/geometry/extent';
import { Extent, PrintExtent } from '../../models/extent.model';
import { textOnPoint } from '../../styles/text.styles';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';

type Margins = { top: number; bottom: number; left: number; right: number };
type SelectEvent = { feature?: Feature };

export type PAPER_SIZE =
  | 'A0_LANDSCAPE'
  | 'A0_PORTRAIT'
  | 'A1_LANDSCAPE'
  | 'A1_PORTRAIT'
  | 'A2_LANDSCAPE'
  | 'A2_PORTRAIT'
  | 'A3_LANDSCAPE'
  | 'A3_PORTRAIT'
  | 'A4_LANDSCAPE'
  | 'A4_PORTRAIT'
  | 'A5_LANDSCAPE'
  | 'A5_PORTRAIT';

const PAPER_SIZES = {
  A0_LANDSCAPE: [1189, 841],
  A0_PORTRAIT: [841, 1189],
  A1_LANDSCAPE: [841, 594],
  A1_PORTRAIT: [594, 841],
  A2_LANDSCAPE: [594, 420],
  A2_PORTRAIT: [420, 594],
  A3_LANDSCAPE: [420, 297],
  A3_PORTRAIT: [297, 420],
  A4_LANDSCAPE: [297, 210],
  A4_PORTRAIT: [210, 297],
  A5_LANDSCAPE: [210, 148],
  A5_PORTRAIT: [148, 210],
};

const SQRT2 = Math.sqrt(2);

const boringStyle = new Style({
  stroke: new Stroke({
    color: [255, 0, 0, 1],
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.02)',
  }),
  zIndex: 10000,
});

@Component({
  selector: 'la-map-paper-extent',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPaperBboxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() paperSize: PAPER_SIZE;
  @Input() margins?: Margins;
  @Input() initialExtent?: Extent;
  @Input() scale?: number;
  @Input() editable: boolean = true;

  @Output() setExtent = new EventEmitter<PrintExtent>();

  private instance: Transform;
  private mask: Mask;
  private extent: Extent;

  private sketchLayer = new VectorLayer({
    source: new VectorSource({
      features: [],
    }),
    updateWhileInteracting: true,
    style: (feature: Feature, resolution: number) => {
      const extent = feature.getGeometry().getExtent();

      const width = extent[2] - extent[0];
      const height = extent[3] - extent[1];

      const a = (extent[0] * 1.96 + extent[2] * 0.04) / 2;
      const b = (width > height ? extent[1] * 1.93 + extent[3] * 0.07 : extent[1] * 1.96 + extent[3] * 0.04) / 2;

      const point = new Point([a, b]);
      const factor = (extent[2] - extent[0] + (extent[3] - extent[1])) / (resolution * 70);

      const labelStyle = textOnPoint('Border', point, {
        textAlign: 'left',
        font: `${factor}px Roboto`,
        fill: new Fill({ color: '#ff0000' }),
        stroke: new Stroke({
          color: '#ff0000',
          width: 1,
        }),
      });
      return [boringStyle, labelStyle];
    },
    zIndex: 9999,
  });

  private readonly event$ = new Subject<PrintExtent>();
  private readonly destroy$ = new Subject<void>();

  constructor(private host: MapComponent) {}

  ngOnInit() {
    this.event$.pipe(delay(16), throttleTime(100), takeUntil(this.destroy$)).subscribe((data) => this.setExtent.emit(data));

    this.extent = this.getPrintingExtent(this.initialExtent, this.paperSize, this.scale);
    this.clipRectangle(this.extent);
    this.zoomToExtent(this.extent);
  }

  ngOnDestroy() {
    this.cleanPreviousMaskIfExists();
    this.sketchLayer.getSource().clear();
    this.host.instance.removeLayer(this.sketchLayer);

    if (this.instance) {
      this.host.instance.removeInteraction(this.instance);
      this.instance = undefined;
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.paperSize && !changes.paperSize.firstChange) ||
      (changes.margins && !changes.margins.firstChange) ||
      (changes.scale && !changes.scale.firstChange)
    ) {
      this.updatePaperFeature();
      this.event$.next(this.getPrintExtentResult());
    }

    if (changes.scale && !changes.scale.firstChange) {
      if (changes.scale.currentValue && this.editable) {
        this.instance.set('scale', false);
      } else {
        this.instance.set('scale', true);
      }
    }

    if (changes.initialExtent && !changes.initialExtent.firstChange) {
      this.extent = this.initialExtent;
      this.updatePaperFeature();

      if (!this.editable) {
        this.zoomToExtent(this.extent);
      }
    }
  }

  private updatePaperFeature() {
    this.extent = this.getPrintingExtent(this.extent, this.paperSize, this.scale);

    const updated = this.getPaperFeature(this.extent);
    const feature = this.sketchLayer.getSource().getFeatures()[0];

    feature.setGeometry(updated.getGeometry());
    feature.changed();

    this.sketchLayer.changed();
  }

  private fitPaperSize(extent: Extent | undefined, paperSize: PAPER_SIZE, isInit: boolean): [number, number] {
    const dimentions = extentDimensions(extent);
    const isLandscape = /_LANDSCAPE/.test(paperSize);

    if (isInit) {
      return [dimentions.height * SQRT2, dimentions.height];
    }

    if (dimentions.width > dimentions.height) {
      return isLandscape ? [dimentions.width, dimentions.height] : [dimentions.height, dimentions.width];
    }
    return isLandscape ? [dimentions.height, dimentions.width] : [dimentions.width, dimentions.height];
  }

  private getPrintingExtent(extent: Extent, paperSize: PAPER_SIZE, fixedScale?: number): Extent {
    const isInit = !extent;

    extent = extent ? extent : this.host.instance.getView().calculateExtent(this.host.instance.getSize());

    let [width, height] = this.fitPaperSize(extent, paperSize, isInit);

    if (fixedScale) {
      const [paperWidth, paperHeight] = PAPER_SIZES[paperSize];
      width = (paperWidth * fixedScale) / 1000;
      height = (paperHeight * fixedScale) / 1000;
    }

    const bottomLeft = new Point(getCenter(extent));
    bottomLeft.translate(-width / 2, -height / 2);

    const topRight = new Point(getCenter(extent));
    topRight.translate(width / 2, height / 2);

    return [...bottomLeft.getCoordinates(), ...topRight.getCoordinates()] as Extent;
  }

  private clipRectangle(extent: Extent) {
    const feature = this.getPaperFeature(extent);

    this.sketchLayer.getSource().addFeatures([feature]);
    this.applyMask(feature);
    this.host.instance.addLayer(this.sketchLayer);

    this.instance = new Transform({
      layers: [this.sketchLayer],
      features: new Collection([feature]),
      scale: this.editable,
      translate: this.editable,
      stretch: false,
      rotate: false,
      keepAspectRatio: always,
      translateFeature: false,
    });

    addInteraction(this.host.instance, this.instance);

    this.instance.select(feature, true);
    this.instance.on('select', (e: SelectEvent) => {
      if (e?.feature === undefined) {
        this.instance.select(feature, true);
      }
    });

    this.instance.on(['scaleend', 'scaling', 'translateend', 'translating'], (e: SelectEvent) => {
      this.extent = e.feature?.getGeometry()?.getExtent();
      this.event$.next(this.getPrintExtentResult());
    });

    this.event$.next(this.getPrintExtentResult());
  }

  public getScale(paperSize: PAPER_SIZE, printWidth: number) {
    const paperWidth = PAPER_SIZES[paperSize][0];
    return Math.round((printWidth / paperWidth) * 1000);
  }

  private getPrintExtentResult(): PrintExtent {
    const { width, height } = extentDimensions(this.extent);
    const inner = this.calculateInnerExtent(this.extent, this.margins);
    const area = calculateArea(extentToGeometry(inner), this.host.projection.view, this.host.projection.calculate);
    const scale = this.getScale(this.paperSize, width);

    return {
      extent: this.extent,
      width,
      height,
      scale,
      area,
    };
  }

  private getPaperFeature(extent: Extent): Feature {
    const inner = this.calculateInnerExtent(extent, this.margins);
    const outerCoordinates: [number, number][] = getExtentCoordinates(extent);
    const innerCoordinates: [number, number][] = getExtentCoordinates(inner);

    return new Feature(
      new MultiPolygon([
        [outerCoordinates, innerCoordinates],
        [innerCoordinates, outerCoordinates, innerCoordinates],
      ])
    );
  }

  private calculateInnerExtent(extent: Extent, margins: Margins): Extent {
    const dimentions = extentDimensions(extent);

    return [
      extent[0] + dimentions.width * (margins.left / 100),
      extent[1] + dimentions.height * (margins.bottom / 100),
      extent[2] - dimentions.width * (margins.right / 100),
      extent[3] - dimentions.height * (margins.top / 100),
    ];
  }

  private applyMask(feature: Feature) {
    this.cleanPreviousMaskIfExists();

    this.mask = new Mask({
      feature,
      inner: false,
      fill: new Fill({ color: [0, 0, 0, 0.4] }),
    });

    (this.sketchLayer as any).addFilter(this.mask);
  }

  private cleanPreviousMaskIfExists() {
    if (this.mask) {
      (this.sketchLayer as any).removeFilter(this.mask);
    }
  }

  private zoomToExtent(extent?: Extent) {
    setTimeout(() => {
      this.host.instance.getView().fit(extent);
      const zoom = this.host.instance.getView().getZoom();
      this.host.instance.getView().setZoom(zoom - 0.05);
    }, 50);
  }
}
