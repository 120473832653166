import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'la-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBarComponent {
  @Input() actionButton: string;
  @Input() actionButtonDisabled: boolean;
  @Input() showClear: boolean = true;
  @Input() tooltip?: string;
  @Input() clearButtonText?: string;
  @Input() buttonSecondaryText?: boolean = false;
  @Input() showRightActionButton?: boolean = false;
  @Input() rightActionButtonText?: string;

  @Output() actionButtonClicked = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  @Output() rightActionButtonClicked = new EventEmitter<void>();
}
