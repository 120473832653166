import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/config';

import { OrganisationType, ProjectTemplate, Sector, SubscriptionsPrices } from '../models/configs.model';
import { BaseLayer, DataLayer } from '../models/layer.model';
import { PrintBorder } from '../models/print.model';
import { deserialise } from '../operators/deserialise.operator';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigApiService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private http: HttpClient
  ) {}

  getLayers() {
    return this.http.post<{ basemaps: BaseLayer[]; layers: DataLayer[] }>(`${this.config.api.layerUrl}/config`, {});
  }

  getProjectTemplates() {
    return this.http.get<ProjectTemplate[]>(`${this.config.api.mapUrl}/project-definitions`).pipe(
      deserialise(),
      map((templates) => {
        return templates.map((template) => ({
          ...template,
          data: template.data.map((data) => ({
            ...data,
            code: data.code ? data.code : data.id,
          })),
        }));
      })
    );
  }

  getSectors(withTemplates: boolean) {
    return this.http.get<Sector[]>(`${this.config.api.mapUrl}/sectors?withTemplates=${withTemplates}`).pipe(deserialise());
  }

  getOrganisationTypes() {
    return this.http.get<OrganisationType[]>(`${this.config.api.mapUrl}/organisation-types`).pipe(deserialise());
  }

  getSubscriptionsPrices() {
    return this.http.get<SubscriptionsPrices>(`${this.config.api.mapUrl}/users/subscriptions/price`).pipe(deserialise());
  }

  getPrintBorders() {
    return this.http.get<PrintBorder[]>(`${this.config.api.mapUrl}/prints/borders`).pipe(deserialise());
  }
}
