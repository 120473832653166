import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCredits from '../reducers/credits.reducer';
import { AppErrorCodes } from '@core/api';

export const getCreditsState = createFeatureSelector<fromCredits.CreditsState>(fromCredits.featureKey);

export const getCreditsBalance = createSelector(
  getCreditsState,
  (state) => state?.balance?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) || '0'
);

export const getCreditsVersion = createSelector(getCreditsState, (state) => state.version);

const getBuyCreditsAction = createSelector(getCreditsState, ({ buyAction }) => buyAction);
export const getBuyCreditsReady = createSelector(getBuyCreditsAction, ({ status }) => status === 'READY');
export const getBuyCreditsSaving = createSelector(getBuyCreditsAction, ({ status }) => status === 'LOADING');
export const getBuyCreditsError = createSelector(getBuyCreditsAction, ({ status, error }) => (status === 'ERROR' ? error : undefined));

const getPaymentMethodAction = createSelector(getCreditsState, ({ paymentMethodAction }) => paymentMethodAction);
export const getPaymentMethodStatus = createSelector(
  getBuyCreditsAction,
  getPaymentMethodAction,
  (buyAction, payAction) =>
    buyAction.status === 'ERROR' &&
    (buyAction.error?.code === AppErrorCodes.PAYMENT_FAILED || buyAction.error?.code === AppErrorCodes.PAYMENT_METHOD_EXPIRED) &&
    (payAction.status === 'INIT' || payAction.status === 'LOADING' || payAction.status === 'ERROR')
);
export const getValidPaymentMethodAfterChange = createSelector(getPaymentMethodAction, (state) => state.status === 'READY');
