import { get, isEmpty, isNil } from 'lodash';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { ProjectionLike } from 'ol/proj';
import { Stroke, Style } from 'ol/style';
import { area as calculateArea, convertAreaTo } from '~/libs/geometry/area';
import { convertPerimeterTo, perimeter as calculatePerimeter } from '~/libs/geometry/perimeter';
import { AnnotateMapperFunc, FeatureProperties, MetricUnits } from '../models/properties.model';
import { dateToShortFormat, isDate } from '../utils/date.utils';
import { toGeoJSON } from '../utils/feature.utils';
import { textOnPoint } from './text.styles';

function getAttributeText(
  key: string,
  feature: GeoJSON.Feature<GeoJSON.Geometry>,
  fp: ProjectionLike,
  cp: ProjectionLike,
  units: MetricUnits
) {
  if (!feature) {
    return '';
  }

  if (key === 'area') {
    return convertAreaTo(calculateArea(feature.geometry, fp, cp), units.area || 'ha');
  } else if (key === 'perimeter') {
    return convertPerimeterTo(calculatePerimeter(feature.geometry, fp, cp), units.perimeter || 'm');
  } else if (key === 'laFieldId') {
    if (feature.properties.sheetId && feature.properties.parcelId) {
      return `${feature.properties.sheetId} ${feature.properties.parcelId}`;
    }
  }

  const value: string | number | undefined = get(feature.properties, [key]);
  let text = isNil(value) ? '' : `${value}`;

  if (isDate(text)) {
    text = dateToShortFormat(new Date(text));
  }

  return text;
}

export function buildAttributeStyles(
  feature: Feature<Geometry>,
  fp: ProjectionLike,
  cp: ProjectionLike,
  scale = 1,
  annotateMapper?: AnnotateMapperFunc
): Style[] {
  const properties: FeatureProperties = feature['values_'] || {};
  const geometry = feature.getGeometry();

  if (!geometry) {
    return [];
  }

  const jsonFeature = toGeoJSON(feature);
  const laNotes = properties?.laNotes && Array.isArray(properties.laNotes) ? properties.laNotes : [];
  const laUnits = properties?.laUnits && !isEmpty(properties.laUnits) ? properties.laUnits : {};

  const styles: Style[] = [];

  let offset: number;
  let centerPoint: Point | undefined;

  if (geometry instanceof Point) {
    offset = -20;
    centerPoint = geometry;
  } else if (geometry instanceof MultiPoint) {
    offset = -20;
    centerPoint = new Point(geometry.getFirstCoordinate());
  } else if (geometry instanceof LineString) {
    offset = -20;
    centerPoint = new Point(geometry.getLastCoordinate());
  } else if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
    offset = properties?.laCentroid ? -20 : 0;
    const centerCoordinates =
      geometry instanceof Polygon ? geometry.getInteriorPoint().getCoordinates() : geometry.getInteriorPoints().getCoordinates()[0];
    centerPoint = new Point(centerCoordinates);
  }

  laNotes.forEach((key) => {
    let text: string;

    if (annotateMapper) {
      text = annotateMapper(key, jsonFeature, fp, cp, laUnits);
    }

    if (!text) {
      text = getAttributeText(key, jsonFeature, fp, cp, laUnits);
    }

    if (text && centerPoint) {
      styles.push(
        textOnPoint(text, centerPoint, {
          offsetY: offset,
          font: '13px Roboto',
          stroke: new Stroke({ color: '#ffffff', width: 5 }),
          textBaseline: 'bottom',
          scale,
        })
      );

      const lines = (text.match(/\n/g) || '').length + 1;
      offset = offset - lines * 18 * scale;
    }
  });

  return styles;
}
