import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { click } from 'ol/events/condition';
import Select, { Options, SelectEvent } from 'ol/interaction/Select';
import RenderFeature from 'ol/render/Feature';
import Feature from 'ol/Feature';

import { MapComponent } from '../map/map.component';

export class MapPick extends Select {
  constructor(options: Options) {
    super(options);

    this['applySelectedStyle_'] = () => {};
    this['restorePreviousStyle_'] = () => {};
  }
}

@Component({
  selector: 'la-map-pick',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPickComponent implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;
  @Input() from: string;

  @Output() picked = new EventEmitter<{ id: string | number; properties: GeoJSON.GeoJsonProperties }>();

  private instance: MapPick;
  private previouslyVisibleIds: string[] = [];

  constructor(private host: MapComponent) {}

  ngOnInit(): void {
    this.instance = new MapPick({
      condition: click,
      filter: (feature) => feature instanceof RenderFeature || feature instanceof Feature,
    });

    this.host.instance.addInteraction(this.instance);

    this.instance.setActive(this.active);
    this.instance.on('select', (event) => this.handlePick(event));

    this.previouslyVisibleIds = this.getVisibleLayerIds();
    this.setVisibleLayers([this.from]);
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);
    this.setVisibleLayers(this.previouslyVisibleIds);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);
    }

    if (changes.from && !changes.from.firstChange) {
      this.setVisibleLayers([changes.from.currentValue]);
    }
  }

  private handlePick(event: SelectEvent) {
    const [feature] = event.target.getFeatures().getArray();
    this.instance.getFeatures().clear();

    if (feature) {
      this.picked.emit({
        id: feature.getId(),
        properties: this.getProperties(feature),
      });
    } else {
      this.picked.emit(null);
    }
  }

  private getProperties(feature: Feature | RenderFeature) {
    const props = feature.getProperties();
    delete props['geometry'];
    return props;
  }

  private getVisibleLayerIds() {
    return this.host.instance
      .getLayers()
      .getArray()
      .filter((layer) => layer.getVisible())
      .map((layer) => {
        const properties = layer.getProperties();
        return properties ? properties.id : undefined;
      })
      .filter((id) => !!id);
  }

  private setVisibleLayers(visibleIds: string[]) {
    this.host.instance.getLayers().forEach((layer) => {
      const properties = layer.getProperties();

      if (!properties.id) {
        return;
      }

      layer.setVisible(visibleIds.includes(properties.id));
    });
  }
}
