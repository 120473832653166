import Feature from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';

import { area as calculateArea } from '~/libs/geometry/area';
import { perimeter as calculatePerimeter } from '~/libs/geometry/perimeter';

import { MapComponent } from '../components/map/map.component';
import { toGeoJSONGeometry } from '../utils/feature.utils';
import { getObjectId } from '../utils/id.utils';

export abstract class MapInteractionAbstract {
  constructor(protected host: MapComponent) {}

  protected getLayerById(id: string) {
    return this.host.instance
      .getLayers()
      .getArray()
      .find((layer: TileLayer | VectorLayer | VectorTileLayer) => {
        const properties = layer.getProperties();
        return properties && properties.id === id;
      }) as TileLayer | VectorLayer | VectorTileLayer;
  }

  protected enrichFeature(feature: Feature) {
    if (!feature.getId()) {
      feature.setId(getObjectId());
    }

    const geometry = toGeoJSONGeometry(feature);
    const measurements = this.calculateMeasurements(geometry);

    feature.setProperties(measurements);
  }

  private calculateMeasurements(geometry: GeoJSON.Geometry) {
    let measurements: { area?: number; perimeter?: number } = {};

    switch (geometry.type) {
      case 'GeometryCollection':
      case 'MultiPolygon':
      case 'Polygon':
        measurements = {
          area: calculateArea(geometry, this.host.projection.view, this.host.projection.calculate),
          perimeter: calculatePerimeter(geometry, this.host.projection.view, this.host.projection.calculate),
        };
        break;
      case 'MultiLineString':
      case 'LineString':
        measurements = {
          perimeter: calculatePerimeter(geometry, this.host.projection.view, this.host.projection.calculate),
        };
        break;
    }

    return measurements;
  }
}
