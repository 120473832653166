import Tile from 'ol/layer/Tile';
import Bing from 'ol/source/BingMaps';

import { environment } from '@front-environments/environment';
import { BingLayerDefinition } from '../models/layer.model';

type BingLayerConfig = BingLayerDefinition & { visible?: boolean; opacity?: number };

export const bingSource = (config: BingLayerConfig) => {
  const { imagerySet = 'Aerial', maxZoom = 19, visible, opacity } = config;

  const options = {
    source: new Bing({
      key: environment.layers.bingKey,
      imagerySet,
      maxZoom,
    }),
    visible,
    opacity,
  };

  return new Tile(options);
};
