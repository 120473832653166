export type PERIMETER_UNITS = 'm' | 'mi' | 'km' | 'ft';

const METER_TO_MILE = 0.000621371192;
const METER_TO_KILOMETER = 0.001;
const METER_TO_FOOT = 3.280839895;

function round(value: number, precision: number): number {
  const multiplicator = Math.pow(10, precision);
  return Math.round(value * multiplicator) / multiplicator;
}

function meterToMile(value: number): number {
  return value * METER_TO_MILE;
}

function meterToKilometer(value: number): number {
  return value * METER_TO_KILOMETER;
}

function meterToFoot(value: number): number {
  return value * METER_TO_FOOT;
}

function isInputValid(value: number): boolean {
  return !(value < 0 || typeof value !== 'number');
}

export function convertPerimeterTo(value: number, unit: PERIMETER_UNITS, precision: number = 2): string {
  if (!isInputValid(value)) {
    return '';
  }

  let recalculatedArea = value;

  switch (unit) {
    case 'mi':
      recalculatedArea = meterToMile(value);
      break;
    case 'km':
      recalculatedArea = meterToKilometer(value);
      break;
    case 'ft':
      recalculatedArea = meterToFoot(value);
      break;
  }

  return `${round(recalculatedArea, precision).toFixed(precision)} ${unit}`;
}
