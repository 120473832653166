import { Feature, Plan, ProjectTemplate, UserLayer } from '@core/api';
import { SelectedGeoJSONFeature, SelectedRenderFeature } from '~/libs/open-layers';

export function toGeoJSONFeature({ id, geometry, properties, createdAt, updatedAt }: Feature): GeoJSON.Feature {
  return {
    id,
    type: 'Feature',
    geometry,
    properties: { ...properties, createdAt, updatedAt },
  };
}

export function convertUserLayerToPlan(layer: UserLayer, features: GeoJSON.Feature[] = []): Plan {
  return {
    ...layer,
    geojson: {
      type: 'FeatureCollection',
      features: features ? features : [],
    },
  };
}

export function isRenderFeatureSelected(feature: SelectedGeoJSONFeature | SelectedRenderFeature): feature is SelectedRenderFeature {
  return feature.type === 'RenderFeature';
}

export function isPlanReadonly(plan: Plan, templates: ProjectTemplate[]) {
  const template = templates?.find((t) => t.id === plan?.subtype);

  return !plan || plan.readonly || !template || template.type === 'survey';
}
