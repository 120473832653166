import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'la-panel-header',
  styleUrls: ['./panel-header.component.scss'],
  templateUrl: './panel-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelHeaderComponent {
  @Input() title: string;
  @Input() back = true;
  @Input() link = '../';
  @Input() backButton = false;

  @Output() closing = new EventEmitter();
}
