type Coordinates = number[] | number[][] | number[][][] | number[][][][];

export function hasPointCoordinate(coordinates: Coordinates): coordinates is number[] {
  return coordinates[0] && typeof coordinates[0] === 'number';
}

export function hasSingleCoordinates(coordinates: Coordinates): coordinates is number[][] {
  return coordinates[0] && typeof coordinates[0][0] === 'number';
}

export function hasSimpleCoordinates(coordinates: Coordinates): coordinates is number[][][] {
  return coordinates[0] && coordinates[0][0] && typeof coordinates[0][0][0] === 'number';
}

export function hasMultiCoordinates(coordinates: Coordinates): coordinates is number[][][][] {
  return coordinates[0] && coordinates[0][0] && coordinates[0][0][0] && typeof coordinates[0][0][0][0] === 'number';
}

export function flatten(coordinates: Coordinates): number[][] {
  if (hasPointCoordinate(coordinates)) {
    return [coordinates];
  } else if (hasSingleCoordinates(coordinates)) {
    return coordinates;
  } else if (hasSimpleCoordinates(coordinates)) {
    return [].concat.apply([], coordinates);
  } else if (hasMultiCoordinates(coordinates)) {
    return [].concat.apply([], [].concat.apply([], coordinates));
  }

  return undefined;
}
