import { Interaction, Snap } from 'ol/interaction';
import Map from 'ol/Map';

export function addInteraction(map: Map, interaction: Interaction) {
  const collection = map.getInteractions();

  const snapIndex = collection.getArray().findIndex((i) => i instanceof Snap);

  if (snapIndex === -1 || interaction instanceof Snap) {
    collection.push(interaction);
  } else {
    collection.insertAt(snapIndex, interaction);
  }
}
