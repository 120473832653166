import { TinyColor } from '@ctrl/tinycolor';

export interface PatternConfig {
  fillColor: string;
  fillOpacity?: number;
  patternColor: string;
  patternOpacity?: number;
}

export interface PatternOptions extends PatternConfig {
  size?: number;
  divisions?: number;
  style?: string;
  direction?: string;
}

export const PATTERN_NAMES: string[][] = [
  ['diagonal-1', 'diagonal-2', 'diagonal-3', 'diagonal-4', 'diagonal-5', 'diagonal-6'],
  ['diagonal-7', 'diagonal-8', 'diagonal-9', 'diagonal-10', 'diagonal-11', 'diagonal-12'],
  ['vertical-1', 'vertical-2', 'vertical-3', 'vertical-4', 'vertical-5', 'vertical-6'],
  ['horizontal-1', 'horizontal-2', 'horizontal-3', 'horizontal-4', 'horizontal-5', 'horizontal-6'],
  ['plus-1', 'plus-2', 'plus-3', 'plus-4', 'plus-5', 'plus-6'],
  ['cross-1', 'cross-2', 'cross-3', 'cross-4', 'cross-5', 'cross-6'],
  ['circle-1', 'circle-2', 'circle-3', 'circle-4', 'circle-5', 'circle-6'],
  ['circle-7', 'circle-8', 'circle-9', 'circle-10', 'circle-11', 'circle-12'],
];

const cache = new Map();

const color = (value: string, opacity?: number) => {
  return opacity === undefined ? value : new TinyColor(value).setAlpha(opacity).toRgbString();
};

const defaultPattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity } = options;
  const WIDTH = 16;
  const HEIGHT = 16;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  return ctx.createPattern(canvas, 'repeat');
};

const diagonalPattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions, direction } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  ctx.fillStyle = color(patternColor, patternOpacity);

  if (direction === 'right') {
    // Top line
    ctx.beginPath();
    ctx.moveTo(0, HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH * (1 / divisions), 0);
    ctx.lineTo(0, 0);
    ctx.lineTo(0, HEIGHT * (1 / divisions));
    ctx.fill();

    // Middle line
    ctx.beginPath();
    ctx.moveTo(WIDTH, HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH * (1 / divisions), HEIGHT);
    ctx.lineTo(0, HEIGHT);
    ctx.lineTo(0, HEIGHT * ((divisions - 1) / divisions));
    ctx.lineTo(WIDTH * ((divisions - 1) / divisions), 0);
    ctx.lineTo(WIDTH, 0);
    ctx.lineTo(WIDTH, HEIGHT * (1 / divisions));
    ctx.fill();

    // Bottom line
    ctx.beginPath();
    ctx.moveTo(WIDTH, HEIGHT * ((divisions - 1) / divisions));
    ctx.lineTo(WIDTH * ((divisions - 1) / divisions), HEIGHT);
    ctx.lineTo(WIDTH, HEIGHT);
    ctx.lineTo(WIDTH, HEIGHT * ((divisions - 1) / divisions));
    ctx.fill();
  } else {
    // Top line
    ctx.beginPath();
    ctx.moveTo(WIDTH, HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH * ((divisions - 1) / divisions), 0);
    ctx.lineTo(WIDTH, 0);
    ctx.lineTo(WIDTH, HEIGHT * (1 / divisions));
    ctx.fill();

    // Middle line
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH * ((divisions - 1) / divisions), HEIGHT);
    ctx.lineTo(WIDTH, HEIGHT);
    ctx.lineTo(WIDTH, HEIGHT * ((divisions - 1) / divisions));
    ctx.lineTo(WIDTH * (1 / divisions), 0);
    ctx.lineTo(0, 0);
    ctx.fill();

    // Bottom line
    ctx.beginPath();
    ctx.moveTo(0, HEIGHT * ((divisions - 1) / divisions));
    ctx.lineTo(WIDTH * (1 / divisions), HEIGHT);
    ctx.lineTo(0, HEIGHT);
    ctx.lineTo(0, HEIGHT * ((divisions - 1) / divisions));
    ctx.fill();
  }

  return ctx.createPattern(canvas, 'repeat');
};

const linePattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions, direction } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  ctx.fillStyle = color(patternColor, patternOpacity);

  if (direction === 'vertical') {
    ctx.beginPath();
    ctx.moveTo(WIDTH / 2 - WIDTH * (1 / divisions), 0);
    ctx.lineTo(WIDTH / 2 + WIDTH * (1 / divisions), 0);
    ctx.lineTo(WIDTH / 2 + WIDTH * (1 / divisions), HEIGHT);
    ctx.lineTo(WIDTH / 2 - WIDTH * (1 / divisions), HEIGHT);
    ctx.lineTo(WIDTH / 2 - WIDTH * (1 / divisions), 0);
    ctx.fill();
  } else {
    ctx.beginPath();
    ctx.moveTo(0, HEIGHT / 2 - HEIGHT * (1 / divisions));
    ctx.lineTo(0, HEIGHT / 2 + HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH, HEIGHT / 2 + HEIGHT * (1 / divisions));
    ctx.lineTo(WIDTH, HEIGHT / 2 - HEIGHT * (1 / divisions));
    ctx.lineTo(0, HEIGHT / 2 - HEIGHT * (1 / divisions));
    ctx.fill();
  }

  return ctx.createPattern(canvas, 'repeat');
};

const plusPattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  ctx.fillStyle = color(patternColor, patternOpacity);

  ctx.beginPath();
  ctx.moveTo(WIDTH / 2 - WIDTH * (1 / divisions), 0);
  ctx.lineTo(WIDTH / 2 + WIDTH * (1 / divisions), 0);
  ctx.lineTo(WIDTH / 2 + WIDTH * (1 / divisions), HEIGHT);
  ctx.lineTo(WIDTH / 2 - WIDTH * (1 / divisions), HEIGHT);
  ctx.lineTo(WIDTH / 2 - WIDTH * (1 / divisions), 0);
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(0, HEIGHT / 2 - HEIGHT * (1 / divisions));
  ctx.lineTo(0, HEIGHT / 2 + HEIGHT * (1 / divisions));
  ctx.lineTo(WIDTH, HEIGHT / 2 + HEIGHT * (1 / divisions));
  ctx.lineTo(WIDTH, HEIGHT / 2 - HEIGHT * (1 / divisions));
  ctx.lineTo(0, HEIGHT / 2 - HEIGHT * (1 / divisions));
  ctx.fill();

  return ctx.createPattern(canvas, 'repeat');
};

const crossPattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  ctx.fillStyle = color(patternColor, patternOpacity);

  ctx.beginPath();
  ctx.moveTo(WIDTH, HEIGHT * (1 / divisions));
  ctx.lineTo(WIDTH * (1 / divisions), HEIGHT);
  ctx.lineTo(0, HEIGHT);
  ctx.lineTo(0, HEIGHT * ((divisions - 1) / divisions));
  ctx.lineTo(WIDTH * ((divisions - 1) / divisions), 0);
  ctx.lineTo(WIDTH, 0);
  ctx.lineTo(WIDTH, HEIGHT * (1 / divisions));
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(0, HEIGHT * (1 / divisions));
  ctx.lineTo(WIDTH * ((divisions - 1) / divisions), HEIGHT);
  ctx.lineTo(WIDTH, HEIGHT);
  ctx.lineTo(WIDTH, HEIGHT * ((divisions - 1) / divisions));
  ctx.lineTo(WIDTH * (1 / divisions), 0);
  ctx.lineTo(0, 0);
  ctx.fill();

  return ctx.createPattern(canvas, 'repeat');
};

const circlePattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions, style } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  if (style === 'closed') {
    ctx.fillStyle = color(patternColor, patternOpacity);
  } else {
    ctx.fillStyle = 'transparent';
  }

  ctx.beginPath();
  ctx.arc(WIDTH / 2, HEIGHT / 2, WIDTH * (1 / divisions), 0, 2 * Math.PI, false);
  ctx.fill();

  if (style === 'open') {
    ctx.lineWidth = 2;
    ctx.strokeStyle = color(patternColor, patternOpacity);
    ctx.stroke();
  }

  return ctx.createPattern(canvas, 'repeat');
};

const diagonalCirclePattern = (options: PatternOptions) => {
  const { fillColor, fillOpacity, patternColor, patternOpacity, size, divisions, style } = options;
  const WIDTH = size;
  const HEIGHT = size;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = WIDTH;
  canvas.height = HEIGHT;

  ctx.fillStyle = color(fillColor, fillOpacity);
  ctx.fillRect(0, 0, WIDTH, HEIGHT);

  if (style === 'closed') {
    ctx.fillStyle = color(patternColor, patternOpacity);
  } else {
    ctx.fillStyle = 'transparent';
  }

  ctx.beginPath();
  ctx.arc(WIDTH / 4, HEIGHT / 4, WIDTH * (1 / divisions), 0, 2 * Math.PI, false);
  ctx.fill();

  if (style === 'open') {
    ctx.lineWidth = 2;
    ctx.strokeStyle = color(patternColor, patternOpacity);
    ctx.stroke();
  }

  ctx.beginPath();
  ctx.arc((WIDTH * 3) / 4, (HEIGHT * 3) / 4, WIDTH * (1 / divisions), 0, 2 * Math.PI, false);
  ctx.fill();

  if (style === 'open') {
    ctx.lineWidth = 2;
    ctx.strokeStyle = color(patternColor, patternOpacity);
    ctx.stroke();
  }

  return ctx.createPattern(canvas, 'repeat');
};

const convertToImage = (pattern: CanvasPattern) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = 50;
  canvas.height = 50;

  ctx.fillStyle = pattern;
  ctx.fillRect(0, 0, 50, 50);

  return canvas.toDataURL();
};

const createCacheKey = (key: string, config: PatternConfig): string => {
  return `${key}-${JSON.stringify(config)}`;
};

export const renderPattern = (key: string, options: PatternConfig) => {
  switch (key) {
    case 'diagonal-1':
      return diagonalPattern({ ...options, size: 16, divisions: 16, direction: 'right' });
    case 'diagonal-2':
      return diagonalPattern({ ...options, size: 16, divisions: 8, direction: 'right' });
    case 'diagonal-3':
      return diagonalPattern({ ...options, size: 16, divisions: 4, direction: 'right' });
    case 'diagonal-4':
      return diagonalPattern({ ...options, size: 10, divisions: 10, direction: 'right' });
    case 'diagonal-5':
      return diagonalPattern({ ...options, size: 10, divisions: 6, direction: 'right' });
    case 'diagonal-6':
      return diagonalPattern({ ...options, size: 10, divisions: 4, direction: 'right' });
    case 'diagonal-7':
      return diagonalPattern({ ...options, size: 16, divisions: 16, direction: 'left' });
    case 'diagonal-8':
      return diagonalPattern({ ...options, size: 16, divisions: 8, direction: 'left' });
    case 'diagonal-9':
      return diagonalPattern({ ...options, size: 16, divisions: 4, direction: 'left' });
    case 'diagonal-10':
      return diagonalPattern({ ...options, size: 10, divisions: 10, direction: 'left' });
    case 'diagonal-11':
      return diagonalPattern({ ...options, size: 10, divisions: 6, direction: 'left' });
    case 'diagonal-12':
      return diagonalPattern({ ...options, size: 10, divisions: 4, direction: 'left' });
    case 'vertical-1':
      return linePattern({ ...options, size: 16, divisions: 16, direction: 'vertical' });
    case 'vertical-2':
      return linePattern({ ...options, size: 16, divisions: 10, direction: 'vertical' });
    case 'vertical-3':
      return linePattern({ ...options, size: 16, divisions: 6, direction: 'vertical' });
    case 'vertical-4':
      return linePattern({ ...options, size: 10, divisions: 10, direction: 'vertical' });
    case 'vertical-5':
      return linePattern({ ...options, size: 10, divisions: 6, direction: 'vertical' });
    case 'vertical-6':
      return linePattern({ ...options, size: 10, divisions: 4, direction: 'vertical' });
    case 'horizontal-1':
      return linePattern({ ...options, size: 16, divisions: 16, direction: 'horizontal' });
    case 'horizontal-2':
      return linePattern({ ...options, size: 16, divisions: 10, direction: 'horizontal' });
    case 'horizontal-3':
      return linePattern({ ...options, size: 16, divisions: 6, direction: 'horizontal' });
    case 'horizontal-4':
      return linePattern({ ...options, size: 10, divisions: 10, direction: 'horizontal' });
    case 'horizontal-5':
      return linePattern({ ...options, size: 10, divisions: 6, direction: 'horizontal' });
    case 'horizontal-6':
      return linePattern({ ...options, size: 10, divisions: 4, direction: 'horizontal' });
    case 'plus-1':
      return plusPattern({ ...options, size: 16, divisions: 16 });
    case 'plus-2':
      return plusPattern({ ...options, size: 16, divisions: 10 });
    case 'plus-3':
      return plusPattern({ ...options, size: 16, divisions: 6 });
    case 'plus-4':
      return plusPattern({ ...options, size: 10, divisions: 10 });
    case 'plus-5':
      return plusPattern({ ...options, size: 10, divisions: 6 });
    case 'plus-6':
      return plusPattern({ ...options, size: 10, divisions: 4 });
    case 'cross-1':
      return crossPattern({ ...options, size: 16, divisions: 16 });
    case 'cross-2':
      return crossPattern({ ...options, size: 16, divisions: 10 });
    case 'cross-3':
      return crossPattern({ ...options, size: 16, divisions: 6 });
    case 'cross-4':
      return crossPattern({ ...options, size: 10, divisions: 10 });
    case 'cross-5':
      return crossPattern({ ...options, size: 10, divisions: 6 });
    case 'cross-6':
      return crossPattern({ ...options, size: 10, divisions: 4 });
    case 'circle-1':
      return circlePattern({ ...options, size: 16, divisions: 8, style: 'closed' });
    case 'circle-2':
      return circlePattern({ ...options, size: 10, divisions: 5, style: 'closed' });
    case 'circle-3':
      return circlePattern({ ...options, size: 16, divisions: 4, style: 'closed' });
    case 'circle-4':
      return circlePattern({ ...options, size: 22, divisions: 5, style: 'closed' });
    case 'circle-5':
      return circlePattern({ ...options, size: 18, divisions: 4, style: 'open' });
    case 'circle-6':
      return circlePattern({ ...options, size: 26, divisions: 4, style: 'open' });
    case 'circle-7':
      return diagonalCirclePattern({ ...options, size: 16, divisions: 8, style: 'closed' });
    case 'circle-8':
      return diagonalCirclePattern({ ...options, size: 10, divisions: 5, style: 'closed' });
    case 'circle-9':
      return diagonalCirclePattern({ ...options, size: 16, divisions: 4, style: 'closed' });
    case 'circle-10':
      return diagonalCirclePattern({ ...options, size: 22, divisions: 5, style: 'closed' });
    case 'circle-11':
      return diagonalCirclePattern({ ...options, size: 18, divisions: 6, style: 'open' });
    case 'circle-12':
      return diagonalCirclePattern({ ...options, size: 26, divisions: 4, style: 'open' });
    default:
      return defaultPattern(options);
  }
};

export const getPatternImage = (key: string, config: PatternConfig): string => {
  const cacheKey = createCacheKey(key, config);
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const pattern = renderPattern(key, config);
  const patternAsUrl = convertToImage(pattern);
  cache.set(cacheKey, patternAsUrl);

  return patternAsUrl;
};
