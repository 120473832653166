import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-layer-source',
  templateUrl: './layer-source.component.html',
  styleUrls: ['./layer-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerSourceComponent {
  @Input() source: { name: string; url: string }[];

  constructor() {}
}
