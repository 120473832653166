import { Icon, Style } from 'ol/style';

function makeIconStyle(src: string, color: string) {
  return new Style({
    image: new Icon({
      scale: 1,
      src,
      color,
    }),
  });
}

export const photoStyle = makeIconStyle('assets/icons/photo.svg', '#F28522');

export function buildPhotoStyle(scale: number) {
  const style = photoStyle.clone();
  style.getImage().setScale(scale * 0.75);
  return style;
}
