import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { GtagService } from '@core/providers';
import { clickUpgrade, creditsActions, creditsApiActions } from '../actions/credits.actions';

@Injectable()
export class AnalyticsEventsEffects {
  openBuyCreditsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(creditsActions.openBuyCreditsDialog),
        tap(({ click_location, basemap_type }) =>
          this.gtag.event('os_credits_add_start', basemap_type ? { basemap_type, click_location } : { click_location })
        )
      ),
    { dispatch: false }
  );

  cancelBuyCreditsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(creditsActions.cancelBuyCreditsDialog),
        tap(() => this.gtag.event('os_credits_add_cancel'))
      ),
    { dispatch: false }
  );

  buyOSCredits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(creditsApiActions.buyCreditsSuccess),
        tap((action) => {
          this.gtag.event('os_credits_add_finish', {
            number_of_credits: action.balance,
          });
        })
      ),
    { dispatch: false }
  );

  errorBuyOSCredits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(creditsApiActions.buyCreditsFails),
        tap((action) => {
          this.gtag.event('os_credits_add_error', {
            error_type: action.error.code.replace(/\s/g, '_')?.toLowerCase(),
          });
        })
      ),
    { dispatch: false }
  );

  clickUpgradeSubscription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clickUpgrade),
        tap(({ upgrade_location }) => this.gtag.event('upgrade_start', { upgrade_location }))
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private gtag: GtagService
  ) {}
}
