import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AppErrorCodes, ConfigApiService, OrganisationType, Sector } from '@core/api';
import { PASSWORD_PATTERN } from '@core/common';
import { joinOrganisationActions, joinOrganisationSelectors } from '../../store';

@Component({
  selector: 'la-join-organization-page',
  styleUrls: ['./join-organization-page.component.scss'],
  templateUrl: './join-organization-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinOrganizationPageComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public organisationTypes: OrganisationType[];
  public sectors: Sector[];
  public serverErrorMessage: string;
  public isInvitationExpired: boolean;

  loading$ = this.store.select(joinOrganisationSelectors.getCreateUserLoading);

  private destroy$: Subject<void> = new Subject();

  hide = true;

  constructor(
    private route: ActivatedRoute,
    private configApiService: ConfigApiService,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.createForm(params.token);
    });

    this.configApiService
      .getSectors(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((sectors) => {
        this.sectors = sectors;
        this.cd.markForCheck();
      });

    this.configApiService
      .getOrganisationTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((organisationTypes) => {
        this.organisationTypes = organisationTypes;
        this.cd.markForCheck();
      });

    this.store
      .select(joinOrganisationSelectors.getCreateUserError)
      .pipe(
        takeUntil(this.destroy$),
        filter((error) => !!error)
      )
      .subscribe((error) => {
        if (error.code == AppErrorCodes.SERVER_ERROR) {
          this.serverErrorMessage = error.message;
        } else {
          this.isInvitationExpired = true;
        }

        this.cd.markForCheck();
      });
  }

  toggleVisibility() {
    this.hide = !this.hide;
  }

  submit() {
    const { token, password, sector, organisationType } = this.form.value;
    this.store.dispatch(joinOrganisationActions.createUser({ token, password, sector, organisationType }));
  }

  private createForm(token: string) {
    this.form = new UntypedFormGroup({
      token: new UntypedFormControl(token, [Validators.required]),
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(64), Validators.pattern(PASSWORD_PATTERN)]),
      sector: new UntypedFormControl(null, [Validators.required]),
      organisationType: new UntypedFormControl(null, Validators.required),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
