function shoelaceArea(coords: number[][]) {
  let sum = 0;
  const x0 = coords[0][0];
  for (let i = 1; i < coords.length - 1; i++) {
    const x = coords[i][0] - x0;
    const y1 = coords[i + 1][1];
    const y2 = coords[i - 1][1];
    sum += x * (y2 - y1);
  }
  return Math.abs(sum / 2);
}

function area(geometry: GeoJSON.Geometry) {
  let sum = 0;

  switch (geometry.type) {
    case 'GeometryCollection':
      geometry.geometries.forEach((subGeometry) => {
        sum += area(subGeometry);
      });
      break;
    case 'Polygon':
      geometry.coordinates.forEach((interior, index) => {
        if (index === 0) {
          sum += shoelaceArea(interior);
        } else {
          sum -= shoelaceArea(interior);
        }
      });
      break;
    case 'MultiPolygon':
      geometry.coordinates.forEach((rings) => {
        rings.forEach((interior, index) => {
          if (index === 0) {
            sum += shoelaceArea(interior);
          } else {
            sum -= shoelaceArea(interior);
          }
        });
      });
      break;
  }

  return sum;
}

export function mercatorArea(geometry: GeoJSON.Geometry) {
  return area(geometry);
}
