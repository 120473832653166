import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class CreditsApiService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private http: HttpClient
  ) {}

  fetch() {
    return this.http.get<{ balance: number }>(`${this.config.api.mapUrl}/users/balance`);
  }

  buy(quantity: number) {
    return this.http.post<{ balance: number }>(`${this.config.api.mapUrl}/users/top-up`, { quantity });
  }
}
