import { createReducer, on } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, last, omit } from 'lodash';

import { Map } from '@core/api';
import { ActionUIState, initActions, ItemUIState } from '@core/store';
import { Interaction, Selection } from '../../models/interactions.model';
import * as dataLayersApiActions from '../actions/api/data-layers-api.actions';
import { saveFeaturesComplete } from '../actions/api/features-api.actions';
import * as foldersApiActions from '../actions/api/folders-api.actions';
import * as mapsApiActions from '../actions/api/maps-api.actions';
import * as photosApiActions from '../actions/api/photos-api.actions';
import * as plansApiActions from '../actions/api/plans-api.actions';
import * as estateEffectActions from '../actions/estate-effect.actions';
import * as estateHeaderActions from '../actions/estate-header.actions';
import * as landPickerActions from '../actions/land-picker.actions';
import * as layerToolbarActions from '../actions/layer-toolbar.actions';
import * as leftHandPanelActions from '../actions/left-hand-panel.actions';
import * as mapContainerActions from '../actions/map-container.actions';
import * as mapPageActions from '../actions/map-page.actions';
import * as plansEffectActions from '../actions/plans-effect.actions';
import * as printSetupActions from '../actions/print-setup.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';
import * as workflowActions from '../actions/workflow.actions';
import { definePaths, getParentIds, updatePlan } from '../utils/plans-tree.utils';

export const featureKey = 'estate';

export interface EstateState extends ItemUIState<Map> {
  readonly?: boolean;
  locked?: boolean;
  rendered?: boolean;

  interaction: Interaction;
  selection: Selection;

  measure?: {
    mode: 'distance' | 'area';
    drawing: boolean;
    area?: number;
    perimeter?: number;
  };

  action: ActionUIState;

  previousLocation?: {
    extent: [number, number, number, number];
    basemap: string;
  };
}

export const initialState: EstateState = {
  status: 'INIT',

  interaction: {
    mapMode: 'select',
    featureMode: 'modify',
    snapMode: true,
    interactiveMap: false,
  },

  selection: {
    layerId: null,
    features: [],
    paths: [],
  },

  action: {
    status: 'INIT',
  },
};

export const reducer = createReducer<EstateState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),
  on(mapPageActions.leaveMap, (state) => ({ ...cloneDeep(initialState), previousLocation: state.previousLocation })),

  on(mapPageActions.initMap, (state) => ({ ...state, status: 'LOADING' })),
  on(mapsApiActions.initMapSuccess, (state, { estate, readonly }) => ({ ...state, data: estate, readonly })),
  on(mapsApiActions.initMapFail, (state, { error }) => ({ ...state, status: 'ERROR', error })),
  on(mapsApiActions.initMapComplete, (state) => ({ ...state, status: 'READY' })),

  on(mapContainerActions.renderComplete, (state) => ({ ...state, rendered: true })),

  on(estateEffectActions.socketLockMap, (state) => ({
    ...state,
    locked: true,
    interaction: {
      ...omit(state.interaction, ['drawDefinition', 'drawFeatureIds', 'bufferPreview']),
      mapMode: 'select',
    },
  })),
  on(estateEffectActions.socketUnlockMap, (state) => ({ ...state, locked: false })),

  on(mapContainerActions.selectOnMap, (state, { layerId, features }) => ({
    ...state,
    selection: {
      ...state.selection,
      folders: layerId ? getParentIds(layerId, state.data.plans) : state.selection.folders,
      layerId: layerId ? layerId : state.selection.layerId,
      features,
      paths: isEmpty(features) ? state.selection.paths : definePaths(features),
    },
  })),
  on(leftHandPanelActions.selectTreeNode, (state, { folders, layers, parcels }) => ({
    ...state,
    selection: {
      ...state.selection,
      folders: folders || [],
      layerId: layers?.length ? layers[0] : undefined,
      paths: parcels || [],
    },
  })),
  on(estateEffectActions.deselectAll, (state) => ({
    ...state,
    selection: {
      layerId: null,
      features: [],
      paths: [],
    },
  })),
  on(estateEffectActions.deselectFeatures, (state) => ({
    ...state,
    selection: {
      ...state.selection,
      features: [],
    },
  })),

  on(saveFeaturesComplete, photosApiActions.linkPhotosSuccess, photosApiActions.unlinkPhotosSuccess, (state) => ({
    ...state,
    data: { ...state.data, updatedAt: new Date().toISOString() },
  })),

  on(leftHandPanelActions.showPlan, (state, { id }) => ({
    ...state,
    data: { ...state.data, plans: updatePlan(state.data.plans, id, { visible: true }) },
  })),
  on(leftHandPanelActions.hidePlan, (state, { id }) => ({
    ...state,
    data: { ...state.data, plans: updatePlan(state.data.plans, id, { visible: false }) },
  })),

  on(
    leftHandPanelActions.createBlankPlan,
    leftHandPanelActions.createEmptyPlan,
    leftHandPanelActions.createPlanFromExistingPlan,
    leftHandPanelActions.createPlanFromPicker,
    leftHandPanelActions.createPlansFromRPA,
    leftHandPanelActions.showPlan,
    leftHandPanelActions.hidePlan,
    leftHandPanelActions.archivePlan,
    leftHandPanelActions.deleteFolder,
    leftHandPanelActions.publishFolder,
    leftHandPanelActions.unpublishFolder,
    leftHandPanelActions.dragAndDropNodes,
    leftHandPanelActions.showPhotos,
    leftHandPanelActions.hidePhotos,
    (state) => ({
      ...state,
      action: { status: 'LOADING' },
    })
  ),

  on(
    plansApiActions.createPlanSuccess,
    plansApiActions.createPlanDuplicateSuccess,
    plansApiActions.createPlanFromRPASuccess,
    plansApiActions.createPlanFromLandPickerSuccess,
    plansApiActions.createPlansFromFilesSuccess,
    plansApiActions.restorePlanSuccess,
    plansApiActions.createPlanWithFeaturesSuccess,
    (
      state,
      {
        changes: {
          map: { plans, version, updatedAt },
        },
      }
    ) => ({
      ...state,
      data: { ...state.data, plans, version, updatedAt },
      action: { status: 'READY' },
    })
  ),

  on(
    plansApiActions.showPlansSuccess,
    plansApiActions.hidePlansSuccess,
    plansApiActions.deletePlanSuccess,
    plansApiActions.fetchOrderSuccess,
    plansApiActions.reorderPlansSuccess,
    foldersApiActions.createFolderSuccess,
    foldersApiActions.renameFolderSuccess,
    foldersApiActions.deleteFolderSuccess,
    foldersApiActions.showFolderSuccess,
    foldersApiActions.hideFolderSuccess,
    foldersApiActions.publishFolderSuccess,
    foldersApiActions.unpublishFolderSuccess,
    foldersApiActions.lockFolderSuccess,
    foldersApiActions.unlockFolderSuccess,
    (state, { map: { plans, version, updatedAt } }) => ({
      ...state,
      data: { ...state.data, plans, version, updatedAt },
      action: { status: 'READY' },
    })
  ),

  on(
    plansApiActions.createPlanFail,
    plansApiActions.createPlanDuplicateFail,
    plansApiActions.createPlanFromRPAFail,
    plansApiActions.createPlanFromLandPickerFail,
    plansApiActions.createPlanWithFeaturesFail,
    plansApiActions.showPlansFail,
    plansApiActions.hidePlansFail,
    plansApiActions.deletePlanFail,
    plansApiActions.fetchOrderFail,
    plansApiActions.reorderPlansFail,
    plansApiActions.restorePlanFail,
    foldersApiActions.createFolderFail,
    foldersApiActions.renameFolderFail,
    foldersApiActions.deleteFolderFail,
    foldersApiActions.showFolderFail,
    foldersApiActions.hideFolderFail,
    foldersApiActions.publishFolderFail,
    foldersApiActions.unpublishFolderFail,
    foldersApiActions.lockFolderFail,
    foldersApiActions.unlockFolderFail,
    photosApiActions.showPhotosFail,
    photosApiActions.hidePhotosFail,
    (state, { error }) => ({
      ...state,
      action: { status: 'ERROR', error },
    })
  ),

  on(leftHandPanelActions.showPhotos, (state) => ({
    ...state,
    data: { ...state.data, photosEnabled: true },
  })),

  on(leftHandPanelActions.hidePhotos, (state) => ({
    ...state,
    data: { ...state.data, photosEnabled: false },
  })),

  on(photosApiActions.showPhotosSuccess, (state, { updatedAt }) => ({
    ...state,
    data: { ...state.data, updatedAt, photosEnabled: true },
    action: { status: 'READY' },
  })),

  on(photosApiActions.hidePhotosSuccess, (state, { updatedAt }) => ({
    ...state,
    data: { ...state.data, updatedAt, photosEnabled: false },
    action: { status: 'READY' },
  })),

  on(leftHandPanelActions.droppedNodes, (state, { plans }) => ({
    ...state,
    data: {
      ...state.data,
      plans,
    },
  })),

  on(plansApiActions.renamePlanSuccess, (state, { layer }) => ({
    ...state,
    data: {
      ...state.data,
      plans: updatePlan(state.data.plans, layer.id, { name: layer.name }),
    },
  })),

  on(estateEffectActions.deletePlan, (state, { planId }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: state.selection.layerId === planId ? 'select' : state.interaction.mapMode,
    },
  })),

  on(leftHandPanelActions.deleteFolder, (state, { folderId }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: state.data.plans
        .find((plan) => plan.id === folderId)
        .content?.map((child) => child.id)
        .includes(state.selection.layerId)
        ? 'select'
        : state.interaction.mapMode,
    },
  })),

  /**
   * Land Picker
   * ----------------------------------------------
   */

  on(landPickerActions.startLandPicker, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'pick-feature' },
  })),
  on(landPickerActions.stopLandPicker, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: initialState.interaction.mapMode },
  })),

  /**
   * Attributes Picker
   * ----------------------------------------------
   */

  on(rightHandPanelActions.enableAttributesPick, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'pick-attributes' },
  })),
  on(rightHandPanelActions.disableAttributesPick, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: initialState.interaction.mapMode },
  })),

  /**
   * Measure Tool
   * ----------------------------------------------
   */
  on(estateHeaderActions.startMeasure, (state, { mode }) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'measure' },
    measure: {
      mode,
      drawing: false,
    },
  })),
  on(mapContainerActions.startDrawingMeasure, (state) => ({
    ...state,
    measure: {
      ...state.measure,
      drawing: true,
    },
  })),
  on(mapContainerActions.stopDrawingMeasure, (state) => ({
    ...state,
    measure: {
      ...state.measure,
      drawing: false,
    },
  })),
  on(mapContainerActions.updateMeasureUnits, (state, { area, perimeter }) => ({
    ...state,
    measure: {
      ...state.measure,
      area,
      perimeter,
    },
  })),
  on(mapContainerActions.resetMeasure, (state) => ({
    ...state,
    measure: {
      ...omit(state.measure, ['area', 'perimeter']),
      drawing: false,
    },
  })),

  /**
   * Layer Toolbar
   * ----------------------------------------------
   */

  on(layerToolbarActions.enableSnap, (state) => ({
    ...state,
    interaction: { ...state.interaction, snapMode: true },
  })),
  on(layerToolbarActions.disableSnap, (state) => ({
    ...state,
    interaction: { ...state.interaction, snapMode: false },
  })),

  on(layerToolbarActions.startMerge, mapContainerActions.menuStartMerge, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'merge' },
  })),
  on(mapContainerActions.mergeOnMap, mapContainerActions.splitOnMap, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'select' },
  })),
  on(layerToolbarActions.stopDraw, (state) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: ['draw', 'split', 'buffer'].includes(state.interaction.mapMode) ? 'select' : state.interaction.mapMode,
    },
  })),
  on(layerToolbarActions.bufferFeatures, (state) => ({
    ...state,
    interaction: {
      ...omit(state.interaction, ['drawDefinition', 'drawFeatureIds']),
      mapMode: ['draw', 'split', 'buffer'].includes(state.interaction.mapMode) ? 'select' : state.interaction.mapMode,
    },
  })),
  on(layerToolbarActions.startSplit, mapContainerActions.menuStartSplit, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'split' },
  })),
  on(layerToolbarActions.startBuffer, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'buffer' },
  })),
  on(layerToolbarActions.bufferPreview, (state, { features }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      bufferPreview: features,
    },
  })),
  on(layerToolbarActions.startDraw, (state, { definition }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: 'draw',
      drawDefinition: definition,
      drawFeatureIds: [],
    },
  })),
  on(mapContainerActions.drawOnMap, (state, { feature }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      drawFeatureIds: state.interaction.drawFeatureIds ? [...state.interaction.drawFeatureIds, feature.id] : [feature.id],
    },
  })),
  on(layerToolbarActions.setFeatureMode, (state, { mode }) => ({
    ...state,
    interaction: { ...state.interaction, featureMode: mode },
  })),
  on(
    layerToolbarActions.resetInteractions,
    mapContainerActions.stopMeasure,
    mapContainerActions.cancelMerge,
    layerToolbarActions.stopBuffer,
    plansEffectActions.splitFeaturesFail,
    plansEffectActions.mergeFeaturesFail,
    printSetupActions.resetPrintSetup,
    (state) => ({
      ...omit(state, ['measure']),
      interaction: {
        ...omit(state.interaction, ['drawDefinition', 'drawFeatureIds', 'bufferPreview']),
        mapMode: 'select',
      },
    })
  ),

  on(estateEffectActions.clearDrawings, (state) => ({
    ...state,
    interaction: {
      ...omit(state.interaction, ['drawDefinition', 'drawFeatureIds']),
    },
  })),

  on(layerToolbarActions.switchBaseLayer, printSetupActions.switchBaseLayerQuiet, (state, { baseLayer }) => ({
    ...state,
    data: {
      ...state.data,
      selectedBasemap: baseLayer.id,
    },
  })),
  on(printSetupActions.restorePreviousLocation, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedBasemap: state.previousLocation?.basemap ? state.previousLocation.basemap : state.data.selectedBasemap,
    },
  })),

  on(layerToolbarActions.showDataLayer, (state, { id }) => ({
    ...state,
    data: {
      ...state.data,
      dataLayers: [...state.data.dataLayers, id],
    },
  })),
  on(layerToolbarActions.hideDataLayer, (state, { id }) => ({
    ...state,
    data: {
      ...state.data,
      dataLayers: state.data.dataLayers.filter((layer) => layer !== id),
    },
  })),
  on(dataLayersApiActions.hideDataLayersSuccess, (state, { ids }) => ({
    ...state,
    data: {
      ...state.data,
      dataLayers: state.data.dataLayers.filter((id) => !ids.includes(id)),
    },
  })),

  on(estateEffectActions.completePlanCreation, (state, { plans }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      createdPlanId: last(plans),
    },
    selection: {
      layerId: last(plans),
      features: [],
      folders: [],
      paths: [],
    },
  })),

  on(plansEffectActions.clearCreatedPlanId, (state) => ({
    ...state,
    interaction: {
      ...omit(state.interaction, ['createdPlanId']),
    },
  })),

  on(mapContainerActions.updateInteractiveMapVisibility, (state, { isVisible }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      interactiveMap: isVisible,
    },
  })),

  /**
   * Print Setup
   * ----------------------------------------------
   */

  on(printSetupActions.renderPaperOnMap, (state, { paperSize, margins, scale, extent, editable }) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: 'paper-bbox',
      paperSettings: { ...state.interaction.paperSettings, paperSize, margins, scale, extent, editable: isNil(editable) ? true : editable },
    },
  })),

  on(printSetupActions.selectFrame, (state, { frame, margins }) => ({
    ...state,
    data: {
      ...state.data,
      selectedBasemap: frame.basemap,
    },
    interaction: {
      ...state.interaction,
      mapMode: 'paper-bbox',
      paperSettings: { ...state.interaction.paperSettings, paperSize: frame.paperSize, margins, extent: frame.bbox, editable: false },
    },
  })),
  on(printSetupActions.deselectFrame, (state) => ({
    ...state,
    interaction: {
      ...state.interaction,
      mapMode: 'paper-bbox',
      paperSettings: undefined,
    },
  })),

  on(printSetupActions.setPreviousLocation, (state, { extent, basemap }) => ({ ...state, previousLocation: { extent, basemap } })),
  on(printSetupActions.clearPreviousLocation, (state) => omit(state, ['previousLocation'])),

  /**
   * Buy Data
   * ----------------------------------------------
   */

  on(workflowActions.setWorkflowParameters, (state, { parameters }) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: parameters.referenceAreaMode === 'radio-bbox' ? 'workflow-bbox' : 'none' },
  })),
  on(workflowActions.closeWorkflowSetupPanel, (state) => ({
    ...state,
    interaction: { ...state.interaction, mapMode: 'select' },
  }))
);
