export interface StyleConfig {
  fillOpacity?: number;
  fillColour?: string;
  strokeColour?: string;
  strokeOpacity?: number;
  strokeWidth?: number;
  strokeDash?: number[];
  fillPattern?: string;
  fillPatternOpacity?: number;
  fillPatternColour?: string;
  imageRadius?: number;
  imageFillColour?: string;
  imageFillOpacity?: number;
  imageStrokeColour?: string;
  imageStrokeOpacity?: number;
  imageStrokeWidth?: number;
  textString?: string;
  textSize?: number;
  textRotation?: number;
  textFillColour?: string;
  textFillOpacity?: number;
  textShadowColour?: string;
  textShadowOpacity?: number;
  textShadowWidth?: number;
  textMinResolution?: number;
  textMaxResolution?: number;
}

export type StyleDefinitionFilters = { lookup: string; stops: (string | number)[] }[];

export interface StyleDefinition {
  default?: StyleConfig;
  lookup?: string;
  stops?: [boolean | number | string, StyleConfig][];
  filters?: StyleDefinitionFilters;
}

export interface FeatureDefinition {
  id: string;
  name: string;
  category: string;
  geometryType: 'Point' | 'LineString' | 'Polygon';
  style: StyleConfig;
}
