import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class ChargebeeApiService {
  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig, private http: HttpClient) {}

  getMainSession() {
    return this.http.post(`${this.config.api.mapUrl}/users/cb-session`, {}).pipe(map((session: any) => session.portal_session));
  }

  getPaymentSession() {
    return this.http.get(`${this.config.api.mapUrl}/users/update-payment-method`, {}).pipe(map((session: any) => session.hosted_page));
  }
}
