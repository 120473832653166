import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { metaReducer } from '@core/store';
import { DialogsModule } from '../dialogs';
import { PaywallBalanceComponent } from './components/paywall-balance/paywall-balance.component';
import { PaywallSnackbarComponent } from './components/paywall-snackbar/paywall-snackbar.component';
import { UpgradeSubscriptionComponent } from './components/upgrade-subscription/upgrade-subscription.component';
import { CreditsTopUpDialogComponent } from './dialogs/credits-top-up-dialog/credits-top-up-dialog.component';
import { CreditsDialogsService, effects, featureKey, reducer } from './store';

@NgModule({
  declarations: [PaywallSnackbarComponent, CreditsTopUpDialogComponent, PaywallBalanceComponent, UpgradeSubscriptionComponent],
  exports: [PaywallSnackbarComponent, CreditsTopUpDialogComponent, PaywallBalanceComponent, UpgradeSubscriptionComponent],
  imports: [
    StoreModule.forFeature(featureKey, reducer, { metaReducers: [metaReducer] }),
    EffectsModule.forFeature(effects),
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    DialogsModule,
  ],
  providers: [CreditsDialogsService],
})
export class PaywallModule {}
