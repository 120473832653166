import { PERIMETER_UNITS } from '@core/api';
import { round } from '@core/common';

const METER_TO_MILE = 0.000621371192;
const METER_TO_KILOMETER = 0.001;
const METER_TO_FOOT = 3.280839895;

const meterToMile = (value: number): number => {
  return value * METER_TO_MILE;
};

const meterToKilometer = (value: number): number => {
  return value * METER_TO_KILOMETER;
};

const meterToFoot = (value: number): number => {
  return value * METER_TO_FOOT;
};

const isInputValid = (value: number): boolean => {
  return !(value < 0 || typeof value !== 'number');
};

export const convertPerimeterTo = (value: number, unit: PERIMETER_UNITS, precision: number = 2): string => {
  if (!isInputValid(value)) {
    return '';
  }

  let recalculatedArea = value;

  switch (unit) {
    case 'mi':
      recalculatedArea = meterToMile(value);
      break;
    case 'km':
      recalculatedArea = meterToKilometer(value);
      break;
    case 'ft':
      recalculatedArea = meterToFoot(value);
      break;
  }

  return `${round(recalculatedArea, precision).toFixed(precision)} ${unit}`;
};
