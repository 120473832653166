import { addCoordinateTransforms, addProjection } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';

export const AVAILABLE_PROJECTIONS = {
  'EPSG:3857': {
    projection: 'EPSG:3857',
    center: [-185027, 6816271],
    extent: [-20026376, -20048966, 20026376, 20048966] as [number, number, number, number],
    zoom: 8,
    minZoom: 0,
    maxZoom: 28,
  },
  'EPSG:27700': {
    projection: 'EPSG:27700',
    center: [435000, 296700],
    extent: [-238375.0, 0.0, 900000.0, 1376256.0] as [number, number, number, number],
    resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375, 0.05, 0.02],
    zoom: 3,
    minZoom: 0,
    maxZoom: 15,
  },
};

const install = () => {
  proj4.defs(
    'EPSG:27700',
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs'
  );

  const bngProjection = new Projection({
    code: 'EPSG:27700',
    extent: [0, 0, 700000, 1300000],
    units: 'm',
  });

  addProjection(bngProjection);

  addCoordinateTransforms(
    'EPSG:4326',
    bngProjection,
    (coordinate) => proj4('EPSG:4326', 'EPSG:27700', coordinate),
    (coordinate) => proj4('EPSG:27700', 'EPSG:4326', coordinate)
  );

  addCoordinateTransforms(
    'EPSG:3857',
    bngProjection,
    (coordinate) => proj4('EPSG:3857', 'EPSG:27700', coordinate),
    (coordinate) => proj4('EPSG:27700', 'EPSG:3857', coordinate)
  );
};

install();
