import { fromEvent, merge } from 'rxjs';

const keycodes = {};

export const keyEvent$ = merge(fromEvent(document, 'keydown'), fromEvent(document, 'keyup'));

keyEvent$.subscribe((e: KeyboardEvent) => {
  keycodes[e.code] = e.type === 'keydown';
});

export const isKeyPressed = (keyCodes: string[]) => keyCodes.every((keyCode) => !!keycodes[keyCode]);
