import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

const DEFAULTS = {
  now: 'just now',
  seconds: 'less than 1 minute ago',
  minutes: {
    1: 'minute ago',
    2: 'minutes ago',
  },
  hours: {
    1: 'hour ago',
    2: 'hours ago',
  },
  days: {
    1: 'day ago',
    2: 'days ago',
  },
  weeks: {
    1: 'week ago',
    2: 'weeks ago',
  },
  months: {
    1: 'month ago',
    2: 'months ago',
  },
  years: {
    1: 'year ago',
    2: 'years ago',
  },
};

@Component({
  selector: 'la-from-now',
  template: '{{ displayTime }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FromNowComponent implements OnInit, OnDestroy {
  @Input() timing = 10000;

  @Input() set date(value: string | Date) {
    this.time = !value ? new Date() : value instanceof Date ? value : new Date(value);
  }

  private time: Date;
  private interval: any;

  constructor(private cd: ChangeDetectorRef) {}

  get displayTime() {
    if (!this.time) {
      return '';
    }

    let diff: number;
    const past = this.time.getTime();
    const now = Date.now();

    const seconds = Math.floor((now - past) / 1000);

    diff = Math.floor(seconds / 31536000);
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'years')}`;
    }

    diff = Math.floor(seconds / 2592000);
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'months')}`;
    }

    diff = Math.floor(seconds / (86400 * 7));
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'weeks')}`;
    }

    diff = Math.floor(seconds / 86400);
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'days')}`;
    }

    diff = Math.floor(seconds / 3600);
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'hours')}`;
    }

    diff = Math.floor(seconds / 60);
    if (diff >= 1) {
      return `${diff} ${this.getUnit(diff, 'minutes')}`;
    }

    if (seconds < 10) {
      return DEFAULTS.now;
    }

    return DEFAULTS.seconds;
  }

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  private startTimer() {
    this.stopTimer();
    this.interval = setInterval(() => this.cd.detectChanges(), this.timing);
  }

  private stopTimer() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  private getUnit(interval: number, unit: string) {
    let result: string;

    Object.keys(DEFAULTS[unit]).forEach((key) => {
      if (parseInt(key, 10) <= interval) {
        result = DEFAULTS[unit][key];
      }
    });

    return result;
  }
}
