import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { PaywallModule } from '../paywall';
import { StylesModule } from '../styles';
import { ActionComponent } from './components/action/action.component';
import { AddFirstPlanComponent } from './components/add-first-plan/add-first-plan.component';
import { AvatarIconComponent } from './components/avatar-icon/avatar-icon.component';
import { ContentWithLabelComponent } from './components/content-with-label/content-with-label.component';
import { ContextualHeaderComponent } from './components/contextual-header/contextual-header.component';
import { ContextualToolbarComponent } from './components/contextual-toolbar/contextual-toolbar.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { DelayButtonComponent } from './components/delay-button/delay-button.component';
import { FilterAutocompleteComponent } from './components/filter-autocomplete/filter-autocomplete.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FromNowComponent } from './components/from-now/from-now.component';
import { IconHostComponent } from './components/icon-host/icon-host.component';
import { ImageComponent } from './components/image/image.component';
import { LayerSourceComponent } from './components/layer-source/layer-source.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NewTagFilterComponent } from './components/new-tag-filter/new-tag-filter.component';
import { PanelHeaderComponent } from './components/panel-header/panel-header.component';
import { SectionComponent } from './components/section/section.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ScrollableDirective } from './directives/scrollable.directive';
import { AreaPipe } from './pipes/area.pipe';
import { ExtensionPipe, FileNamePipe } from './pipes/file.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GroupPipe } from './pipes/group.pipe';
import { IsExpiredPipe } from './pipes/is-expired.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { LimitTextDisplayPipe } from './pipes/limit-text-display.pipe';
import { NonBreakingHyphenPipe } from './pipes/non-breaking-hyphen';
import { PaperSizePipe } from './pipes/paper-size.pipe';
import { PerimeterPipe } from './pipes/perimeter.pipe';
import { PluralSingularPipe } from './pipes/pluralize.pipe';
import { GrossPricePipe, GrossVatPipe, PricePipe, VatPipe } from './pipes/price.pipe';
import { SeparatorPipe } from './pipes/separator.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SubscriptionNamePipe } from './pipes/subscription-name';
import { TimespanPipe } from './pipes/timespan.pipe';

@NgModule({
  declarations: [
    ActionComponent,
    AvatarIconComponent,
    ContentWithLabelComponent,
    ContentWithLabelComponent,
    ContextualHeaderComponent,
    ContextualToolbarComponent,
    CopyToClipboardComponent,
    DelayButtonComponent,
    FilterBarComponent,
    FiltersComponent,
    FromNowComponent,
    IconHostComponent,
    ImageComponent,
    LayerSourceComponent,
    LoaderComponent,
    NewTagFilterComponent,
    PanelHeaderComponent,
    SectionComponent,
    FilterAutocompleteComponent,
    AddFirstPlanComponent,

    AutofocusDirective,
    ScrollableDirective,

    AreaPipe,
    ExtensionPipe,
    FileNamePipe,
    FilterPipe,
    GrossPricePipe,
    GrossVatPipe,
    GroupPipe,
    IsExpiredPipe,
    KeysPipe,
    LimitTextDisplayPipe,
    NonBreakingHyphenPipe,
    PaperSizePipe,
    PerimeterPipe,
    PricePipe,
    SeparatorPipe,
    SubscriptionNamePipe,
    TimespanPipe,
    VatPipe,
    PluralSingularPipe,
    SortPipe,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    MatSliderModule,
    MatCardModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,

    ActionComponent,
    AvatarIconComponent,
    ContentWithLabelComponent,
    ContextualHeaderComponent,
    ContextualToolbarComponent,
    CopyToClipboardComponent,
    DelayButtonComponent,
    FilterBarComponent,
    FiltersComponent,
    FromNowComponent,
    IconHostComponent,
    ImageComponent,
    LayerSourceComponent,
    LoaderComponent,
    NewTagFilterComponent,
    PanelHeaderComponent,
    SectionComponent,
    FilterAutocompleteComponent,
    AddFirstPlanComponent,

    AutofocusDirective,
    ScrollableDirective,

    AreaPipe,
    ExtensionPipe,
    FileNamePipe,
    FilterPipe,
    GrossPricePipe,
    GrossVatPipe,
    GroupPipe,
    IsExpiredPipe,
    KeysPipe,
    LimitTextDisplayPipe,
    NonBreakingHyphenPipe,
    PaperSizePipe,
    PerimeterPipe,
    PricePipe,
    SeparatorPipe,
    SubscriptionNamePipe,
    TimespanPipe,
    VatPipe,
    PluralSingularPipe,
    SortPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSliderModule,

    ClipboardModule,
    StylesModule,
    PaywallModule,
  ],
})
export class LayoutModule {}
