import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'la-paywall-snackbar',
  templateUrl: './paywall-snackbar.component.html',
  styleUrls: ['./paywall-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallSnackbarComponent {
  @Output() openTopUpDialog = new EventEmitter<void>();
}
