import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';

import { AppError } from '@core/api';
import { ActionUIState, initActions, paymentApiActions } from '@core/store';
import { creditsActions, creditsApiActions } from '../actions/credits.actions';

export const featureKey = 'uiCredits';

export interface CreditsState {
  balance: number;
  version: string;
  error?: AppError;

  buyAction: ActionUIState;
  paymentMethodAction: ActionUIState;
}

export const initialState: CreditsState = {
  balance: 0,
  version: v4(),

  buyAction: { status: 'INIT' },
  paymentMethodAction: { status: 'INIT' },
};

export const reducer = createReducer<CreditsState>(
  cloneDeep(initialState),
  on(initActions.appCleanup, () => cloneDeep(initialState)),

  on(creditsApiActions.fetchCreditsSuccess, (state, { balance }) => ({ ...state, balance })),
  on(creditsApiActions.fetchCreditsFails, (state, { error }) => ({ ...state, error })),

  on(creditsActions.buyCredits, (state) => ({ ...state, buyAction: { status: 'LOADING' } })),
  on(creditsApiActions.buyCreditsSuccess, (state, { balance }) => ({
    ...state,
    balance,
    version: v4(),
    buyAction: { status: 'READY' },
    paymentMethodAction: { status: 'INIT' },
  })),
  on(creditsApiActions.buyCreditsFails, (state, { error }) => ({ ...state, buyAction: { status: 'ERROR', error } })),

  on(creditsActions.updatePaymentMethod, (state) => ({ ...state, paymentMethodAction: { status: 'LOADING' } })),
  on(paymentApiActions.fetchPaymentSuccess, (state) => ({ ...state, paymentMethodAction: { status: 'READY' } })),
  on(paymentApiActions.fetchPaymentFail, (state, { error }) => ({ ...state, paymentMethodAction: { status: 'ERROR', error } })),

  on(creditsActions.openBuyCreditsDialog, creditsActions.cancelBuyCreditsDialog, (state) => ({
    ...state,
    buyAction: { status: 'INIT' },
    paymentMethodAction: { status: 'INIT' },
  }))
);
