import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Translate } from 'ol/interaction';
import { TranslateEvent } from 'ol/interaction/Translate';
import VectorLayer from 'ol/layer/Vector';

import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapSelectComponent } from '../map-select/map-select.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-move',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMoveComponent extends MapInteractionAbstract implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;

  @Output() moveStart = new EventEmitter<{ layerId: string; features: GeoJSON.Feature[] }>();
  @Output() moveEnd = new EventEmitter<{ layerId: string; features: GeoJSON.Feature[] }>();

  private instance: Translate;

  constructor(protected host: MapComponent, private select: MapSelectComponent) {
    super(host);
  }

  ngOnInit(): void {
    this.instance = new Translate({
      features: this.select.instance.selectedInteractiveFeatures,
    });

    addInteraction(this.host.instance, this.instance);

    this.instance.setActive(this.active);
    this.instance.on('translatestart', (event) => this.onMoveStart(event));
    this.instance.on('translateend', (event) => this.onMoveEnd(event));
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);

      if (this.select.instance.selectedInteractiveFeatures.getLength() > 0) {
        const layer = this.select.instance.getLayer(this.select.instance.selectedInteractiveFeatures.getArray()[0]);
        layer.changed();
      }
    }
  }

  private onMoveStart(event: TranslateEvent) {
    const features = event.features.getArray();
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      this.moveStart.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private onMoveEnd(event: TranslateEvent) {
    const features = event.features.getArray();
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      features.forEach((feature) => this.enrichFeature(feature));

      this.moveEnd.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }
}
