import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() close = true;
  @Input() back: () => void = undefined;
}
