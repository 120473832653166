import { BillingDetails } from './billings.model';
import { BillingCycle } from './organization.model';
import { Price } from './price.model';
import { Team, TeamRole, TeamType } from './team.model';

export enum Subscription {
  FREE = 'FREE',
  STANDARD = 'STANDARD',
  PROFESSIONAL = 'PROFESSIONAL',
  PREMIUM = 'PREMIUM',
}

export enum UserGroup {
  ADMIN = 'admin',
  BETA_TESTER = 'beta-tester',
}

export enum SubscriptionChangeScope {
  SELECTED_USERS = 'SELECTED_USERS',
  ORGANISATION = 'ORGANISATION',
  INVITATION = 'INVITATION',
}

export enum DowngradeCancelScope {
  SELECTED_USERS = 'SELECTED_USERS',
  ORGANISATION = 'ORGANISATION',
}

export interface User {
  email: string;
  firstName: string;
  id: string;
  images?: {
    logo?: string;
    avatar?: string;
  };
  intercomHash: string;
  lastName: string;
  countryCode?: string;
  phoneNumber?: string;
  referralCode?: string;
  role: string;
  sector: string;
  organisationType: string;
  blacklist?: string[];
  subscription: Subscription;
  billingDetails?: BillingDetails;
  poNumber?: string;
  groups?: UserGroup[];
}

export interface UserWithOrganisation extends User {
  organisation: {
    id: string;
    name: string;
  };
  status: string;
  teams: {
    id: string;
    type: TeamType;
  }[];
  type: string;
}

export interface UserWithOrganisationDetails extends User {
  type: string;
  organisation: {
    id: string;
    name: string;
    teams: {
      id: string;
      role: TeamRole;
    }[];
    joinedAt: string;
    invitedBy: string;
  };
  teams: Team[];
  subscription: Subscription;
  status: string;
  isAccreditedProfessional?: boolean;
  isApplicationAdmin?: boolean;
  isBetaTester?: boolean;
}

export interface UserCredits {
  creditEarned: number;
  creditBalance: number;
  hasParent: boolean;
}

export interface PasswordChange {
  currenPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export interface Tier {
  id: Subscription;
  title: string;
  icon: string;
  description: string;
  header: string;
  benefits: string[];
}

export type SubscriptionChangeRequest = {
  scope: SubscriptionChangeScope;
  users?: {
    id: string;
    subscription: Subscription;
  }[];
  subscription?: Subscription;
  organisation?: {
    name?: string;
    logoUrl?: string;
    billingCycle: BillingCycle;
    billingDetails?: BillingDetails;
    billingRecipients?: string[];
  };
  organisationClosure?: {
    centralised: boolean;
    transferTo?: string;
  };
};

export type DowngradeCancelRequest = {
  scope: DowngradeCancelScope;
  users?: string[];
};

export interface SubscriptionPrice extends Price {
  billingDate: string;
  subscriptionQuantity: number;
  pendingUserDowngradeCount?: number;
  pendingOrganisationDowngrade?: boolean;
  pendingInvitationsCount?: number;
  referralCredits?: number;
  subscription?: Subscription;
  changes?: {
    integrationApiKeyRemoval?: boolean;
  };
}

export interface BillingCyclePrice extends Price {
  billingCycle: BillingCycle;
  executeDate: string;
  billingDate: string;
  subscriptionQuantity: number;
}

export interface UserRegister {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber: string;
  countryCode: string;
  sector: string;
  organisationType: string;
  referralCode: string;
  policiesCheckbox: boolean;
  subscription: Subscription;
}

export interface UserCreate {
  token: string;
  password: string;
  sector: string;
  organisationType: string;
}
