import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import { area } from '~/libs/geometry/area';
import { perimeter } from '~/libs/geometry/perimeter';
import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { geojsonSource } from '../../sources/geojson.source';
import { buildMeasurementsStyles, buildRulerStyles } from '../../styles/measurements.styles';
import { toGeoJSONGeometry } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-measure',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMeasureComponent extends MapInteractionAbstract implements OnInit, OnChanges, OnDestroy {
  @Input() measure: any;
  @Output() startDrawing = new EventEmitter<void>();
  @Output() stopDrawing = new EventEmitter<void>();
  @Output() updateMeasures = new EventEmitter<{ area: number; perimeter: number }>();

  private layer: VectorLayer;
  private interaction: Draw;
  private featureType: GeometryType;

  constructor(protected host: MapComponent) {
    super(host);
  }

  ngOnInit(): void {
    this.layer = geojsonSource({
      id: 'measure-tool',
      type: 'GEOJSON',
      geojson: {
        type: 'FeatureCollection',
        features: [],
      },
      projection: this.host.projection.view,
      viewProjection: this.host.projection.view,
      style: (feature: Feature, resolution: number) =>
        this.buildInteractionStyles(feature, resolution, this.host.projection.view, this.host.projection.calculate),
      visible: true,
      opacity: 1,
    });

    this.host.instance.getLayers().push(this.layer);

    this.setupInteraction();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { measure } = changes;

    if (measure && measure.currentValue) {
      const type = measure.currentValue.mode === 'area' ? GeometryType.POLYGON : GeometryType.LINE_STRING;
      if (this.featureType !== type) {
        this.featureType = type;
        this.clearPreviousMeasurement();
        this.setupInteraction();
      } else if (!measure.currentValue.area && !measure.currentValue.perimeter) {
        this.clearPreviousMeasurement();
      }
    }
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.interaction);

    this.layer.getSource().clear();
    this.layer.setSource(undefined);
    this.host.instance.getLayers().remove(this.layer);
    this.layer = undefined;
    this.interaction = undefined;
  }

  private buildInteractionStyles(feature: Feature, resolution: number, view: string, calculate: string) {
    this?.updateAreaAndPerimeter(feature);

    return [...buildMeasurementsStyles(feature, resolution, view, calculate), ...buildRulerStyles(feature, resolution, view, calculate)];
  }

  private clearPreviousMeasurement() {
    this.layer?.getSource()?.clear();
  }

  private onDrawEnd(event) {
    this.layer.getSource().addFeature(event.feature);
    this.stopDrawing.emit();
  }

  private onDrawStart() {
    this.clearPreviousMeasurement();
    this.startDrawing.emit();
  }

  private setupInteraction() {
    this.host.instance.removeInteraction(this.interaction);

    this.interaction = new Draw({
      features: new Collection(),
      type: this.featureType,
      style: (feature: Feature, resolution: number) =>
        this.buildInteractionStyles(feature, resolution, this.host.projection.view, this.host.projection.calculate),
    });

    addInteraction(this.host.instance, this.interaction);

    this.interaction.on('drawstart', (event) => this.onDrawStart());
    this.interaction.on('drawend', (event) => this.onDrawEnd(event));
  }

  private updateAreaAndPerimeter(feature: Feature) {
    const geometry = toGeoJSONGeometry(feature.getGeometry());

    if (geometry.type === 'Polygon') {
      this.updateMeasures.emit({
        area: area(geometry, this.host.projection.view, this.host.projection.calculate),
        perimeter: perimeter(geometry, this.host.projection.view, this.host.projection.calculate),
      });
    } else if (geometry.type === 'LineString') {
      this.updateMeasures.emit({
        area: null,
        perimeter: perimeter(geometry, this.host.projection.view, this.host.projection.calculate),
      });
    }
  }
}
