import { Component } from '@angular/core';

@Component({
  selector: 'la-icon-host',
  templateUrl: './icon-host.component.html',
  styleUrls: ['./icon-host.component.scss'],
})
export class IconHostComponent {
  public icon: string;

  constructor() {}

  public setIcon(icon: string) {
    this.icon = icon;
  }
}
