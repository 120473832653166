<mat-form-field appearance="outline" subscriptSizing="dynamic">
  <input
    #input
    type="text"
    matInput
    placeholder="Filter by organisation"
    [matAutocomplete]="auto"
    [formControl]="inputControl"
    (input)="filter()"
    (focus)="filter()"
  />

  <mat-autocomplete
    requireSelection
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event)"
    [displayWith]="displayWith.bind(this)"
  >
    @for (option of options; track option.value) {
    <mat-option [value]="option.value">
      {{ option.label }}
    </mat-option>
    }
  </mat-autocomplete>
  <mat-icon matSuffix>filter_list</mat-icon>
</mat-form-field>
