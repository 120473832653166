import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreditsTopUpDialogComponent } from '../../dialogs/credits-top-up-dialog/credits-top-up-dialog.component';

@Injectable()
export class CreditsDialogsService {
  constructor(private dialog: MatDialog) {}

  openTopUpDialog() {
    return this.dialog.open(CreditsTopUpDialogComponent);
  }
}
