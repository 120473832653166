import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { isNil } from 'lodash';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { StyleFunction } from 'ol/style/Style';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LayerDefinition } from '../../models/layer.model';
import { AnnotateMapperFunc } from '../../models/properties.model';
import { StyleDefinition } from '../../models/style.model';
import { StyleService } from '../../services/style.service';
import { arcgisSource } from '../../sources/arcgis.source';
import { bingSource } from '../../sources/bing.source';
import { geojsonSource } from '../../sources/geojson.source';
import { mvtSource } from '../../sources/mvt.source';
import { osmSource } from '../../sources/osm.source';
import { vsSource } from '../../sources/vs.source';
import { wmsSource } from '../../sources/wms.source';
import { xyzSource } from '../../sources/xyz.source';
import { boringStyle } from '../../styles/static.styles';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-layer',
  template: '',
  providers: [StyleService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() visible = true;
  @Input() opacity = 1;
  @Input() index = undefined;
  @Input() readonly = false;
  @Input() selectable = true;
  @Input() definition: LayerDefinition;
  @Input() style: StyleDefinition;
  @Input() order: (string | number)[] = [];
  @Input() creditsVersion: string;
  @Input() hasOsApiKey: boolean;
  @Input() annotateMapper?: AnnotateMapperFunc;

  @Output() paymentRequired = new EventEmitter<boolean>();
  @Output() tileLoadCompleted = new EventEmitter();

  private instance: TileLayer | VectorTileLayer | VectorLayer;

  private destroy$ = new Subject<void>();
  private _tileLoadError$ = new Subject<boolean>();

  constructor(private host: MapComponent, private http: HttpClient, private styleService: StyleService) {}

  ngOnInit(): void {
    this.instance = this.generateLayer();
    this.instance.setProperties({ readonly: this.readonly });

    if (!isNil(this.index)) {
      this.instance.setZIndex(this.index);
    }

    this.host.instance.getLayers().push(this.instance);

    this.styleService.layer = this.instance;

    this._tileLoadError$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((value) => {
      if (!this.hasOsApiKey) {
        this.paymentRequired.emit(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.host.instance.getLayers().remove(this.instance);
    this.styleService.layer = undefined;
    this.instance.setSource(null);
    this.instance.dispose();
    this.instance = undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { visible, opacity, readonly, index, order, definition, style, creditsVersion } = changes;

    if (visible && !visible.firstChange) {
      this.instance.setVisible(visible.currentValue);
    }

    if (opacity && !opacity.firstChange) {
      this.instance.setOpacity(opacity.currentValue);
    }

    if (index && !index.firstChange) {
      this.instance.setZIndex(index.currentValue);
    }

    if (readonly && !readonly.firstChange && readonly.currentValue !== readonly.previousValue) {
      this.instance.setProperties({ readonly: readonly.currentValue });
      this.regenerateLayer();
    }

    if (
      definition &&
      !definition.firstChange &&
      (definition.currentValue.id !== definition.previousValue.id || definition.currentValue.id === 'photos')
    ) {
      this.regenerateLayer();
    }

    if (style && !style.firstChange) {
      this.styleService.setFilters(style.currentValue.filters);
      this.instance.changed();
    }

    if (order && !order.firstChange && order.currentValue?.length) {
      this.instance.setProperties({ order: order.currentValue });
      this.instance.changed();
    }

    if (
      definition &&
      !definition.firstChange &&
      definition.currentValue.v !== definition.previousValue.v &&
      this.instance instanceof VectorLayer
    ) {
      const source = this.instance.getSource();

      const features = new GeoJSON().readFeatures(definition.currentValue.geojson, {
        dataProjection: definition.currentValue.projection,
        featureProjection: this.host.instance.getView().getProjection(),
      });

      source.clear();
      source.addFeatures(features);
    }

    if (
      creditsVersion &&
      !creditsVersion.firstChange &&
      creditsVersion.currentValue &&
      creditsVersion.currentValue !== creditsVersion.previousValue
    ) {
      this.tileLoadSuccess();
      this.refreshLayer();
    }
  }

  private generateLayer() {
    const { opacity, visible, http } = this;
    const viewProjection = this.host.projection.view;

    let style: StyleFunction = () => boringStyle;

    switch (this.definition.type) {
      case 'OSM':
        return osmSource({ ...this.definition, visible, opacity });
      case 'BING':
        return bingSource({ ...this.definition, visible, opacity });
      case 'XYZ':
        const minResolution =
          this.definition.maxZoom >= 13 ? undefined : this.host.instance.getView().getResolutionForZoom(this.definition.maxZoom + 1);
        const maxResolution = !this.definition.minZoom
          ? undefined
          : this.host.instance.getView().getResolutionForZoom(this.definition.minZoom);

        return xyzSource({
          ...this.definition,
          visible,
          opacity,
          minResolution,
          maxResolution,
          http,
          tileLoadSuccess: this.tileLoadSuccess.bind(this),
          tileLoadPaymentError: this.tileLoadPaymentError.bind(this),
        });
      case 'ARCGIS':
        return arcgisSource({ ...this.definition, visible, opacity });
      case 'WMS':
        return wmsSource({ ...this.definition, visible, opacity });
      case 'MVT':
        style = this.styleService.buildRenderer(this.host, this.style, { readonly: this.readonly, id: this.definition.id });
        return mvtSource({ ...this.definition, visible, opacity, http, style, selectable: this.selectable });
      case 'VECTORSPACE':
      case 'OSMM':
        style = this.styleService.buildRenderer(this.host, this.style, { readonly: this.readonly, id: this.definition.id });
        return vsSource({
          ...this.definition,
          visible,
          opacity,
          http,
          style,
          viewProjection,
          selectable: this.selectable,
          tileLoadSuccess: this.tileLoadSuccess.bind(this),
          tileLoadPaymentError: this.tileLoadPaymentError.bind(this),
        });
      case 'GEOJSON':
        style = this.styleService.buildRenderer(
          this.host,
          this.style,
          { readonly: this.readonly, id: this.definition.id },
          this.annotateMapper
        );
        return geojsonSource({
          ...this.definition,
          visible,
          opacity,
          style,
          viewProjection,
          readonly: this.readonly,
          selectable: this.selectable,
          order: this.order,
        });
      case 'BLANK':
        return new TileLayer({});
    }
  }

  private regenerateLayer() {
    const index = this.host.instance.getLayers().getArray().indexOf(this.instance);

    this.instance.dispose();
    this.instance = undefined;
    this.instance = this.generateLayer();
    this.instance.setProperties({ readonly: this.readonly, order: this.order });

    if (!isNil(this.index)) {
      this.instance.setZIndex(this.index);
    }

    this.host.instance.getLayers().removeAt(index);
    this.host.instance.getLayers().insertAt(index, this.instance);
  }

  private refreshLayer() {
    const source = this.instance.getSource();
    source.clear();
  }

  private tileLoadSuccess() {
    this._tileLoadError$.next(false);
    this.tileLoadCompleted.emit();
  }

  private tileLoadPaymentError() {
    this._tileLoadError$.next(true);
  }
}
