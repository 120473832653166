<la-dialog-header>Add OS Credits</la-dialog-header>

<la-dialog-content class="wrapper">
  <form [formGroup]="form">
    <mat-radio-group formControlName="creditOptions">
      <mat-radio-button class="la-radio-button-label" *ngFor="let option of creditOptions" [value]="option.credits">
        <p>
          <span>{{ option.label }}</span> for {{ option.credits | number: '' : 'en-GB' }} Credits
          <span *ngIf="option.savedAmount">Save {{ option.savedAmount }}</span>
        </p>
        <p>{{ option.description }}</p>
      </mat-radio-button>
    </mat-radio-group>
  </form>
  <p>(All prices exclude VAT)</p>
  <div *ngIf="isPaymentUpdated$ | async" class="success">Payment details successfully updated. You can now buy OS Credits.</div>
</la-dialog-content>

<la-dialog-footer>
  <ng-container la-dialog-footer-left>
    <div *ngIf="saving$ | async"><mat-spinner diameter="20"></mat-spinner></div>
  </ng-container>

  <ng-container la-dialog-footer-right>
    <div *ngIf="isPaymentInvalid$ | async; else noError">
      <mat-error>You do not have a valid payment method</mat-error>
      <button mat-raised-button color="primary" (click)="addPaymentMethod()">Add Payment Method</button>
    </div>

    <ng-template #noError>
      <button mat-button mat-dialog-close>Back</button>
      <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid || (saving$ | async)">Buy</button>
    </ng-template>
  </ng-container>
</la-dialog-footer>
