<la-dialog-header>{{ data.title }}</la-dialog-header>

<la-dialog-content>
  <ng-container *ngIf="isContentArray; else oneLineContent">
    <p *ngFor="let paragraph of data.content">
      {{ paragraph }}
    </p>
  </ng-container>
  <ng-template #oneLineContent>
    <p>{{ data.content }}</p>
  </ng-template>
</la-dialog-content>

<la-dialog-footer>
  <ng-container la-dialog-footer-right>
    <button mat-raised-button (click)="confirm()" [color]="okButtonColor">{{ data.ok }}</button>
  </ng-container>
</la-dialog-footer>
