import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppError } from '@core/api';

export const creditsActions = createActionGroup({
  source: 'Credits',
  events: {
    'Fetch Credits': emptyProps(),
    'Buy Credits': props<{ credits: number }>(),
    'Open Buy Credits Dialog': props<{ click_location: string; basemap_type?: string }>(),
    'Cancel Buy Credits Dialog': emptyProps(),
    'Update Payment Method': emptyProps(),
  },
});

export const creditsApiActions = createActionGroup({
  source: 'Credits/API',
  events: {
    'Fetch Credits Success': props<{ balance: number }>(),
    'Fetch Credits Fails': props<{ error: AppError }>(),
    'Buy Credits Success': props<{ balance: number }>(),
    'Buy Credits Fails': props<{ error: AppError }>(),
  },
});

export const clickUpgrade = createAction('[Paywall] Click upgrade subscription', props<{ upgrade_location: string }>());
