import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { creditsActions, creditsSelectors } from '../../store';

@Component({
  selector: 'la-paywall-balance',
  templateUrl: './paywall-balance.component.html',
  styleUrl: './paywall-balance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallBalanceComponent implements OnInit {
  balance$ = this.store.select(creditsSelectors.getCreditsBalance);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(creditsActions.fetchCredits());
  }

  openTopUpDialog() {
    this.store.dispatch(creditsActions.openBuyCreditsDialog({ click_location: 'profile_icon' }));
  }
}
