import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { OSMLayerDefinition } from '../models/layer.model';

type OSMLayerConfig = OSMLayerDefinition & { visible?: boolean; opacity?: number };

export const osmSource = (config: OSMLayerConfig) => {
  const { visible, opacity } = config;

  const options = {
    source: new OSM(),
    visible,
    opacity,
  };

  return new Tile(options);
};
