import turfArea from '@turf/area';
import { Geometry } from '@turf/helpers';

export function sphericalArea(geometry: GeoJSON.Geometry) {
  let sum = 0;

  switch (geometry.type) {
    case 'GeometryCollection':
      geometry.geometries.forEach((subGeometry) => {
        sum += turfArea(subGeometry as Geometry);
      });
      break;
    case 'MultiPolygon':
    case 'Polygon':
      sum = turfArea(geometry);
  }

  return sum;
}
