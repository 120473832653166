export function distance(p1: number[], p2: number[]): number {
  return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
}

export function lineDistance(coordinates: number[][]) {
  let sum = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    sum += distance(coordinates[i], coordinates[i + 1]);
  }

  return sum;
}

function perimeter(geometry: GeoJSON.Geometry) {
  let sum = 0;

  switch (geometry.type) {
    case 'GeometryCollection':
      geometry.geometries.forEach((subGeometry) => {
        sum += perimeter(subGeometry);
      });
      break;
    case 'MultiPolygon':
      geometry.coordinates.forEach((rings) => {
        rings.forEach((interior) => {
          sum += lineDistance(interior);
        });
      });
      break;
    case 'MultiLineString':
    case 'Polygon':
      geometry.coordinates.forEach((interior) => {
        sum += lineDistance(interior);
      });
      break;
    case 'LineString':
      sum += lineDistance(geometry.coordinates);
      break;
  }

  return sum;
}

export function mercatorPerimeter(geometry: GeoJSON.Geometry) {
  return perimeter(geometry);
}
