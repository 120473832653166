import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'laPlural',
})
export class PluralSingularPipe implements PipeTransform {
  transform(number: number, singularText: string, pluralText: string = null): string {
    let pluralWord = pluralText ? pluralText : `${singularText}s`;
    return number === 0 || number > 1 ? `${number} ${pluralWord}` : `${number} ${singularText}`;
  }
}
