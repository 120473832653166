import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import Transform from 'ol-ext/interaction/Transform';
import { always } from 'ol/events/condition';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';

import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapSelectComponent } from '../map-select/map-select.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-rotate-ext',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapRotateExtComponent extends MapInteractionAbstract implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;

  @Output() rotateStart = new EventEmitter<any>();
  @Output() rotateEnd = new EventEmitter<any>();

  private instance: Transform;

  constructor(protected host: MapComponent, private select: MapSelectComponent) {
    super(host);
  }

  ngOnInit(): void {
    this.instance = new Transform({
      features: this.select.instance.selectedInteractiveFeatures,
      scale: true,
      translate: true,
      stretch: true,
      rotate: true,
      selection: false,
      keepAspectRatio: always,
      translateFeature: false,
    });

    addInteraction(this.host.instance, this.instance);

    this.instance.setActive(this.active);
    this.instance.on(['rotatestart', 'scalestart', 'translatestart'], (event) => this.onRotateStart(event));
    this.instance.on(['rotateend', 'scaleend', 'translateend'], (event) => this.onRotateEnd(event));

    this.instance.setSelection(this.select.instance.selectedInteractiveFeatures);
    this.select.instance.selectedInteractiveFeatures.on('change', () =>
      this.instance?.setSelection(this.select.instance.selectedInteractiveFeatures)
    );
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);
    this.instance = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);

      if (this.select.instance.selectedInteractiveFeatures.getLength() > 0) {
        const layer = this.select.instance.getLayer(this.select.instance.selectedInteractiveFeatures.getArray()[0]);
        layer.changed();
      }
    }
  }

  private onRotateStart(event) {
    const features = event.features.getArray() as Feature[];
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      this.rotateStart.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private onRotateEnd(event) {
    const features = event.features.getArray() as Feature[];
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      features.forEach((feature) => this.enrichFeature(feature));

      this.rotateEnd.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }
}
