import { cloneDeep } from 'lodash';
import { ProjectionLike } from 'ol/proj';

import { projectionCode } from '../reproject';
import { mercatorGeneratePoint } from './resize-mercator';
import { sphericalGeneratePoint } from './resize-spherical';

/**
 * Moving points B and C based on width.
 *
 * A---------B
 * |         |
 * D---------C
 */
export function resizeRectangleWidth(feature: GeoJSON.Feature | GeoJSON.Geometry, width: number, projection: ProjectionLike) {
  projection = projectionCode(projection);

  const geometry: GeoJSON.Geometry = cloneDeep(feature['geometry'] ? feature['geometry'] : feature);

  if (geometry.type !== 'Polygon') {
    return geometry;
  }

  if (projection === 'EPSG:4326') {
    const b = sphericalGeneratePoint(geometry.coordinates[0][0], geometry.coordinates[0][1], width);
    const c = sphericalGeneratePoint(geometry.coordinates[0][3], geometry.coordinates[0][2], width);

    geometry.coordinates[0][1] = b;
    geometry.coordinates[0][2] = c;
  } else {
    const b = mercatorGeneratePoint(geometry.coordinates[0][0], geometry.coordinates[0][1], width);
    const c = mercatorGeneratePoint(geometry.coordinates[0][3], geometry.coordinates[0][2], width);

    geometry.coordinates[0][1] = b;
    geometry.coordinates[0][2] = c;
  }

  return geometry;
}

/**
 * Moving points C and D based on height.
 *
 * A---------B
 * |         |
 * D---------C
 */
export function resizeRectangleHeight(feature: GeoJSON.Feature | GeoJSON.Geometry, height: number, projection: ProjectionLike) {
  projection = projectionCode(projection);

  const geometry: GeoJSON.Geometry = cloneDeep(feature['geometry'] ? feature['geometry'] : feature);

  if (geometry.type !== 'Polygon') {
    return geometry;
  }

  if (projection === 'EPSG:4326') {
    const c = sphericalGeneratePoint(geometry.coordinates[0][1], geometry.coordinates[0][2], height);
    const d = sphericalGeneratePoint(geometry.coordinates[0][0], geometry.coordinates[0][3], height);

    geometry.coordinates[0][2] = c;
    geometry.coordinates[0][3] = d;
  } else {
    const c = mercatorGeneratePoint(geometry.coordinates[0][1], geometry.coordinates[0][2], height);
    const d = mercatorGeneratePoint(geometry.coordinates[0][0], geometry.coordinates[0][3], height);

    geometry.coordinates[0][2] = c;
    geometry.coordinates[0][3] = d;
  }

  return geometry;
}

export function resizeRectangle(feature: GeoJSON.Feature | GeoJSON.Geometry, width: number, height: number, projection: ProjectionLike) {
  return resizeRectangleHeight(resizeRectangleWidth(feature, width, projection), height, projection);
}

/**
 * Moving all points from a single center point
 */
export function resizeCircle(feature: GeoJSON.Feature | GeoJSON.Geometry, center: number[], radius: number, projection: ProjectionLike) {
  projection = projectionCode(projection);

  const geometry: GeoJSON.Geometry = cloneDeep(feature['geometry'] ? feature['geometry'] : feature);

  if (geometry.type !== 'Polygon') {
    return geometry;
  }

  if (projection === 'EPSG:4326') {
    for (let i = 0; i < geometry.coordinates[0].length; i++) {
      const point = sphericalGeneratePoint(center, geometry.coordinates[0][i], radius);

      geometry.coordinates[0][i] = point;
    }
  } else {
    for (let i = 0; i < geometry.coordinates[0].length; i++) {
      const point = mercatorGeneratePoint(center, geometry.coordinates[0][i], radius);

      geometry.coordinates[0][i] = point;
    }
  }

  return geometry;
}

export function generatePoint(a: GeoJSON.Position, b: GeoJSON.Position, width: number, projection: ProjectionLike) {
  const code = projectionCode(projection);

  if (code === 'EPSG:4326') {
    return sphericalGeneratePoint(a, b, width);
  } else {
    return mercatorGeneratePoint(a, b, width);
  }
}

/**
 * Moving points B based on length.
 *
 * A---------B
 *
 */
export function resizeLineLength(feature: GeoJSON.Feature | GeoJSON.Geometry, length: number, projection: ProjectionLike) {
  projection = projectionCode(projection);

  const geometry: GeoJSON.Geometry = cloneDeep(feature['geometry'] ? feature['geometry'] : feature);

  if (geometry.type !== 'LineString') {
    return geometry;
  }

  if (projection === 'EPSG:4326') {
    const b = sphericalGeneratePoint(geometry.coordinates[0], geometry.coordinates[1], length);

    geometry.coordinates[1] = b;
  } else {
    const b = mercatorGeneratePoint(geometry.coordinates[0], geometry.coordinates[1], length);

    geometry.coordinates[1] = b;
  }

  return geometry;
}
