import { Circle, Fill, Stroke, Style } from 'ol/style';

export const boringStyle: Style = new Style({
  fill: new Fill({ color: 'rgba(136, 138, 255, 0.3)' }),
  stroke: new Stroke({ color: '#2225ff' }),
  image: new Circle({
    radius: 4,
    fill: new Fill({ color: 'rgba(136, 138, 255, 0.3)' }),
    stroke: new Stroke({ color: '#2225ff' }),
  }),
});

export const bufferStyle: Style = new Style({
  fill: new Fill({ color: 'rgba(0, 0, 0, 0)' }),
  stroke: new Stroke({ color: 'rgb(255, 0, 0)' }),
  zIndex: 10,
});

export const hiddenStyle: Style = new Style();

export const unfilteredStyle: Style = new Style({
  fill: new Fill({ color: 'rgba(0, 0, 0, 0.15)' }),
  stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.15)' }),
  image: new Circle({
    radius: 4,
    fill: new Fill({ color: 'rgba(0, 0, 0, 0.15)' }),
    stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.15)' }),
  }),
});
