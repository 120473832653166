import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import VectorLayer from 'ol/layer/Vector';
import { geojsonSource } from '../../sources/geojson.source';
import { bufferStyle } from '../../styles/static.styles';
import { fromGeoJSON } from '../../utils/feature.utils';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-buffer-preview',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBufferPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() features: GeoJSON.Feature[] = [];

  private instance: VectorLayer;

  constructor(private host: MapComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.features && !changes.features.firstChange) {
      const source = this.instance.getSource();
      source.clear();

      if (this.features?.length) {
        const features = this.features.map((feature) => {
          return fromGeoJSON(feature);
        });

        source.addFeatures(features);
      }
    }
  }

  ngOnInit(): void {
    this.instance = geojsonSource({
      id: 'buffer-preview',
      type: 'GEOJSON',
      geojson: {
        type: 'FeatureCollection',
        features: this.features || [],
      },
      projection: this.host.projection.view,
      viewProjection: this.host.projection.view,
      style: () => bufferStyle,
      visible: true,
      opacity: 1,
    });

    this.instance.setZIndex(1000);
    this.host.instance.getLayers().push(this.instance);
  }

  ngOnDestroy(): void {
    this.instance.getSource().clear();
    this.instance.setSource(undefined);
    this.host.instance.getLayers().remove(this.instance);
    this.instance = undefined;
  }
}
