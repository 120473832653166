import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { Style, Text } from 'ol/style';
import { Options as TextOptions } from 'ol/style/Text';

export function textOnPoint(text: string, point: Point, options: Partial<TextOptions> = {}): Style {
  return new Style({
    geometry: () => point,
    text: new Text({
      text,
      font: '13px Roboto',
      ...options,
    }),
  });
}

export function textOnLine(text: string, line: LineString, options: Partial<TextOptions> = {}): Style {
  return new Style({
    geometry: () => line,
    text: new Text({
      text,
      placement: 'line',
      font: '13px Roboto',
      ...options,
    }),
  });
}
