import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EMAIL_PATTERN } from '@core/common';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'la-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordPageComponent {
  formSent = false;
  disabled = false;
  isGenericError = false;

  public form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.maxLength(128), Validators.pattern(EMAIL_PATTERN)]),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef
  ) {}

  public send() {
    this.disabled = true;
    this.authenticationService.initializePasswordChange(this.form.value.email).subscribe(
      () => {
        this.formSent = true;
        this.disabled = false;
        this.cd.markForCheck();
      },
      () => {
        this.disabled = false;
        this.isGenericError = true;
        this.cd.markForCheck();
      }
    );
  }
}
