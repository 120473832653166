import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { ActionErrorComponent } from './components/action-error/action-error.component';
import { ErrorSectionComponent } from './components/error-section/error-section.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { ExpiredInvitationPageErrorComponent } from './pages/expired-invitation-page-error/expired-invitation-page-error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatCardModule, MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule],
  declarations: [
    PageErrorComponent,
    ActionErrorComponent,
    PageNotFoundComponent,
    ErrorSectionComponent,
    ExpiredInvitationPageErrorComponent,
  ],
  exports: [PageErrorComponent, ActionErrorComponent, ErrorSectionComponent],
})
export class ErrorsModule {}
