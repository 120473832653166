import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'laFileName' })
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    return value.split('.').slice(0, -1).join('.');
  }
}

@Pipe({ name: 'laExtension' })
export class ExtensionPipe implements PipeTransform {
  transform(value: string): string {
    const ext = value.split('.').pop();
    return ext !== 'geojson' ? ext.toUpperCase() : 'GeoJSON';
  }
}
