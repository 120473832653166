import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Collection from 'ol/Collection';
import GeometryType from 'ol/geom/GeometryType';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';

import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';
import { getLineStringGeometryProperties } from '../../utils/draw.utils';
import { isKeyPressed } from '../../services/keypress.service';

@Component({
  selector: 'la-map-split',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSplitComponent extends MapInteractionAbstract implements OnInit, OnDestroy {
  @Input() layerId: string;

  @Output() splitEnd = new EventEmitter<{ line: GeoJSON.Feature }>();

  private instance: Draw;

  constructor(protected host: MapComponent) {
    super(host);
  }

  ngOnInit(): void {
    const layer = this.getLayerById(this.layerId);

    if (layer && layer instanceof VectorLayer) {
      this.instance = new Draw({
        features: new Collection(),
        type: 'LineString' as GeometryType,
        ...getLineStringGeometryProperties(layer.getSource().getFeatures(), () => isKeyPressed(['KeyS'])),
      });

      addInteraction(this.host.instance, this.instance);

      this.instance.setActive(true);
      this.instance.once('drawend', (event) => this.onDrawEnd(event));
    }
  }

  ngOnDestroy(): void {
    if (this.instance) {
      this.host.instance.removeInteraction(this.instance);
      this.instance = undefined;
    }
  }

  private onDrawEnd(event) {
    this.host.instance.removeInteraction(this.instance);
    this.splitEnd.emit({ line: toGeoJSON(event.feature) });
  }
}
