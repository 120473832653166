import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
import { LineString, Polygon, MultiPolygon } from 'ol/geom';
import { lineDistance } from '../perimeter/perimeter-mercator';
import { getBBox, inBBox } from '../extent/extent';

/**
 * Returns the path from start point to end point if exist on the boundary of one of the provided features.
 */
export function snapLineToLine(features: Feature[], start, end) {
  const featuresCoords = features
    .filter((f) => /LineString|Polygon|MultiPolygon/.test(f.getGeometry().getType()))
    .map((f) => {
      const geometry = f.getGeometry() as LineString | Polygon | MultiPolygon;
      switch (geometry.getType()) {
        case 'Polygon':
          return geometry.getCoordinates()[0];
        case 'MultiPolygon':
          return geometry.getCoordinates()[0][0];
        default:
          return geometry.getCoordinates();
      }
    })
    .filter((coords: number[][]) => {
      // quick elimination if start and end are not inside feature bbox
      const bbox = getBBox(coords);
      return inBBox(bbox, start) && inBBox(bbox, end);
    }) as number[][][];

  for (const coords of featuresCoords) {
    let startIndex = -1;
    let endIndex = -1;

    // test start point
    startIndex = pointOnNodeIndex(start, coords);

    if (startIndex === -1) {
      const lineIndex = pointOnLineIndex(coords, start);

      if (lineIndex !== -1) {
        // if point is on line, add it as a new line node
        coords.splice(lineIndex + 1, 0, start);
        startIndex = lineIndex + 1;
      }
    }

    if (startIndex === -1) {
      // start point is not in feature so skip
      continue;
    }

    // test end point
    endIndex = pointOnNodeIndex(end, coords);

    if (endIndex === -1) {
      const lineIndex = pointOnLineIndex(coords, end);

      if (lineIndex !== -1) {
        // if point is on line, add it as a new line node
        coords.splice(lineIndex + 1, 0, end);
        endIndex = lineIndex + 1;

        if (startIndex > lineIndex) {
          startIndex++;
        }
      }
    }

    if (endIndex === -1 || startIndex === endIndex) {
      // skip when end point is not in the feature or start point is equal to end point
      continue;
    }

    const isReversed = startIndex > endIndex;

    const pathA = isReversed ? coords.slice(endIndex, startIndex + 1).reverse() : coords.slice(startIndex, endIndex + 1);

    if (!isEqual(coords[0], coords[coords.length - 1])) {
      return pathA;
    } else {
      // if path is closed check also pathB
      const pathB = isReversed
        ? [...coords.slice(startIndex, coords.length - 1), ...coords.slice(0, endIndex + 1)]
        : [...coords.slice(endIndex, coords.length - 1), ...coords.slice(0, startIndex + 1)].reverse();
      return getShortestPath(pathA, pathB);
    }
  }

  return undefined;
}

/**
 * Returns the shortest path
 */
export function getShortestPath(pathA: number[][], pathB: number[][]) {
  const perimeterA = lineDistance([...pathA, pathA[0]]);
  const perimeterB = lineDistance([...pathB, pathB[0]]);

  return perimeterA < perimeterB ? pathA : pathB;
}

/**
 * Returns the position of the first occurrence of a point on a line node.
 */
export function pointOnNodeIndex(pt, line) {
  for (let i = 0; i < line.length; i++) {
    if (isEqual(pt, line[i])) {
      return i;
    }
  }

  return -1;
}

/**
 * Returns the position of the first occurrence of a point on a line.
 */
export function pointOnLineIndex(line, pt) {
  for (let i = 0; i < line.length - 1; i++) {
    if (isPointOnLine(pt, line[i], line[i + 1])) {
      return i;
    }
  }

  return -1;
}

/**
 * Is point on line?
 */
export function isPointOnLine(pt, start, end, threshold = 0.5) {
  const x = pt[0];
  const y = pt[1];
  const x1 = start[0];
  const y1 = start[1];
  const x2 = end[0];
  const y2 = end[1];
  const dxc = x - x1;
  const dyc = y - y1;
  const dxl = x2 - x1;
  const dyl = y2 - y1;
  const cross = dxc * dyl - dyc * dxl;

  if (Math.abs(cross) > threshold) {
    return false;
  }

  return Math.abs(dxl) >= Math.abs(dyl)
    ? dxl > 0
      ? x1 <= x && x <= x2
      : x2 <= x && x <= x1
    : dyl > 0
    ? y1 <= y && y <= y2
    : y2 <= y && y <= y1;
}
