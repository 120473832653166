import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
import { LineString, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { createRegularPolygon, Options as DrawOptions } from 'ol/interaction/Draw';

import { snapLineToLine } from '~/libs/geometry/snap-to-line';

import { FeatureDefinition } from '../models/style.model';

export function defineGeometryProperties(featureDefinition: FeatureDefinition, features: Feature[]): Partial<DrawOptions> {
  if (featureDefinition.id === 'rectangle') {
    return getRectangleGeometryProperties();
  } else if (featureDefinition.id === 'circle') {
    return getCircleGeometryProperties();
  } else if (featureDefinition.id === 't_mark') {
    return getTMarkGeometryProperties();
  } else if (featureDefinition.geometryType === 'LineString') {
    return getLineStringGeometryProperties(features);
  } else if (featureDefinition.geometryType === 'Polygon') {
    return getPolygonGeometryProperties(features);
  }

  return undefined;
}

function getRectangleGeometryProperties(): Partial<DrawOptions> {
  const length = (a: any, b: any) => Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
  const compare = (a: number, b: number) => a.toFixed(4) === b.toFixed(4);

  return {
    maxPoints: 3,
    geometryFunction: (coordinates, geometry) => {
      const newCoordinates = [];

      if (typeof coordinates[0] === 'number') {
        return geometry;
      }

      if (coordinates[0].length === 2) {
        newCoordinates.push(coordinates[0][0]);
        newCoordinates.push(coordinates[0][1]);
      } else if (coordinates[0].length === 3) {
        // first with second
        if (compare(coordinates[0][0][0], coordinates[0][1][0]) && compare(coordinates[0][0][1], coordinates[0][1][1])) {
          coordinates[0][1][0] = coordinates[0][0][0] - 0.00001;
          coordinates[0][1][1] = coordinates[0][0][1] - 0.00001;
        }
        // first with third
        if (compare(coordinates[0][0][0], coordinates[0][2][0]) && compare(coordinates[0][0][1], coordinates[0][2][1])) {
          coordinates[0][2][0] = coordinates[0][0][0] - 0.00002;
          coordinates[0][2][1] = coordinates[0][0][1] - 0.00002;
        }
        // second with third
        if (compare(coordinates[0][1][0], coordinates[0][2][0]) && compare(coordinates[0][1][1], coordinates[0][2][1])) {
          coordinates[0][2][0] = coordinates[0][1][0] - 0.00003;
          coordinates[0][2][1] = coordinates[0][1][1] - 0.00003;
        }

        /*
               D _______________ C  (P)
                |               |   /|
                |               |  / |
                |               | /  |
                |_______________|/___|
               (A)             (B)   X
        */

        const A = coordinates[0][0]; // first clicked point
        const B = coordinates[0][1]; // second clicked point
        const P = coordinates[0][2]; // third clicked point

        const APx = P[0] - A[0];
        const APy = P[1] - A[1];
        const ABx = B[0] - A[0];
        const ABy = B[1] - A[1];

        const AX = length(A, P) * Math.cos(Math.atan2(APy, APx) - Math.atan2(ABy, ABx));
        const AB = length(A, B);

        const AXx = (ABx * AX) / AB;
        const AXy = (ABy * AX) / AB;
        const X = [A[0] + AXx, A[1] + AXy];

        const XPx = P[0] - X[0];
        const XPy = P[1] - X[1];

        const C = [B[0] + XPx, B[1] + XPy];
        const D = [A[0] + XPx, A[1] + XPy];

        newCoordinates.push(A);
        newCoordinates.push(B);
        newCoordinates.push(C);
        newCoordinates.push(D);
        newCoordinates.push(A);
      }

      if (!geometry) {
        geometry = new Polygon([newCoordinates]);
      } else {
        geometry.setCoordinates([newCoordinates]);
      }

      return geometry;
    },
  };
}

function getCircleGeometryProperties(): Partial<DrawOptions> {
  return {
    geometryFunction: (coordinates, geometry, projection) => {
      const compare = (a: number, b: number) => a.toFixed(4) === b.toFixed(4);

      if (coordinates.length === 2) {
        if (compare(coordinates[0][0], coordinates[1][0]) && compare(coordinates[0][1], coordinates[1][1])) {
          coordinates[1][0] = coordinates[0][0] - 0.00001;
          coordinates[1][1] = coordinates[0][1] - 0.00001;
        }
      }

      return createRegularPolygon(64)(coordinates, geometry, projection);
    },
    type: GeometryType.CIRCLE,
  };
}

export function getLineStringGeometryProperties(features: Feature[], canSnapLineToLine: () => boolean = () => true): Partial<DrawOptions> {
  let history = [];
  let steps = 0;

  return {
    geometryFunction: (coordinates, geometry) => {
      if (!geometry) {
        history = [];
        steps = 0;
      }

      // new coords was added
      if (coordinates.length > steps) {
        history = geometry ? geometry.getCoordinates() : [coordinates[0]];
        steps = coordinates.length;
      }

      const start = history.slice(-1)[0];
      const end = coordinates.slice(-1)[0];
      let newCoords = [...history, end];

      if (canSnapLineToLine()) {
        const res = snapLineToLine(features, start, end);

        if (res) {
          newCoords = [...newCoords.slice(0, -2), ...res];
        }
      }

      // detect double-click - do not create new points
      if (newCoords.length > 2) {
        const last = newCoords[newCoords.length - 1];
        const beforeLast = newCoords[newCoords.length - 2];

        if (isEqual(last, beforeLast)) {
          newCoords = newCoords.slice(0, -1);
        }
      }

      if (!geometry) {
        geometry = new LineString(newCoords);
      } else {
        geometry.setCoordinates(newCoords);
      }

      return geometry;
    },
  };
}

export function getPolygonGeometryProperties(features: Feature[], canSnapLineToLine: () => boolean = () => true): Partial<DrawOptions> {
  let history = [];
  let steps = 0;

  return {
    geometryFunction: ([coordinates]: number[][], geometry) => {
      if (!geometry) {
        history = [];
        steps = 0;
      }

      // new coords was added
      if (coordinates.length > steps) {
        history = geometry ? geometry.getCoordinates()[0].slice(0, -1) : [coordinates[0]];
        steps = coordinates.length;
      }

      const start = history.slice(-1)[0];
      const end = coordinates.slice(-1)[0];
      let newCoords = [...history, end];

      if (canSnapLineToLine()) {
        const res = snapLineToLine(features, start, end);

        if (res) {
          newCoords = [...newCoords.slice(0, -2), ...res];
        }
      }

      if (!geometry) {
        geometry = new Polygon([[...newCoords, newCoords[0]]]);
      } else {
        geometry.setCoordinates([[...newCoords, newCoords[0]]]);
      }

      return geometry;
    },
  };
}

function getTMarkGeometryProperties(): Partial<DrawOptions> {
  const compare = (a: number, b: number) => a.toFixed(4) === b.toFixed(4);

  return {
    maxPoints: 2,
    geometryFunction: (coordinates, geometry) => {
      let newCoordinates = [];

      if (typeof coordinates[0] === 'number') {
        return geometry;
      } else if (coordinates.length === 2) {
        // first with second
        if (compare(coordinates[0][0] as number, coordinates[1][0]) && compare(coordinates[0][1] as number, coordinates[1][1])) {
          coordinates[1][0] = (coordinates[0][0] as number) - 0.00001;
          coordinates[1][1] = (coordinates[0][1] as number) - 0.00001;
        }

        const A = coordinates[0] as [number, number]; // stem base
        const B = coordinates[1] as [number, number]; // stem end

        newCoordinates = [A, B];
      }

      if (!geometry) {
        geometry = new LineString(newCoordinates);
      } else {
        geometry.setCoordinates(newCoordinates);
      }

      return geometry;
    },
  };
}
