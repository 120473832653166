import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Organisation, User, UserGroup } from '@core/api';
import { APP_CONFIG, AppConfig } from '@core/config';
import { organisationSelectors, TokenService, userSelectors } from '@core/store';
import { CreditsTopUpDialogComponent } from '~/shared/paywall';

@Component({
  selector: 'la-contextual-header',
  styleUrls: ['./contextual-header.component.scss'],
  templateUrl: './contextual-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualHeaderComponent implements OnInit, OnDestroy {
  @Input() logoNavigates = true;
  @Output() navigate = new EventEmitter<MouseEvent>();
  @Output() openBuyMoreOsCredits = new EventEmitter();

  @HostBinding('class.middle') @Input() middle = false;

  public user: User;
  public creditsBalance: string;
  public organization: Organisation;
  public isNatureReportingEnabled: boolean;

  private config = inject<AppConfig>(APP_CONFIG);

  private destroy$ = new Subject();

  constructor(
    private store: Store,
    private tokenService: TokenService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit() {
    this.store
      .select(userSelectors.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        this.cd.markForCheck();
      });

    this.store
      .select(organisationSelectors.getOrganisation)
      .pipe(takeUntil(this.destroy$))
      .subscribe((organization) => {
        this.organization = organization;
        this.cd.markForCheck();
      });

    this.store
      .select(organisationSelectors.getUserHasNatureReportingAccess)
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasAccess) => {
        this.isNatureReportingEnabled = hasAccess;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get logoUrl() {
    return this.organization?.logoUrl ? this.getOrganisationLogoUrl(this.organization?.logoUrl) : 'assets/layout/short-logo-green.svg';
  }

  get hasOrganization() {
    return !!this.organization;
  }

  get isAdmin() {
    return this.user.groups?.includes(UserGroup.ADMIN);
  }

  openTopUpDialog() {
    this.dialog.open(CreditsTopUpDialogComponent, { disableClose: true, autoFocus: false });
    this.openBuyMoreOsCredits.emit();
  }

  storeLastLocation() {
    this.navigate.emit();

    localStorage.setItem('last.location', this.location.path());
  }

  private getOrganisationLogoUrl(storage: string): string {
    if (!storage) {
      return '';
    }

    return `${this.config.api.mediaUrl}/prints/logo/${storage}?access_token=${this.tokenService.token?.access_token}`;
  }
}
