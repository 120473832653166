import { HttpClient } from '@angular/common/http';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import RenderFeature from 'ol/render/Feature';
import VectorTileSource from 'ol/source/VectorTile';
import { StyleFunction } from 'ol/style/Style';
import { createXYZ } from 'ol/tilegrid';
import VectorTile from 'ol/VectorTile';

import { MVTLayerDefinition } from '../models/layer.model';

type MVTLayerConfig = MVTLayerDefinition & {
  style: StyleFunction;
  http: HttpClient;
  visible?: boolean;
  opacity?: number;
  selectable?: boolean;
};

const DEFAULT_RENDER_MODE = 'image';

const tileLoadFunction = (http?: HttpClient) => {
  if (!http) {
    return undefined;
  }

  return {
    tileLoadFunction: (tile: VectorTile, url: string) => {
      tile.setLoader((extent, resolution, projection) => {
        http.get(url, { responseType: 'arraybuffer' }).subscribe((arrayBufferView: BlobPart) => {
          const format = tile.getFormat();
          const features = format.readFeatures(arrayBufferView, {
            extent,
            featureProjection: projection,
          });
          tile.setFeatures(features as any);
        });
      });
    },
  };
};

export const mvtSource = (config: MVTLayerConfig) => {
  const { id, style, http, visible, opacity, selectable } = config;

  const options = {
    source: new VectorTileSource({
      format: new MVT(),
      tileGrid: createXYZ({
        minZoom: config.minZoom || 0,
        maxZoom: config.maxZoom || 15,
      }),
      projection: config.projection,
      transition: 0,
      url: config.url,
      ...tileLoadFunction(http),
    } as any),
    renderMode: config.renderMode || DEFAULT_RENDER_MODE,
    minResolution: config.minResolution,
    maxResolution: config.maxResolution,
    projection: config.projection,
    style,
    visible,
    opacity,
  };

  const vectorTileLayer = new VectorTileLayer(options);
  vectorTileLayer.setProperties({ id, selectable });

  return vectorTileLayer;
};
