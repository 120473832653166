import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapBrowserEvent, PluggableMap } from 'ol';
import Collection from 'ol/Collection';
import Feature, { FeatureLike } from 'ol/Feature';
import BaseEvent from 'ol/events/Event';
import { click, shiftKeyOnly } from 'ol/events/condition';
import Select, { Options, SelectEvent } from 'ol/interaction/Select';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Pixel } from 'ol/pixel';
import RenderFeature from 'ol/render/Feature';
import Source from 'ol/source/Source';
import { area as calculateArea } from '~/libs/geometry/area';
import { perimeter as calculatePerimeter } from '~/libs/geometry/perimeter';
import { ContextMenuOutcome, SelectOutcome, SelectedGeoJSONFeature, SelectedRenderFeature } from '../../models/select.model';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';
import { isKeyPressed } from '../../services/keypress.service';

export interface SelectOptions extends Options {
  singleLayer?: boolean;
}

function clear(object) {
  for (const property in object) {
    delete object[property];
  }
}

function includes(arr, obj) {
  return arr.indexOf(obj) >= 0;
}

interface FeatureAtPixel {
  layer: Layer<Source>;
  feature: FeatureLike;
}

export class MapSelect extends Select {
  selectedInteractiveFeatures = new Collection<Feature>([]);
  singleLayer = false;

  order: FeatureAtPixel[] = [];

  constructor(options: SelectOptions) {
    super(options);

    this.singleLayer = options.singleLayer;

    this['applySelectedStyle_'] = (feature: Feature | RenderFeature) => {
      if (feature instanceof Feature) {
        super['applySelectedStyle_'](feature);
      }
    };

    this['restorePreviousStyle_'] = (feature: Feature | RenderFeature) => {
      if (feature instanceof Feature) {
        super['restorePreviousStyle_'](feature);
      }
    };
  }

  getOriginalOrder(features: FeatureAtPixel[]) {
    if (
      this.order.length &&
      this.order.every((f) =>
        includes(
          features.map((d) => d.feature),
          f.feature
        )
      ) &&
      this.order.length === features.length
    ) {
      return this.order;
    }

    this.order = [...features];

    return this.order;
  }

  getFeaturesAtPixel(pixel: Pixel, map: PluggableMap) {
    const result: FeatureAtPixel[] = [];

    let firstLayer: Layer<Source>;

    map.forEachFeatureAtPixel<unknown, void>(
      pixel,
      (feature, layer) => {
        if (!this['filter_'](feature, layer)) {
          return;
        }

        if (!firstLayer) {
          firstLayer = layer;
        }

        if (this.singleLayer) {
          if (firstLayer === layer) {
            result.push({ layer, feature });
          }
        } else {
          result.push({ layer, feature });
        }
      },
      {
        layerFilter: this['layerFilter_'],
        hitTolerance: this['hitTolerance_'],
      }
    );

    return this.getOriginalOrder(result);
  }

  getNextFeatureToSelect(pixel: Pixel, map: PluggableMap, selected: FeatureLike[]) {
    const underlying = this.getFeaturesAtPixel(pixel, map);

    const indexes = selected
      .map((feature) => underlying.findIndex((f) => f.feature.getId() === feature.getId()))
      ?.sort()
      ?.reverse();

    const nextIndex = !underlying.length ? -1 : !indexes.length ? 0 : indexes[0] === underlying.length - 1 ? 0 : indexes[0] + 1;
    return nextIndex === -1 ? undefined : underlying[nextIndex];
  }

  handleEvent(mapBrowserEvent: MapBrowserEvent<UIEvent>): boolean {
    if (!this['condition_'](mapBrowserEvent)) {
      return true;
    }
    const add = this['addCondition_'](mapBrowserEvent);
    const remove = this['removeCondition_'](mapBrowserEvent);
    const toggle = this['toggleCondition_'](mapBrowserEvent);
    const set = !add && !remove && !toggle;
    const map = mapBrowserEvent.map;
    const features = this.getFeatures();

    const deselected = [];
    const selected = [];

    if (set) {
      clear(this['featureLayerAssociation_']);

      const nextSelection = this.getNextFeatureToSelect(mapBrowserEvent.pixel, map, features.getArray());

      if (nextSelection) {
        selected.push(nextSelection.feature);
        this['addFeatureLayerAssociation_'](nextSelection.feature, nextSelection.layer);
      }

      for (var i = features.getLength() - 1; i >= 0; --i) {
        var feature = features.item(i);
        var index = selected.indexOf(feature);
        if (index > -1) {
          selected.splice(index, 1);
        } else {
          features.remove(feature);
          deselected.push(feature);
        }
      }
      if (selected.length !== 0) {
        features.extend(selected);
      }
    } else {
      const nextSelection = this.getNextFeatureToSelect(mapBrowserEvent.pixel, map, features.getArray());

      if (nextSelection) {
        if ((add || toggle) && !includes(features.getArray(), nextSelection.feature)) {
          selected.push(nextSelection.feature);
          this['addFeatureLayerAssociation_'](nextSelection.feature, nextSelection.layer);
        } else if ((remove || toggle) && includes(features.getArray(), nextSelection.feature)) {
          deselected.push(nextSelection.feature);
          this['removeFeatureLayerAssociation_'](nextSelection.feature);
        }
      }

      for (var j = deselected.length - 1; j >= 0; --j) {
        features.remove(deselected[j]);
      }
      features.extend(selected);
    }

    if (selected.length > 0 || deselected.length > 0) {
      this.dispatchEvent(new SelectEvent('select' as any, selected, deselected, mapBrowserEvent));
    }

    return true;
  }

  select(features?: (Feature | RenderFeature)[], layer?: VectorLayer | VectorTileLayer) {
    const collection = this.getFeatures();
    collection.clear();

    if (features && features.length && layer) {
      collection.extend(features as Feature[]);

      features.forEach((feature) => this['addFeatureLayerAssociation_'](feature, layer));
    } else {
      this['featureLayerAssociation_'] = {};
    }

    this.dispatchEvent(new BaseEvent('select'));
  }
}

@Component({
  selector: 'la-map-select',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;
  @Input() multi = true;

  @Output() selected = new EventEmitter<SelectOutcome>();
  @Output() contextMenu = new EventEmitter<ContextMenuOutcome>();

  public selection: SelectOutcome;
  public instance: MapSelect;
  private shiftRequired = true;

  constructor(private host: MapComponent) {}

  ngOnInit(): void {
    this.instance = new MapSelect({
      condition: (event) => click(event) && !isKeyPressed(['KeyA']),
      toggleCondition: () => false,
      addCondition: (event) => (!this.shiftRequired || shiftKeyOnly(event)) && this.multi,
      filter: (feature) => !!feature.getId(),
      singleLayer: true,
      style: null,
    });

    this.instance.setActive(this.active);

    addInteraction(this.host.instance, this.instance);

    this.setContextualMenuEventHandlers();

    this.instance.on('select', (event) => {
      const features: (Feature | RenderFeature)[] = event.target.getFeatures().getArray();

      this.instance.selectedInteractiveFeatures.clear();

      if (features.length) {
        const lastFeature = features[features.length - 1];
        const lastLayer = this.instance.getLayer(lastFeature);

        if (!lastLayer.getProperties().selectable) {
          event.target.getFeatures().clear();
          this.updateStyles();
          this.selection = {
            layerId: null,
            features: [],
          };
          this.instance.selectedInteractiveFeatures.changed();
          this.selected.emit(this.selection);
          return;
        }

        if (features.length > 1) {
          const firstLayer = this.instance.getLayer(features[0]);

          if (firstLayer.getProperties().id !== lastLayer.getProperties().id) {
            event.target.getFeatures().clear();
            event.target.getFeatures().push(lastFeature);
          }
        }

        if (!lastLayer.getProperties().readonly) {
          const interactive = event.target
            .getFeatures()
            .getArray()
            .filter((feature: FeatureLike) => feature instanceof Feature);

          this.instance.selectedInteractiveFeatures.extend(interactive);
        }
      }

      this.updateStyles();

      this.selection = {
        layerId: this.getLayerId(features),
        features: features.map((feature) => this.getFeatureData(feature)),
      };

      this.instance.selectedInteractiveFeatures.changed();
      this.selected.emit(this.selection);
    });
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);
    this.instance = undefined;
    this.selection = undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);
    }
  }

  toggleShiftRequired(required: boolean) {
    this.shiftRequired = required;
  }

  private getFeatureData(feature: Feature | RenderFeature): SelectedGeoJSONFeature | SelectedRenderFeature {
    const id = feature.getId();
    const properties = feature.getProperties();
    delete properties['geometry'];

    if (feature instanceof Feature) {
      const result = toGeoJSON(feature);

      if (!result.properties) {
        result.properties = {};
      }

      if (
        (result.geometry.type === 'Polygon' || result.geometry.type === 'MultiPolygon' || result.geometry.type === 'GeometryCollection') &&
        !properties.area
      ) {
        result.properties['area'] = calculateArea(result.geometry, this.host.projection.view, this.host.projection.calculate);
      }

      if (
        (result.geometry.type === 'Polygon' ||
          result.geometry.type === 'MultiPolygon' ||
          result.geometry.type === 'GeometryCollection' ||
          result.geometry.type === 'LineString' ||
          result.geometry.type === 'MultiLineString') &&
        !properties.perimeter
      ) {
        result.properties['perimeter'] = calculatePerimeter(result.geometry, this.host.projection.view, this.host.projection.calculate);
      }

      return result;
    }

    return { type: 'RenderFeature', id, properties };
  }

  private getLayerId(features: (Feature | RenderFeature)[]) {
    if (!features || !features.length) {
      return null;
    }

    const layer = this.instance.getLayer(features[0]);
    return layer.getProperties().id;
  }

  private updateStyles = () => {
    this.host.instance
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorTileLayer || layer instanceof VectorLayer)
      .forEach((layer) => layer.changed());
  };

  private setContextualMenuEventHandlers() {
    this.host.instance.getViewport().addEventListener('contextmenu', (event) => {
      event.preventDefault();

      if (!this.active) {
        return;
      }

      const stack = [];

      const offset = (this.host.instance.getTarget() as HTMLDivElement).getBoundingClientRect();
      const x = event.x - offset.left;
      const y = event.y - offset.top;

      this.host.instance.forEachFeatureAtPixel([x, y], (feature, layer) => stack.push({ layer, feature }));

      const clickedOn: { layer: VectorLayer | VectorTileLayer; feature: Feature | RenderFeature } =
        stack.length && stack[0].layer && stack[0].feature ? stack[0] : null;

      if (clickedOn) {
        if (
          !this.selection ||
          clickedOn.layer.getProperties().id !== this.selection.layerId ||
          !this.selection.features.some((f) => f.id === clickedOn.feature.getId())
        ) {
          this.instance.select([clickedOn.feature], clickedOn.layer);
        }
      }

      this.contextMenu.emit({ opened: true, mapX: x, mapY: y, clientX: event.clientX, clientY: event.clientY, selection: this.selection });
    });

    this.host.instance.on('click', () => this.contextMenu.emit({ opened: false }));
  }
}
