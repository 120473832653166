import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { IntercomService } from '@core/providers';

@Component({
  selector: 'la-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  private t;

  constructor(private intercom: IntercomService) {}

  public ngOnInit() {
    this.t = setTimeout(() => {
      this.intercom.show();
    }, 10000);
  }

  public ngOnDestroy() {
    clearTimeout(this.t);
  }
}
