export function isDate(value: string) {
  const ISORegexp = /^\d{4}(-|\/)([0]\d|1[0-2])(-|\/)([0-2]\d|3[01])/;

  if (!ISORegexp.test(value)) {
    return false;
  }

  const date = new Date(value);
  return !isNaN(date.getTime());
}

export function dateToShortFormat(date: Date) {
  const getPaddedComp = (comp: number) => {
    return comp < 10 ? `0${comp}` : `${comp}`;
  };

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${getPaddedComp(day)} ${monthName} ${year}`;
}
