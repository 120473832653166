import Tile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { WMSLayerDefinition } from '../models/layer.model';

type WMSLayerConfig = WMSLayerDefinition & { visible?: boolean; opacity?: number };

export const wmsSource = (config: WMSLayerConfig) => {
  const { visible, opacity, url, attributions, layerName, layerFormat } = config;

  const options = {
    source: new TileWMS({
      url,
      params: { LAYERS: layerName, FORMAT: layerFormat },
      attributions,
      serverType: 'mapserver',
    }),
    visible,
    opacity,
  };

  return new Tile(options);
};
