import { Injectable } from '@angular/core';
import Feature, { FeatureLike } from 'ol/Feature';
import { Modify, Select } from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import RenderFeature from 'ol/render/Feature';
import Style, { StyleFunction } from 'ol/style/Style';
import { MapComponent } from '../components/map/map.component';
import { AnnotateMapperFunc } from '../models/properties.model';
import { StyleDefinition, StyleDefinitionFilters } from '../models/style.model';
import { buildArrowForLineStyle } from '../styles/arrow-line.styles';
import { buildAttributeStyles } from '../styles/attributes.styles';
import { buildCentroidMarkerStyle } from '../styles/centroid.styles';
import { buildCustomStyle, buildStyleFunction } from '../styles/custom.styles';
import { buildPhotoStyle } from '../styles/icon.styles';
import { modifyStyle, pickedStyle, selectStyle } from '../styles/interactive.styles';
import { buildMeasurementsStyles } from '../styles/measurements.styles';
import { boringStyle, hiddenStyle, unfilteredStyle } from '../styles/static.styles';
import { buildTMarkStyles } from '../styles/t-mark.styles';
import { buildTextPointStyle } from '../styles/text-point.styles';

@Injectable()
export class StyleService {
  public layer: BaseLayer;

  private lookup: string | null = null;
  private defaultStyle: Style;
  private conditionStyles: { [key: string]: (f: FeatureLike, r: number, scale: number) => Style } = {};
  private properties: { [key: string]: any } = {};
  private filters: StyleDefinitionFilters;

  private host: MapComponent;

  buildRenderer(
    host: MapComponent,
    styleDefinition: StyleDefinition,
    properties?: { [key: string]: any },
    annotateMapper?: AnnotateMapperFunc
  ): StyleFunction {
    this.host = host;
    this.properties = properties;
    this.conditionStyles = {};
    this.defaultStyle = styleDefinition && styleDefinition.default ? buildCustomStyle(styleDefinition.default) : boringStyle;

    if (styleDefinition && styleDefinition.lookup && styleDefinition.stops && styleDefinition.stops.length) {
      this.lookup = styleDefinition.lookup;
      styleDefinition.stops.forEach(([condition, value]) => (this.conditionStyles[condition.toString()] = buildStyleFunction(value)));
    }

    if (styleDefinition && styleDefinition.filters) {
      this.filters = styleDefinition.filters;
    }

    return (feature, resolution) => {
      const { laHidden } = feature['properties_'] || feature['values_'] || {};

      if (laHidden) {
        return hiddenStyle;
      }

      if (feature instanceof Feature) {
        return this.drawFeature(feature, resolution, annotateMapper);
      }

      return this.drawRenderFeature(feature, resolution);
    };
  }

  setFilters(filters: StyleDefinitionFilters) {
    this.filters = filters;
  }

  private drawFeature(feature: Feature, resolution: number, annotateMapper?: AnnotateMapperFunc) {
    const properties = feature['properties_'] || feature['values_'] || {};
    const { laStyleType, laStyle, laFeatureType } = properties;

    let mainStyle = this.defaultStyle;
    const additionalStyles = [];

    if (laFeatureType && laFeatureType === 'photo') {
      mainStyle = this.host.photoVisibility ? buildPhotoStyle(this.host.photoScale) : hiddenStyle;
    } else if (laFeatureType && laFeatureType === 'text_point') {
      mainStyle = buildTextPointStyle(feature, resolution, this.host.scale);
    } else if (laStyleType === 'custom' && laStyle) {
      mainStyle = buildCustomStyle(laStyle);
    } else if (this.lookup) {
      if (properties[this.lookup] && this.conditionStyles[properties[this.lookup]]) {
        mainStyle = this.conditionStyles[properties[this.lookup]](feature, resolution, this.host.scale);
      } else {
        const type = this.unifyGeometryType(feature.getGeometry().getType());
        mainStyle = this.conditionStyles[`unassigned-${type}`]
          ? this.conditionStyles[`unassigned-${type}`](feature, resolution, this.host.scale)
          : this.conditionStyles[type]
            ? this.conditionStyles[type](feature, resolution, this.host.scale)
            : this.defaultStyle;
      }
    }

    if (properties.laFeatureType && properties.laFeatureType === 'arrow_line') {
      const arrowLineStyle = buildArrowForLineStyle(feature, resolution);

      if (arrowLineStyle) {
        additionalStyles.push(arrowLineStyle);
      }
    }

    if (laFeatureType && laFeatureType === 't_mark') {
      additionalStyles.push(...buildTMarkStyles(feature, [mainStyle]));
    }

    if (properties.laCentroid || properties.laCenterPointVisible) {
      const centroidStyle = buildCentroidMarkerStyle(feature);

      if (centroidStyle) {
        additionalStyles.push(centroidStyle);
      }
    }

    if (properties.laNotes && properties.laNotes.length) {
      additionalStyles.push(
        ...buildAttributeStyles(feature, this.host?.projection?.view, this.host?.projection?.calculate, this.host.scale, annotateMapper)
      );
    }

    if (this.isFeatureSelected(feature)) {
      const featureStyle = mainStyle.clone();

      selectStyle.setZIndex(1);
      featureStyle.setZIndex(2);
      additionalStyles.forEach((style) => style.setZIndex(3));

      if (!this.properties.readonly && this.hasActiveModifyInteraction()) {
        const vertexStyle = modifyStyle.clone();
        const measureStyles = buildMeasurementsStyles(feature, resolution, this.host.projection.view, this.host.projection.calculate);

        vertexStyle.setZIndex(4);
        measureStyles.forEach((style) => style.setZIndex(5));

        if (properties.laFeatureType === 't_mark') {
          return [selectStyle, featureStyle, ...additionalStyles, vertexStyle, ...buildTMarkStyles(feature, [selectStyle])];
        }

        return [selectStyle, featureStyle, ...additionalStyles, vertexStyle, ...measureStyles];
      }

      if (properties.laFeatureType === 't_mark') {
        return [selectStyle, featureStyle, ...additionalStyles, ...buildTMarkStyles(feature, [selectStyle])];
      }

      return [selectStyle, featureStyle, ...additionalStyles];
    }

    if (laFeatureType && laFeatureType === 'photo' && this.host.photoVisibility === false) {
      return [mainStyle];
    }

    return [mainStyle, ...additionalStyles];
  }

  private drawRenderFeature(feature: RenderFeature, resolution: number) {
    const properties = feature['properties_'] || feature['values_'] || {};

    let mainStyle = this.defaultStyle;

    if (this.lookup && properties[this.lookup] && this.conditionStyles[properties[this.lookup]]) {
      mainStyle = this.conditionStyles[properties[this.lookup]](feature, resolution, this.host.scale);
    }

    if (this.filters?.length && !this.isFeatureMatchingFilters(properties)) {
      if (this.isFeatureSelected(feature)) {
        const featureStyle = unfilteredStyle.clone();

        selectStyle.setZIndex(1);
        featureStyle.setZIndex(2);

        return [selectStyle, featureStyle];
      }
      return [unfilteredStyle];
    }

    if (this.isFeaturePicked(feature)) {
      selectStyle.setZIndex(1);
      pickedStyle.setZIndex(2);

      return [selectStyle, pickedStyle];
    }

    if (this.isFeatureSelected(feature)) {
      const featureStyle = mainStyle.clone();

      selectStyle.setZIndex(1);
      featureStyle.setZIndex(2);

      return [selectStyle, featureStyle];
    }

    return [mainStyle];
  }

  private isFeatureMatchingFilters(properties: GeoJSON.GeoJsonProperties) {
    let result = true;
    this.filters.forEach((filter) => {
      if (properties[filter.lookup]) {
        result = result && filter.stops.some((stop) => properties[filter.lookup] === stop);
      }
    });
    return result;
  }

  private unifyGeometryType(geometryType: string) {
    switch (geometryType) {
      case 'Point':
      case 'MultiPoint':
        return 'point';
      case 'LineString':
      case 'MultiLineString':
        return 'line';
      case 'Polygon':
      case 'MultiPolygon':
        return 'polygon';
      default:
        return '';
    }
  }

  private getSelectInteractions(): Select[] {
    return this.host.instance
      .getInteractions()
      .getArray()
      .filter((interaction) => interaction instanceof Select) as Select[];
  }

  private hasActiveModifyInteraction(): boolean {
    return this.host.instance
      .getInteractions()
      .getArray()
      .some((interaction) => interaction instanceof Modify && interaction.getActive());
  }

  private isFeatureSelected(feature: Feature | RenderFeature) {
    return this.getSelectInteractions().some((select) => {
      return select
        .getFeatures()
        .getArray()
        .some((f) => {
          const data = select.getLayer(f).getProperties();
          return data.id === this.properties.id && f.getId() === feature.getId();
        });
    });
  }

  private isFeaturePicked(feature: RenderFeature) {
    return this.layer?.getProperties()?.picked?.includes(feature.getId());
  }
}
