import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-add-first-plan',
  templateUrl: './add-first-plan.component.html',
  styleUrls: ['./add-first-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFirstPlanComponent {
  @Input() title?: string;
  @Input() description?: string;
}
