import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ChargebeeApiService } from '@core/api';
import { APP_CONFIG, AppConfig } from '@core/config';

@Injectable({ providedIn: 'root' })
export class ChargebeeService {
  private cb: any;

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private chargebeeApiService: ChargebeeApiService
  ) {
    const delay = this.config.chargebee.delay;

    this.install(delay).subscribe(() => {
      this.cb = (window as any).Chargebee.init({ site: this.config.chargebee.site });
    });
  }

  public showSubscriptionPortal() {
    return this.chargebeeApiService.getMainSession().pipe(switchMap((hostedPage) => this.openPortalIframe(hostedPage)));
  }

  public updatePaymentMethod() {
    return this.chargebeeApiService.getPaymentSession().pipe(switchMap((hostedPage) => this.openPaymentsIframe(hostedPage)));
  }

  private install(delay: number) {
    const installer$: Subject<void> = new Subject();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://js.chargebee.com/v2/chargebee.js';
    script.onload = () => {
      setTimeout(() => {
        installer$.next();
        installer$.complete();
      }, delay);
    };

    const other = document.getElementsByTagName('script')[0];
    other.parentNode?.insertBefore(script, other);

    return installer$;
  }

  private openPortalIframe(data: any) {
    return new Observable((subscriber) => {
      this.cb.setPortalSession(() => Promise.resolve(data));
      const portal = this.cb.createChargebeePortal();

      portal.open({
        close: () => {
          subscriber.next();
          subscriber.complete();
        },
        error: (error: Error) => subscriber.error(error),
      });
    });
  }

  private openPaymentsIframe(data: any) {
    return new Observable((subscriber) => {
      this.cb.openCheckout({
        hostedPage: () => Promise.resolve(data),
        close: () => {
          subscriber.next();
          subscriber.complete();
        },
        error: (error: Error) => subscriber.error(error),
      });
    });
  }
}
