import ImageTile from 'ol/ImageTile';
import Tile from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid';

import { XYZLayerDefinition } from '../models/layer.model';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

type XYZLayerConfig = XYZLayerDefinition & {
  http: HttpClient;
  visible?: boolean;
  opacity?: number;
  minResolution?: number;
  maxResolution?: number;
  tileLoadPaymentError: () => void;
  tileLoadSuccess: () => void;
};

const EMPTY_TILE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89u1TPQAJJwNfEN7NAAAAAABJRU5ErkJggg==';

const tileGrid = (projection: string) => {
  // Unfortunately you have to specify magic numbers in order for base maps to work properly
  // https://github.com/tmnnrs/os-maps-examples/blob/master/openlayers-zxy-epsg27700.html
  if (projection === 'EPSG:27700') {
    return {
      tileGrid: new TileGrid({
        resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375],
        origin: [-238375.0, 1376256.0],
      }),
    };
  }

  return undefined;
};

const tileLoadFunction = (tileLoadPaymentError, tileLoadSuccess, http?: HttpClient) => {
  if (!http) {
    return undefined;
  }

  return {
    tileLoadFunction: (tile: ImageTile, url: string) => {
      http
        .get(url, { responseType: 'arraybuffer' })
        .pipe(tap(() => tileLoadSuccess()))
        .subscribe(
          (blobPart: BlobPart) => {
            const blob = new Blob([blobPart], { type: 'image/png' });
            const urlCreator = window.URL || window['webkitURL'];
            const imageUrl = urlCreator.createObjectURL(blob);
            const tileImage = tile.getImage();

            if (tileImage instanceof HTMLImageElement) {
              tileImage.src = imageUrl;
            }
          },
          (error) => {
            if (error.status === 402) {
              const tileImage = tile.getImage();

              if (tileImage instanceof HTMLImageElement) {
                tileImage.src = EMPTY_TILE;
              }
              tileLoadPaymentError();
            }
          }
        );
    },
  };
};

export const xyzSource = (config: XYZLayerConfig) => {
  const {
    url,
    projection,
    minZoom = 0,
    maxZoom = 15,
    minResolution,
    maxResolution,
    attributions = '',
    http,
    visible,
    opacity,
    tileLoadSuccess,
    tileLoadPaymentError,
  } = config;

  const options = {
    source: new XYZ({
      crossOrigin: 'anonymous',
      minZoom,
      maxZoom,
      projection,
      attributions,
      url,
      ...tileGrid(projection),
      ...tileLoadFunction(tileLoadPaymentError, tileLoadSuccess, http),
    }),
    minResolution,
    maxResolution,
    visible,
    opacity,
  };

  return new Tile(options);
};
