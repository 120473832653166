export const TOP_UP_OPTIONS = [
  {
    label: 'One-off £4.50',
    credits: 30000,
    description:
      'This will give you, on average, 50 screens of OS basemap, 1,000 screens of OS Leisure basemap, or 5 screens of OS MasterMap layers.',
  },
  {
    label: 'Light Use £7.50',
    credits: 60000,
    description:
      'This will give you, on average, 100 screens of OS basemap, 2,000 screens of OS Leisure basemap, or 10 screens of OS MasterMap layers.',
    savedAmount: '17%',
  },
  {
    label: 'Regular Use £15',
    credits: 150000,
    description:
      'This will give you, on average, 250 screens of OS basemap, 5,000 screens of OS Leisure basemap, or 25 screens of OS MasterMap layers.',
    savedAmount: '33%',
  },
  {
    label: 'Heavy Use £50',
    credits: 600000,
    description:
      'This will give you, on average, 1,000 screens of OS basemap, 20,000 screens of OS Leisure basemap, or 100 screens of OS MasterMap layers.',
    savedAmount: '44%',
  },
];
