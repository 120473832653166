import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { textOnPoint } from './text.styles';

export function buildCentroidMarkerStyle(feature: Feature<Geometry>) {
  const geometry = feature.getGeometry();

  if (geometry instanceof Polygon || geometry instanceof MultiPolygon) {
    const centerCoordinates =
      geometry instanceof Polygon ? geometry.getInteriorPoint().getCoordinates() : geometry.getInteriorPoints().getCoordinates()[0];
    const centerPoint = new Point(centerCoordinates);

    return textOnPoint('X', centerPoint);
  }

  return null;
}
