export type SelectedGeoJSONFeature = GeoJSON.Feature;

export interface SelectedRenderFeature {
  id: string | number;
  type: 'RenderFeature';
  properties: { [key: string]: any };
  geometry?: { type: string };
}

export interface SelectOutcome {
  layerId: string;
  features: (SelectedGeoJSONFeature | SelectedRenderFeature)[];
}

export interface ContextMenuOutcome {
  opened: boolean;
  selection?: SelectOutcome;
  mapX?: number;
  mapY?: number;
  clientX?: number;
  clientY?: number;
}
