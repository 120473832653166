import { isEmpty, isNil } from 'lodash';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { ProjectionLike } from 'ol/proj';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { area, AREA_UNITS, convertAreaTo } from '~/libs/geometry/area';
import { convertPerimeterTo, perimeter, PERIMETER_UNITS } from '~/libs/geometry/perimeter';
import { generatePoint } from '~/libs/geometry/resize';
import { FeatureProperties } from '../models/properties.model';
import { toGeoJSONGeometry } from '../utils/feature.utils';
import { textOnLine, textOnPoint } from './text.styles';

/**
 * Minimum ratio for perimeter visibility
 * ratio = perimeter in meters / resolution
 */
const VISIBLE_PERIMETER_RATIO = 50;

/**
 * Minimum ratio for area visibility
 * ratio = area in square meters / resolution
 */
const VISIBLE_AREA_RATIO = 40;

function generateDash(coordinates: GeoJSON.Position[], resolution: number, projection: ProjectionLike) {
  const [B, A] = coordinates;

  const CX = B[1] - A[1] + B[0];
  const CY = A[0] - B[0] + B[1];

  const C: GeoJSON.Position = [CX, CY];

  return [B, generatePoint(B, C, resolution * 7, projection)];
}

function nearestPoints(coordinates: GeoJSON.Position[], fp: ProjectionLike, cp: ProjectionLike, resolution: number, accumulator = 0) {
  let dashes = [];

  if (coordinates.length < 2) {
    return null;
  }

  const distance = perimeter({ type: 'LineString', coordinates: [coordinates[0], coordinates[1]] }, fp, cp);
  let next;

  if (accumulator + distance > 1000) {
    const point = generatePoint(coordinates[0], coordinates[1], 1000 - accumulator, cp);
    const dash = generateDash([point, coordinates[1]], resolution, cp);
    dashes.push(dash);

    next = nearestPoints([point, ...coordinates.slice(1)], fp, cp, resolution, 0);
  } else {
    next = nearestPoints(coordinates.slice(1), fp, cp, resolution, accumulator + distance);
  }

  if (next) {
    dashes = [...dashes, ...next];
  }

  return dashes;
}

function measureLineSegments(
  coordinates: number[][],
  resolution: number,
  sp: ProjectionLike,
  cp: ProjectionLike,
  unit: PERIMETER_UNITS = 'm'
) {
  const styles: Style[] = [];

  for (let i = 0; i < coordinates.length - 1; i++) {
    const segmentCoordinates = [coordinates[i], coordinates[i + 1]];
    const segmentPerimeter = perimeter({ type: 'LineString', coordinates: segmentCoordinates }, sp, cp);
    const segment = new LineString(segmentCoordinates);
    const ratio = Math.floor(segmentPerimeter / resolution);

    if (segmentPerimeter > 0 && ratio >= VISIBLE_PERIMETER_RATIO) {
      const text = convertPerimeterTo(segmentPerimeter, unit);

      styles.push(textOnLine(text, segment, { offsetY: -10, font: '12px Roboto' }));
    }
  }

  return styles;
}

function measureTotalPerimeter(
  coordinates: number[][],
  resolution: number,
  fp: ProjectionLike,
  cp: ProjectionLike,
  unit: PERIMETER_UNITS = 'm'
) {
  const styles: Style[] = [];

  const totalPerimeter = perimeter({ type: 'LineString', coordinates }, fp, cp);
  const ratio = Math.floor(totalPerimeter / resolution);

  if (totalPerimeter > 0 && ratio >= VISIBLE_PERIMETER_RATIO) {
    const text = `Total: ${convertPerimeterTo(totalPerimeter, unit)}`;
    const lastPoint = new Point(coordinates[coordinates.length - 1]);

    styles.push(textOnPoint(text, lastPoint, { offsetX: 30, offsetY: 30, font: '12px Roboto' }));
  }

  return styles;
}

function measureTotalArea(
  geometry: GeoJSON.Geometry,
  center: number[],
  resolution: number,
  fp: ProjectionLike,
  cp: ProjectionLike,
  unit: AREA_UNITS = 'ha',
  offsetY: number = 0
) {
  const styles: Style[] = [];

  const totalArea = area(geometry, fp, cp);
  const ratio = Math.floor(Math.sqrt(totalArea) / resolution);

  if (totalArea > 0 && ratio > VISIBLE_AREA_RATIO) {
    const text = convertAreaTo(totalArea, unit);

    styles.push(textOnPoint(text, new Point(center), { offsetY, font: '12px Roboto' }));
  }

  return styles;
}

function measureRadius(
  coordinates: number[][],
  center: number[],
  resolution: number,
  fp: ProjectionLike,
  cp: ProjectionLike,
  unit: PERIMETER_UNITS = 'm'
) {
  const styles: Style[] = [];

  const radiusCoordinates = [center, coordinates[0]];
  const radiusPerimeter = perimeter({ type: 'LineString', coordinates: radiusCoordinates }, fp, cp);
  const radius = new LineString(radiusCoordinates);
  const ratio = Math.floor(radiusPerimeter / resolution);

  if (radiusPerimeter > 0 && ratio >= VISIBLE_PERIMETER_RATIO) {
    styles.push(
      new Style({
        geometry: () => radius,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
      })
    );

    const text = convertPerimeterTo(radiusPerimeter, unit);

    styles.push(textOnLine(text, radius, { offsetY: -10, font: '12px Roboto' }));
  }

  return styles;
}

export function buildMeasurementsStyles(feature: Feature, resolution: number, fp: ProjectionLike, cp: ProjectionLike) {
  const properties = feature['values_'] as FeatureProperties;
  const geometry = feature.getGeometry();

  const jsonGeometry = toGeoJSONGeometry(geometry);
  const laUnits = isNil(properties?.laUnits) || isEmpty(properties?.laUnits) ? {} : properties.laUnits;
  const laCentroid = properties.laCentroid;
  const laNotes = properties.laNotes || [];

  const styles: Style[] = [];

  switch (jsonGeometry.type) {
    case 'LineString':
      styles.push(
        ...measureLineSegments(jsonGeometry.coordinates, resolution, fp, cp, laUnits.perimeter),
        ...measureTotalPerimeter(jsonGeometry.coordinates, resolution, fp, cp, laUnits.perimeter)
      );
      break;
    case 'MultiLineString':
      jsonGeometry.coordinates.forEach((innerLine) =>
        styles.push(
          ...measureLineSegments(innerLine, resolution, fp, cp, laUnits.perimeter),
          ...measureTotalPerimeter(innerLine, resolution, fp, cp, laUnits.perimeter)
        )
      );
      break;
    case 'Polygon':
      const polygonCenter = (geometry as Polygon).getInteriorPoint().getCoordinates();

      switch (properties.laFeatureType) {
        case 'rectangle':
          styles.push(...measureLineSegments(jsonGeometry.coordinates[0].slice(0, 3), resolution, fp, cp, laUnits.perimeter));

          if (!laNotes.includes('area')) {
            styles.push(
              ...measureTotalArea(jsonGeometry, polygonCenter, resolution, fp, cp, laUnits.area, laCentroid || laNotes.length ? 20 : 0)
            );
          }
          break;
        case 'circle':
          styles.push(...measureRadius(jsonGeometry.coordinates[0], polygonCenter, resolution, fp, cp, laUnits.perimeter));
          break;
        default:
          jsonGeometry.coordinates.forEach((innerLine) =>
            styles.push(...measureLineSegments(innerLine, resolution, fp, cp, laUnits.perimeter))
          );

          if (!laNotes.includes('area')) {
            styles.push(
              ...measureTotalArea(jsonGeometry, polygonCenter, resolution, fp, cp, laUnits.area, laCentroid || laNotes.length ? 20 : 0)
            );
          }
          break;
      }
      break;
    case 'MultiPolygon':
      const multiPolygonCenter = (geometry as MultiPolygon).getInteriorPoints().getCoordinates()[0];

      jsonGeometry.coordinates.forEach((innerPolygons) =>
        innerPolygons.forEach((innerLine) => styles.push(...measureLineSegments(innerLine, resolution, fp, cp, laUnits.perimeter)))
      );

      if (!laNotes.includes('area')) {
        styles.push(
          ...measureTotalArea(jsonGeometry, multiPolygonCenter, resolution, fp, cp, laUnits.area, laCentroid || laNotes.length ? 20 : 0)
        );
      }
      break;
  }
  return styles;
}

export function buildRulerStyles(feature: Feature, resolution: number, fp: ProjectionLike, cp: ProjectionLike) {
  const geometry = feature.getGeometry();
  const jsonGeometry = toGeoJSONGeometry(geometry);
  const styles: Style[] = [];

  styles.push(
    new Style({
      fill: new Fill({ color: 'rgba(255, 255, 255, 0.5)' }),
      stroke: new Stroke({ color: 'rgb(0, 0, 0)', width: 3 }),
    })
  );

  if (jsonGeometry.type === 'LineString') {
    const coordinates = jsonGeometry.coordinates;
    const dashes = nearestPoints(coordinates, fp, cp, resolution);

    styles.push(
      new Style({
        geometry: () => new Point(coordinates[0]),
        image: new Circle({
          radius: 5,
          fill: new Fill({ color: '#FFFFFF' }),
          stroke: new Stroke({ color: '#000000', width: 3 }),
        }),
      })
    );

    if (resolution < 80) {
      dashes?.map((dash) => {
        styles.push(
          new Style({
            geometry: () => new LineString(dash),
            stroke: new Stroke({
              color: 'rgb(0, 0, 0)',
              width: 2,
              lineCap: 'square',
            }),
          })
        );
      });
    }
  } else if (jsonGeometry.type === 'Polygon') {
    const coordinates = jsonGeometry.coordinates[0];
    const dashes = nearestPoints(coordinates, fp, cp, resolution);

    styles.push(
      new Style({
        geometry: () => new Point(coordinates[0]),
        image: new Circle({
          radius: 5,
          fill: new Fill({ color: '#FFFFFF' }),
          stroke: new Stroke({ color: '#000000', width: 3 }),
        }),
      })
    );

    if (resolution < 80) {
      dashes?.map((dash) => {
        styles.push(
          new Style({
            geometry: () => new LineString(dash),
            stroke: new Stroke({
              color: 'rgb(0, 0, 0)',
              width: 2,
              lineCap: 'square',
            }),
          })
        );
      });
    }
  } else if (jsonGeometry.type === 'Point') {
    styles.push(
      new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({ color: '#FFFFFF' }),
          stroke: new Stroke({ color: '#000000', width: 3 }),
        }),
      })
    );
  }

  return styles;
}
