import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'la-delay-button',
  templateUrl: './delay-button.component.html',
  styleUrls: ['./delay-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayButtonComponent {
  @Input() delay = 500;
  @Input() disabled = false;
  @Output() action = new EventEmitter<void>();

  private cursor: any;
  private _pending: boolean;

  get pending() {
    return this._pending;
  }

  @Input() set pending(value: boolean) {
    if (value) {
      this._pending = value;
    }

    if (this.cursor) {
      clearTimeout(this.cursor);
    }

    this.cursor = setTimeout(() => {
      this._pending = false;
      clearTimeout(this.cursor);
      this.cursor = undefined;
      this.cd.markForCheck();
    }, this.delay);
  }

  constructor(private cd: ChangeDetectorRef) {}
}
