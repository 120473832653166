import { createAction, props } from '@ngrx/store';

import { UserRegister } from '@core/api';
import { apiActionFactory } from '@core/store';

export const registrationPageOpened = createAction('[Register/Page] Registration Page Opened');
export const activatePageOpened = createAction('[Activate/Page] Activate Page Opened');

export const register = createAction('[Register/Page] Register', props<UserRegister>());
export const [registerSuccess, registerFail] = apiActionFactory<UserRegister>('[Register/Page] Register');
