import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'la-filter-autocomplete',
  templateUrl: './filter-autocomplete.component.html',
  styleUrl: './filter-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterAutocompleteComponent),
      multi: true,
    },
  ],
})
export class FilterAutocompleteComponent implements ControlValueAccessor {
  @Input() options: { label: string; value: string }[] = [];

  @Output() search = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  inputControl = new FormControl('');

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: string): void {
    this.inputControl.setValue(obj);
    this.search.emit(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  filter() {
    if (!this.input.nativeElement.value) {
      this.onChange('');
      this.inputControl.setValue('');
      this.search.emit('');
    }

    if (this.input.nativeElement.value !== this.inputControl.value) {
      this.search.emit(this.input.nativeElement.value);
    }
  }

  optionSelected($event: MatAutocompleteSelectedEvent) {
    this.onChange($event.option.value);
  }

  displayWith(event: string) {
    return this.options?.find((option) => option.value === event)?.label;
  }
}
