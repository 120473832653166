export interface OSMLayerDefinition {
  id: string;
  type: 'OSM';
  name?: string;
  attributions?: string;
}

export interface BingLayerDefinition {
  id: string;
  type: 'BING';
  imagerySet: string;
  maxZoom: number;
  name?: string;
}

export interface XYZLayerDefinition {
  id: string;
  name?: string;
  type: 'XYZ';
  url: string;
  projection: string;
  minZoom?: number;
  maxZoom?: number;
  attributions?: string | string[];
}

export interface ArcGISLayerDefinition {
  id: string;
  name?: string;
  type: 'ARCGIS';
  projection: string;
  url?: string | undefined;
  attributions?: string | string[] | undefined;
  urls?: string[] | undefined;
}

export interface WMSLayerDefinition {
  id: string;
  name?: string;
  type: 'WMS';
  projection: string;
  url?: string | undefined;
  attributions?: string | string[] | undefined;
  layerName: string;
  layerFormat: string;
}

export interface MVTLayerDefinition {
  id: string;
  name?: string;
  type: 'MVT';
  url: string;
  projection: string;
  renderMode?: string;
  minZoom?: number;
  maxZoom?: number;
  minResolution?: number;
  maxResolution?: number;
}

export interface VSLayerDefinition {
  id: string;
  name?: string;
  type: 'VECTORSPACE' | 'OSMM';
  url: string;
  projection: string;
  minZoom?: number;
  maxZoom?: number;
  minResolution?: number;
  maxResolution?: number;
}

export interface GeoJSONLayerDefinition {
  id: string;
  name?: string;
  type: 'GEOJSON';
  geojson: GeoJSON.FeatureCollection;
  projection: string;
}

export interface BlankLayerDefinition {
  id: string;
  name?: string;
  type: 'BLANK';
}

export type LayerDefinition =
  | OSMLayerDefinition
  | BingLayerDefinition
  | XYZLayerDefinition
  | ArcGISLayerDefinition
  | WMSLayerDefinition
  | MVTLayerDefinition
  | VSLayerDefinition
  | GeoJSONLayerDefinition
  | BlankLayerDefinition;
