import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import Rotate from 'ol-rotate-feature';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';

import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { rotateAnchorStyle, rotateArrowStyle } from '../../styles/interactive.styles';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapSelectComponent } from '../map-select/map-select.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-rotate',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapRotateComponent extends MapInteractionAbstract implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;

  @Output() rotateStart = new EventEmitter<any>();
  @Output() rotateEnd = new EventEmitter<any>();

  private instance: Rotate;

  constructor(protected host: MapComponent, private select: MapSelectComponent) {
    super(host);
  }

  ngOnInit(): void {
    this.instance = new Rotate({
      features: this.select.instance.selectedInteractiveFeatures,
      style: (feature: Feature, resolution: number) => this.buildRotateStyle(feature, resolution),
    });

    addInteraction(this.host.instance, this.instance);

    this.instance.setActive(this.active);
    this.instance.on('rotatestart', (event) => this.onRotateStart(event));
    this.instance.on('rotateend', (event) => this.onRotateEnd(event));
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);

      if (this.select.instance.selectedInteractiveFeatures.getLength() > 0) {
        const layer = this.select.instance.getLayer(this.select.instance.selectedInteractiveFeatures.getArray()[0]);
        layer.changed();
      }
    }
  }

  private onRotateStart(event) {
    const features = event.features.getArray() as Feature[];
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      this.rotateStart.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private onRotateEnd(event) {
    const features = event.features.getArray() as Feature[];
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      features.forEach((feature) => this.enrichFeature(feature));

      this.rotateEnd.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private buildRotateStyle(feature: Feature, resolution: number) {
    const style: Style[] = [];
    const angle = feature.get('angle') || 0;

    switch (true) {
      case feature.get('rotate-anchor'):
        style.push(rotateAnchorStyle);
        style[0].getImage().setRotation(-angle);

        return style;
      case feature.get('rotate-arrow'):
        style.push(rotateArrowStyle);

        const coordinates = (feature.getGeometry() as any).getCoordinates();
        const geom = new LineString([coordinates, [coordinates[0], coordinates[1] + 100 * resolution]]);

        geom.rotate(angle, coordinates);
        style[0].setGeometry(geom);
        style[0].getText().setText(Math.round((-angle * 180) / Math.PI) + '°');

        return style;
    }

    return null;
  }
}
