import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { get } from 'lodash';
import Collection from 'ol/Collection';
import Point from 'ol/geom/Point';
import { Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { modificationPointStyle } from '../../styles/interactive.styles';
import { hiddenStyle } from '../../styles/static.styles';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapSelectComponent } from '../map-select/map-select.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-modify-t-mark',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapModifyTMarkComponent extends MapInteractionAbstract implements OnInit, OnDestroy, OnChanges {
  @Input() active = true;

  @Output() modifyStart = new EventEmitter<{ layerId: string; features: GeoJSON.Feature[] }>();
  @Output() modifyEnd = new EventEmitter<{ layerId: string; features: GeoJSON.Feature[] }>();

  private instance: Modify;
  private keycodes: { [key: string]: boolean } = {};
  private listener: () => {};
  private collectionListener: () => void;

  constructor(protected host: MapComponent, private select: MapSelectComponent) {
    super(host);
  }

  ngOnInit(): void {
    const editableFeatures = this.filterCollection(
      this.select.instance.selectedInteractiveFeatures,
      (feature) => feature.getProperties().laFeatureType === 't_mark'
    );
    this.instance = new Modify({
      features: editableFeatures,
      style: (feature) => {
        const geometry = feature.getGeometry();
        if (geometry instanceof Point) {
          const parentFeature = get(feature.getProperties(), ['features', 0], null);

          if (parentFeature && this.compareCoordinates(geometry.getCoordinates(), parentFeature.getGeometry().getCoordinates())) {
            return modificationPointStyle;
          }
        }
        return hiddenStyle;
      },
      insertVertexCondition: () => false,
      deleteCondition: (event) => this.isKeyPressed('KeyA') && event.type === 'pointerup',
    });

    addInteraction(this.host.instance, this.instance);

    this.listener = this.keyEvent.bind(this);

    document.addEventListener('keyup', this.listener);
    document.addEventListener('keydown', this.listener);

    this.instance.setActive(this.active);
    this.instance.on('modifystart', (event) => this.onModifyStart(event));
    this.instance.on('modifyend', (event) => this.onModifyEnd(event));
  }

  ngOnDestroy(): void {
    this.host.instance.removeInteraction(this.instance);

    document.removeEventListener('keyup', this.listener);
    document.removeEventListener('keydown', this.listener);
    this.select.instance.selectedInteractiveFeatures.un('change', this.collectionListener);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && !changes.active.firstChange) {
      this.instance.setActive(changes.active.currentValue);

      if (this.select.instance.selectedInteractiveFeatures.getLength() > 0) {
        const layer = this.select.instance.getLayer(this.select.instance.selectedInteractiveFeatures.getArray()[0]);
        layer.changed();
      }
    }
  }

  private onModifyStart(event: ModifyEvent) {
    const features = event.features.getArray();
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      this.modifyStart.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private onModifyEnd(event: ModifyEvent) {
    const features = event.features.getArray();
    const layer = features && features.length ? this.select.instance.getLayer(features[0]) : null;

    if (layer && layer instanceof VectorLayer) {
      features.forEach((feature) => {
        this.enrichFeature(feature);
      });

      this.modifyEnd.emit({
        layerId: layer.getProperties().id,
        features: features.map((feature) => toGeoJSON(feature)),
      });
    }
  }

  private isKeyPressed(keyCode: string) {
    return !!this.keycodes[keyCode];
  }

  private keyEvent(event: KeyboardEvent) {
    this.keycodes[event.code] = event.type === 'keydown';
  }

  private filterCollection<T>(src: Collection<T>, cnd: (T) => boolean): Collection<T> {
    const collection = new Collection(src.getArray().filter(cnd));
    this.collectionListener = () => {
      collection.clear();
      collection.extend(src.getArray().filter(cnd));
    };
    src.on('change', this.collectionListener);
    return collection;
  }

  private compareCoordinates(needle: number[], haystack: number[][]): boolean {
    for (const point of haystack) {
      if (point[0] === needle[0] && point[1] === needle[1]) {
        return true;
      }
    }

    return false;
  }
}
