import { coordEach, flattenEach } from '@turf/meta';
import { AllGeoJSON } from '@turf/helpers';

import { distance } from '../perimeter/perimeter-mercator';
import booleanIntersects from '@turf/boolean-intersects';

/**
 * Takes a set of coordinates and returns a bounding box.
 */
export function getBBox(coordinates: number[][]): [number, number, number, number] {
  const bbox: [number, number, number, number] = [Infinity, Infinity, -Infinity, -Infinity];

  for (const coordinate of coordinates) {
    if (bbox[0] > coordinate[0]) bbox[0] = coordinate[0];
    if (bbox[1] > coordinate[1]) bbox[1] = coordinate[1];
    if (bbox[2] < coordinate[0]) bbox[2] = coordinate[0];
    if (bbox[3] < coordinate[1]) bbox[3] = coordinate[1];
  }

  return bbox;
}

/**
 * Is point inside BBox?
 */
export function inBBox(extent: [number, number, number, number], point: [number, number]) {
  return extent[0] <= point[0] && extent[1] <= point[1] && extent[2] >= point[0] && extent[3] >= point[1];
}

export function extentDimensions([minX, minY, maxX, maxY]: [number, number, number, number]): { width: number; height: number } {
  return {
    width: distance([minX, minY], [maxX, minY]),
    height: distance([minX, minY], [minX, maxY]),
  };
}

export function validateExtent(extent: [number, number, number, number], projectionExtent: [number, number, number, number]) {
  const hasNumbers = !extent.some((n) => n === Infinity || isNaN(n));

  return (
    hasNumbers &&
    extent[0] >= projectionExtent[0] &&
    extent[0] <= projectionExtent[2] &&
    extent[1] >= projectionExtent[1] &&
    extent[1] <= projectionExtent[3] &&
    extent[2] >= projectionExtent[0] &&
    extent[2] <= projectionExtent[2] &&
    extent[3] >= projectionExtent[1] &&
    extent[3] <= projectionExtent[3]
  );
}

export function generateExtent(geojson: GeoJSON.FeatureCollection | GeoJSON.Feature | GeoJSON.Geometry) {
  const result: [number, number, number, number] = [Infinity, Infinity, -Infinity, -Infinity];

  coordEach(geojson as AllGeoJSON, (coord) => {
    if (result[0] > coord[0]) {
      result[0] = coord[0];
    }
    if (result[1] > coord[1]) {
      result[1] = coord[1];
    }
    if (result[2] < coord[0]) {
      result[2] = coord[0];
    }
    if (result[3] < coord[1]) {
      result[3] = coord[1];
    }
  });

  return result;
}

export function getExtentCoordinates(extent: [number, number, number, number]): [number, number][] {
  return [
    [extent[0], extent[3]],
    [extent[2], extent[3]],
    [extent[2], extent[1]],
    [extent[0], extent[1]],
    [extent[0], extent[3]],
  ];
}

export function extentToGeometry(extent: [number, number, number, number]): GeoJSON.Polygon {
  return {
    type: 'Polygon',
    coordinates: [getExtentCoordinates(extent)],
  };
}

export function intersectsExtent(extent: [number, number, number, number], data: GeoJSON.Feature | GeoJSON.Geometry) {
  const source = extentToGeometry(extent);
  return booleanIntersects(source, data as any);
}
