import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'la-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {
  @Input() title: string;
  @Input() close = false;
}
