import { HttpClient } from '@angular/common/http';
import { Extent } from 'ol/extent';
import GeoJSONFormat from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import { tile } from 'ol/loadingstrategy';
import { ProjectionLike, transformExtent } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { StyleFunction } from 'ol/style/Style';
import { createXYZ } from 'ol/tilegrid';
import { take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { VSLayerDefinition } from '../models/layer.model';

type VSLayerConfig = VSLayerDefinition & {
  style: StyleFunction;
  viewProjection: ProjectionLike;
  http: HttpClient;
  visible?: boolean;
  opacity?: number;
  selectable?: boolean;
  tileLoadPaymentError: () => void;
  tileLoadSuccess: () => void;
};

function isFeatureCollection(data: GeoJSON.FeatureCollection | GeoJSON.Feature[]): data is GeoJSON.FeatureCollection {
  return !!(data['type'] && data['type'] === 'FeatureCollection');
}

const tileLoadFunction = (
  tileLoadPaymentError,
  tileLoadSuccess,
  source: VectorSource,
  mapProjection: ProjectionLike,
  config: any,
  http?: any
): void => {
  if (!http) {
    return undefined;
  }

  const { projection: dataProjection, url } = config;

  source.setLoader((extent: Extent) => {
    const sourceExtent = transformExtent(extent, mapProjection, dataProjection);
    const sourceUrl = url.replace('{bbox}', sourceExtent.join());

    http
      .get(sourceUrl)
      .pipe(
        take(1),
        tap(() => tileLoadSuccess())
      )
      .subscribe(
        (geojson: GeoJSON.FeatureCollection | GeoJSON.Feature[]) => {
          if (!geojson || (isFeatureCollection(geojson) && !geojson.features?.length)) {
            return;
          }
          const features = isFeatureCollection(geojson) ? geojson.features : geojson;
          const featuresFromSource = source.getFeatures();
          const dataFeaturesWithIds = features
            .map((feature) => ({
              ...feature,
              id: feature.id ? feature.id : uuidv4(),
            }))
            .filter((feature) => !featuresFromSource.find((f) => f.getId() === feature.id));

          const data = {
            type: 'FeatureCollection',
            features: dataFeaturesWithIds,
          };

          source.addFeatures(
            new GeoJSONFormat().readFeatures(data, {
              dataProjection,
              featureProjection: mapProjection,
            })
          );
        },
        (error) => {
          if (error.status === 402) {
            tileLoadPaymentError();
            source.addFeatures([]);
          }
        }
      );
  });
};

export const vsSource = (config: VSLayerConfig) => {
  const {
    id,
    viewProjection,
    style,
    http,
    visible,
    opacity,
    minResolution,
    maxResolution,
    minZoom,
    maxZoom,
    selectable,
    tileLoadSuccess,
    tileLoadPaymentError,
  } = config;

  const options = {
    source: new VectorSource({
      format: new GeoJSONFormat(),
      strategy: tile(createXYZ({ tileSize: [2048, 2048] })),
    }),
    style,
    visible,
    opacity,
    minResolution,
    maxResolution,
    minZoom,
    maxZoom,
  };

  tileLoadFunction(tileLoadPaymentError, tileLoadSuccess, options.source, viewProjection, config, http);

  const vectorLayer = new VectorLayer(options);
  vectorLayer.setProperties({ id, selectable });

  return vectorLayer;
};
