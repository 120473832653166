import Tile from 'ol/layer/Tile';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import { ArcGISLayerDefinition } from '../models/layer.model';

type ArcGISLayerConfig = ArcGISLayerDefinition & { visible?: boolean; opacity?: number };

export const arcgisSource = (config: ArcGISLayerConfig) => {
  const { visible, opacity, url, urls, attributions } = config;

  const options = {
    source: new TileArcGISRest({
      url,
      urls,
      attributions,
    }),
    visible,
    opacity,
  };

  return new Tile(options);
};
