import { TinyColor } from '@ctrl/tinycolor';
import { get, isNil, isNumber, isString } from 'lodash';
import { FeatureLike } from 'ol/Feature';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { FeatureProperties } from '../models/properties.model';
import { StyleConfig } from '../models/style.model';
import { renderPattern } from '../utils/patterns.utils';
import { boringStyle, hiddenStyle } from './static.styles';

export function buildCustomStyle(styleConfig: StyleConfig): Style {
  const style = boringStyle.clone();

  if (!styleConfig) {
    return style;
  }

  if (styleConfig.fillColour) {
    const fillColor = styleConfig.fillColour;
    const fillOpacity = (styleConfig.fillOpacity !== undefined ? styleConfig.fillOpacity : 50) / 100;

    if (styleConfig.fillPattern) {
      const patternColor = styleConfig.fillPatternColour || 'rgb(136, 138, 255)';
      const patternOpacity = (styleConfig.fillPatternOpacity !== undefined ? styleConfig.fillPatternOpacity : 100) / 100;

      const pattern = renderPattern(styleConfig.fillPattern, {
        fillColor,
        fillOpacity,
        patternColor,
        patternOpacity,
      });

      if (pattern) {
        style.setFill(new Fill({ color: pattern }));
      }
    } else {
      style.setFill(new Fill({ color: new TinyColor(fillColor).setAlpha(fillOpacity).toRgbString() }));
    }

    if (!styleConfig.strokeColour) {
      style.getStroke().setColor('transparent');
    }
  }

  if (styleConfig.strokeWidth !== undefined && styleConfig.strokeColour) {
    const strokeColor = styleConfig.strokeColour;
    const strokeWidth = styleConfig.strokeWidth ? styleConfig.strokeWidth : 1;
    const strokeDash = Array.isArray(styleConfig.strokeDash) ? styleConfig.strokeDash : undefined;
    const strokeOpacity = (styleConfig.strokeOpacity !== undefined ? styleConfig.strokeOpacity : 80) / 100;

    style.setStroke(
      new Stroke({
        color: new TinyColor(strokeColor).setAlpha(strokeOpacity).toRgbString(),
        width: strokeWidth,
        lineDash: strokeDash,
      })
    );
  }

  if (styleConfig.imageRadius && styleConfig.imageFillColour && styleConfig.imageStrokeColour) {
    const imageRadius = styleConfig.imageRadius;
    const imageFillColor = styleConfig.imageFillColour;
    const imageFillOpacity = (styleConfig.imageFillOpacity !== undefined ? styleConfig.imageFillOpacity : 80) / 100;
    const imageStrokeColor = styleConfig.imageStrokeColour;
    const imageStrokeOpacity = (styleConfig.imageStrokeOpacity !== undefined ? styleConfig.imageStrokeOpacity : 80) / 100;
    const imageStrokeWidth = styleConfig.imageStrokeWidth || 2;

    style.setImage(
      new Circle({
        radius: imageRadius,
        fill: new Fill({ color: new TinyColor(imageFillColor).setAlpha(imageFillOpacity).toRgbString() }),
        stroke: new Stroke({
          color: new TinyColor(imageStrokeColor).setAlpha(imageStrokeOpacity).toRgbString(),
          width: imageStrokeWidth,
        }),
      })
    );
  }

  return style;
}

function toRadians(angle: number) {
  return angle * (Math.PI / 180);
}

function getTextString(option: string, properties: FeatureProperties) {
  if (/^\{([a-zA-Z0-9_-]+\}$)/.test(option)) {
    const lookup = option.replace('{', '').replace('}', '');
    return get(properties, [lookup], 'text-point');
  }

  return option;
}

function getRotation(option: string | number, properties: FeatureProperties): number {
  if (isString(option) && /^\{([a-zA-Z0-9_-]+\}$)/.test(option)) {
    const lookup = option.replace('{', '').replace('}', '');
    return Number(get(properties, [lookup], 0));
  }

  return Number(option || 0);
}

function buildLabelStyle(styleConfig: StyleConfig) {
  const size = !isNil(styleConfig.textSize) ? styleConfig.textSize : 14;

  if (size === 0) {
    return hiddenStyle;
  }

  const rotation = !isNil(styleConfig.textRotation) && isNumber(styleConfig.textRotation) ? toRadians(styleConfig.textRotation) : 0;
  const fillColor = !isNil(styleConfig.textFillColour) ? styleConfig.textFillColour : '#000000';
  const fillOpacity = !isNil(styleConfig.textFillOpacity) ? styleConfig.textFillOpacity : 100;
  const shadowColor = !isNil(styleConfig.textShadowColour) ? styleConfig.textShadowColour : '#ffffff';
  const shadowOpacity = !isNil(styleConfig.textShadowOpacity) ? styleConfig.textShadowOpacity : 100;
  const shadowWidth = !isNil(styleConfig.textShadowWidth) ? styleConfig.textShadowWidth : 0;

  return new Style({
    text: new Text({
      font: `${size}px Roboto`,
      text: '',
      offsetY: 0,
      offsetX: -3,
      fill: new Fill({ color: new TinyColor(fillColor).setAlpha(fillOpacity / 100).toRgbString() }),
      stroke: new Stroke({
        color: new TinyColor(shadowColor).setAlpha(shadowOpacity / 100).toRgbString(),
        width: shadowWidth,
      }),
      rotation,
    }),
  });
}

export function buildStyleFunction(styleConfig: StyleConfig) {
  let style: Style, type: string;

  if (styleConfig.textString || styleConfig.textSize || styleConfig.textFillColour) {
    style = buildLabelStyle(styleConfig);
    type = 'label';
  } else {
    style = buildCustomStyle(styleConfig);
    type = 'custom';
  }

  return function (feature: FeatureLike, resolution: number, scale = 1) {
    const properties = feature['properties_'] || feature['values_'] || {};

    if (type === 'label') {
      if (styleConfig.textMinResolution && styleConfig.textMinResolution > resolution) {
        return hiddenStyle;
      }

      if (styleConfig.textMaxResolution && styleConfig.textMaxResolution < resolution) {
        return hiddenStyle;
      }

      const textStyle = style.clone();
      textStyle.getText().setText(getTextString(styleConfig.textString, properties));
      textStyle.getText().setRotation(toRadians(getRotation(styleConfig.textRotation, properties)));
      textStyle.getText().setScale(scale);

      return textStyle;
    }

    return style;
  };
}
