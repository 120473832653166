import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { MapSelectComponent } from '../map-select/map-select.component';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-merge',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMergeComponent implements OnInit, OnDestroy {
  @Output() selected = new EventEmitter<void>();

  constructor(private host: MapComponent, private select: MapSelectComponent) {}

  ngOnInit(): void {
    this.select.toggleShiftRequired(false);
  }

  ngOnDestroy(): void {
    this.select.toggleShiftRequired(true);
  }
}
