import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DataWorkflowFrameComponent } from './components/map-bbox/map-bbox.component';
import { MapBufferPreviewComponent } from './components/map-buffer-preview/map-buffer-preview.component';
import { MapDrawComponent } from './components/map-draw/map-draw.component';
import { MapLayerComponent } from './components/map-layer/map-layer.component';
import { MapMeasureComponent } from './components/map-measure/map-measure.component';
import { MapMergeComponent } from './components/map-merge/map-merge.component';
import { MapModifyTMarkComponent } from './components/map-modify-t-mark/map-modify-t-mark.component';
import { MapModifyComponent } from './components/map-modify/map-modify.component';
import { MapMoveComponent } from './components/map-move/map-move.component';
import { MapPaperBboxComponent } from './components/map-paper-bbox/map-paper-bbox.component';
import { MapPickComponent } from './components/map-pick/map-pick.component';
import { MapRotateExtComponent } from './components/map-rotate-ext/map-rotate.component';
import { MapRotateComponent } from './components/map-rotate/map-rotate.component';
import { MapSelectComponent } from './components/map-select/map-select.component';
import { MapSnapComponent } from './components/map-snap/map-snap.component';
import { MapSplitComponent } from './components/map-split/map-split.component';
import { MapComponent } from './components/map/map.component';

@NgModule({
  declarations: [
    MapComponent,
    MapLayerComponent,
    MapMeasureComponent,
    MapBufferPreviewComponent,
    MapSelectComponent,
    MapDrawComponent,
    MapModifyComponent,
    MapMoveComponent,
    MapRotateComponent,
    MapRotateExtComponent,
    MapSnapComponent,
    MapPickComponent,
    MapSplitComponent,
    MapMergeComponent,
    MapModifyTMarkComponent,
    DataWorkflowFrameComponent,
    MapPaperBboxComponent,
  ],
  exports: [
    MapComponent,
    MapLayerComponent,
    MapMeasureComponent,
    MapSelectComponent,
    MapBufferPreviewComponent,
    MapDrawComponent,
    MapModifyComponent,
    MapMoveComponent,
    MapRotateComponent,
    MapRotateExtComponent,
    MapSnapComponent,
    MapPickComponent,
    MapSplitComponent,
    MapMergeComponent,
    MapModifyTMarkComponent,
    DataWorkflowFrameComponent,
    MapPaperBboxComponent,
  ],
  imports: [CommonModule],
})
export class OpenLayersModule {}
