import { TinyColor } from '@ctrl/tinycolor';
import { get, nth } from 'lodash';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { Fill, Style, Text } from 'ol/style';
import { FeatureProperties } from '../models/properties.model';

export function buildArrowForLineStyle(feature: Feature<Geometry>, resolution: number): Style | undefined {
  const { laStyle, laStyleType }: FeatureProperties = feature['values_'] || {};

  const geometry = feature.getGeometry();

  if (geometry instanceof LineString) {
    const end = nth(geometry.getCoordinates(), -1);
    const start = nth(geometry.getCoordinates(), -2);

    if (!start || !end) {
      return undefined;
    }

    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx);

    const calculateSize = (width: number) => {
      let size = 26;

      if (width) {
        size = 18 + width * 4;
      }

      if (resolution > 19.1) {
        size = Math.round(size / 2);
      } else if (resolution > 9.55) {
        size = Math.round((size / 4) * 3);
      } else if (resolution > 4.77) {
        size = Math.round((size / 6) * 5);
      }

      return size;
    };

    const isCustom = laStyleType === 'custom';

    const strokeWidth = isCustom ? get(laStyle, ['strokeWidth'], 0) : 0;
    const strokeColor = isCustom ? get(laStyle, ['strokeColour'], '#000000') : '#000000';
    const strokeOpacity = isCustom ? get(laStyle, ['strokeOpacity'], 100) : 100;
    const color = new TinyColor(strokeColor).setAlpha(strokeOpacity / 100).toRgbString();

    return new Style({
      geometry: () => new Point(end),
      text: new Text({
        font: `${calculateSize(strokeWidth)}px Material Icons`,
        text: 'play_arrow',
        offsetY: 0,
        offsetX: -3,
        rotation: -rotation,
        fill: new Fill({ color }),
      }),
    });
  }

  return undefined;
}
