export function mercatorGeneratePoint(a: number[], b: number[], width: number): number[] {
  const vector = [b[0] - a[0], b[1] - a[1]];
  const ratio = width / Math.sqrt(Math.pow(vector[0], 2) + Math.pow(vector[1], 2));

  const vector2 = [vector[0] * ratio, vector[1] * ratio];

  const c1 = a[0] + vector2[0];
  const c2 = a[1] + vector2[1];

  return [c1, c2];
}
