import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';

import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FeatureStylesComponent } from './components/feature-styles/feature-styles.component';
import { LineTypeDropdownComponent } from './components/line-type-dropdown/line-type-dropdown.component';
import { SliderComponent } from './components/slider/slider.component';

@NgModule({
  declarations: [ColorPickerComponent, SliderComponent, LineTypeDropdownComponent, FeatureStylesComponent],
  exports: [ColorPickerComponent, SliderComponent, LineTypeDropdownComponent, FeatureStylesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatTabsModule,
  ],
})
export class StylesModule {}
