import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MATERIAL_COLORS } from './colors';

@Component({
  selector: 'la-color-picker',
  styleUrls: ['./color-picker.component.scss'],
  templateUrl: './color-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  readonly colorSchema: string[][] = MATERIAL_COLORS;
  color: string;

  constructor(private cd: ChangeDetectorRef) {}

  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string) {
    this.selectColor(input);
  }

  onClick(color: string) {
    this.selectColor(color);
  }

  isColorSelected(color: string) {
    return color?.localeCompare(this.color, undefined, { sensitivity: 'base' }) === 0;
  }

  private selectColor(color: string) {
    this.color = color;
    this.onChange(this.color);
    this.cd.markForCheck();
  }
}
