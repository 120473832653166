import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import { ProjectionLike } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { StyleFunction } from 'ol/style/Style';

import { GeoJSONLayerDefinition } from '../models/layer.model';

type GeoJSONLayerConfig = GeoJSONLayerDefinition & {
  viewProjection: ProjectionLike;
  style: StyleFunction;
  visible?: boolean;
  opacity?: number;
  readonly?: boolean;
  selectable?: boolean;
  order?: (string | number)[];
};

function reorderFeatures(layer: VectorLayer, f1: Feature, f2: Feature) {
  const { order } = layer.getProperties() as { order: Array<string | number> };
  const i1 = order.indexOf(f1.getId());
  const i2 = order.indexOf(f2.getId());

  return i1 - i2;
}

export const geojsonSource = (config: GeoJSONLayerConfig) => {
  const { projection, viewProjection, id, visible, opacity, style, readonly, selectable, order } = config;
  const geojson = config.geojson?.features ? config.geojson : { type: 'FeatureCollection', features: [] };

  const features = new GeoJSON().readFeatures(geojson, {
    dataProjection: projection,
    featureProjection: viewProjection,
  });

  const options = {
    source: new VectorSource({
      features: new Collection(features),
    }),
    visible,
    opacity,
    style,
  };

  const vectorLayer = new VectorLayer(options);
  vectorLayer.setProperties({ id, order, readonly, selectable });

  if (order?.length > 0) {
    vectorLayer.setRenderOrder((f1: Feature, f2: Feature) => reorderFeatures(vectorLayer, f1, f2));
  }

  return vectorLayer;
};
