import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { clickUpgrade } from '../../store';

@Component({
  selector: 'la-upgrade-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrl: './upgrade-subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeSubscriptionComponent {
  @Output() navigate = new EventEmitter<MouseEvent>();

  constructor(private store: Store) {}

  clickUpgrade() {
    this.navigate.emit();
    this.store.dispatch(clickUpgrade({ upgrade_location: 'header' }));
  }
}
