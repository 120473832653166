import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { userActions } from '@core/store';
import { loginSelectors } from '../../store';
import { AppErrorCodes } from '@core/api';

@Component({
  selector: 'la-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnDestroy {
  public isActivationRequired = false;
  public isMigrationRequired = false;
  public isGenericError = false;
  public isTemporarilyBlocked = false;
  public isSuspended = false;

  public userRecentlyActivated = false;
  public userRecentlyChangedPassword = false;
  public userRecentlyCreated = false;

  errorMessage = this.store.select(loginSelectors.getLoginErrorMessage);
  loginStatus = this.store.select(loginSelectors.getLoginStatus);
  loading$ = this.store.select(loginSelectors.getLoginLoading);

  loginToAccount = true;
  hide = true;

  NOT_ACTIVE = AppErrorCodes.NOT_ACTIVE;
  PASSWORD_RESET_REQUIRED = AppErrorCodes.PASSWORD_RESET_REQUIRED;

  public form = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras && navigation.extras.state) {
      this.userRecentlyActivated = !!navigation.extras.state.activation;
      this.userRecentlyChangedPassword = !!navigation.extras.state.passwordReset;
      this.userRecentlyCreated = !!navigation.extras.state.userCreated;
      this.loginToAccount = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    const { email, password } = this.form.value;
    this.store.dispatch(userActions.login({ email, password }));
  }

  toggleVisibility() {
    this.hide = !this.hide;
  }
}
