import { createReducer, on } from '@ngrx/store';

import { initActions } from '@core/store';
import * as mapContainerActions from '../actions/map-container.actions';
import { leaveMap } from '../actions/map-page.actions';
import * as rightHandPanelActions from '../actions/right-hand-panel.actions';

export const featureKey = 'attributesPicker';

export interface AttributesPickerState {
  from?: string;
  attributes?: GeoJSON.GeoJsonProperties;
}

export const initialState: AttributesPickerState = {};

export const reducer = createReducer<AttributesPickerState>(
  initialState,
  on(initActions.appCleanup, leaveMap, () => ({ ...initialState })),

  on(rightHandPanelActions.enableAttributesPick, (state, { from }) => ({ from })),
  on(mapContainerActions.pickAttributesOnMap, (state, { properties }) => ({ ...state, attributes: properties }))
);
