import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { TOP_UP_OPTIONS } from '../../constants/top-up-options.const';
import { creditsActions, creditsSelectors } from '../../store';

@Component({
  selector: 'la-credits-top-up-dialog',
  templateUrl: './credits-top-up-dialog.component.html',
  styleUrls: ['./credits-top-up-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditsTopUpDialogComponent implements OnInit {
  creditOptions = TOP_UP_OPTIONS;
  form: UntypedFormGroup;

  saving$ = this.store.select(creditsSelectors.getBuyCreditsSaving);
  ready$ = this.store.select(creditsSelectors.getBuyCreditsReady);
  error$ = this.store.select(creditsSelectors.getBuyCreditsError);

  isPaymentInvalid$ = this.store.select(creditsSelectors.getPaymentMethodStatus);
  isPaymentUpdated$ = this.store.select(creditsSelectors.getValidPaymentMethodAfterChange);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fromPaywall?: boolean },
    public dialogRef: MatDialogRef<CreditsTopUpDialogComponent>,
    private store: Store
  ) {
    this.ready$
      .pipe(
        filter((ready) => !!ready),
        takeUntilDestroyed(),
        tap(() => this.dialogRef.close(true))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  submit() {
    this.store.dispatch(creditsActions.buyCredits({ credits: this.form.controls['creditOptions'].value }));
  }

  addPaymentMethod() {
    this.store.dispatch(creditsActions.updatePaymentMethod());
  }

  private buildForm() {
    this.form = new FormGroup({
      creditOptions: new FormControl<number>(null, Validators.required),
    });
  }
}
