import { Coordinate } from 'ol/coordinate';
import { GeoJSONFeature, GeoJSONFeatureCollection, GeoJSONGeometry } from 'ol/format/GeoJSON';
import { ProjectionLike, transform } from 'ol/proj';
import Projection from 'ol/proj/Projection';

export function projectionCode(projection: ProjectionLike) {
  return projection instanceof Projection ? projection.getCode() : projection;
}

export function reprojectGeometry(geometry: GeoJSONGeometry, from: ProjectionLike, to: ProjectionLike) {
  switch (geometry.type) {
    case 'GeometryCollection':
      return {
        ...geometry,
        geometries: geometry.geometries.map((subGeometry) => reprojectGeometry(subGeometry, from, to)),
      };
    case 'MultiPolygon':
      return {
        ...geometry,
        coordinates: geometry.coordinates.map((rings) => {
          return rings.map((interior) => interior.map((position) => transform(position, from, to)));
        }),
      };
    case 'MultiLineString':
    case 'Polygon':
      return {
        ...geometry,
        coordinates: geometry.coordinates.map((interior) => {
          return interior.map((position) => transform(position, from, to));
        }),
      };
    case 'MultiPoint':
    case 'LineString':
      return {
        ...geometry,
        coordinates: geometry.coordinates.map((position) => transform(position, from, to)),
      };
    case 'Point':
      return {
        ...geometry,
        coordinates: transform(geometry.coordinates, from, to),
      };
  }
}

export function reprojectPoint(coordinate: Coordinate, from: ProjectionLike, to: ProjectionLike): Coordinate {
  return transform(coordinate, from, to);
}

export function reprojectFeature(feature: GeoJSONFeature, from: ProjectionLike, to: ProjectionLike): GeoJSONFeature {
  return {
    ...feature,
    geometry: reprojectGeometry(feature.geometry, from, to),
  };
}

export function reprojectGeoJSON(geojson: GeoJSONFeatureCollection, from: ProjectionLike, to: ProjectionLike): GeoJSONFeatureCollection {
  return {
    ...geojson,
    features: geojson.features.map((feature) => reprojectFeature(feature, from, to)),
  };
}

export function reprojectExtent([minX, minY, maxX, maxY]: [number, number, number, number], from: ProjectionLike, to: ProjectionLike) {
  const southWest = transform([minX, minY], from, to);
  const northEast = transform([maxX, maxY], from, to);
  return [...southWest, ...northEast] as [number, number, number, number];
}
