import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import Mask from 'ol-ext/filter/Mask';
import Transform from 'ol-ext/interaction/Transform';
import Collection from 'ol/Collection';
import { getCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Subject } from 'rxjs';
import { delay, takeUntil, throttleTime } from 'rxjs/operators';
import { extentDimensions, getExtentCoordinates } from '~/libs/geometry/extent';
import { Extent } from '../../models/extent.model';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';

type SelectEvent = { feature?: Feature };

const boringStyle = new Style({
  stroke: new Stroke({
    color: [255, 0, 0, 1],
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.02)',
  }),
  zIndex: 10000,
});

@Component({
  selector: 'la-map-bbox',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataWorkflowFrameComponent implements OnInit, OnDestroy {
  @Output() setExtent = new EventEmitter<Extent>();

  private instance: Transform;
  private mask: Mask;
  private extent: Extent;

  private sketchLayer = new VectorLayer({
    source: new VectorSource({
      features: [],
    }),
    updateWhileInteracting: true,
    style: boringStyle,
    zIndex: 9999,
  });

  private readonly event$ = new Subject<Extent>();
  private readonly destroy$ = new Subject<void>();

  constructor(private host: MapComponent) {}

  ngOnInit(): void {
    this.event$.pipe(delay(16), throttleTime(100), takeUntil(this.destroy$)).subscribe((data) => this.setExtent.emit(data));

    this.extent = this.getViewExtent();
    this.clipRectangle(this.extent);
  }

  ngOnDestroy(): void {
    this.cleanPreviousMaskIfExists();
    this.sketchLayer.getSource().clear();
    this.host.instance.removeLayer(this.sketchLayer);

    if (this.instance) {
      this.host.instance.removeInteraction(this.instance);
      this.instance = undefined;
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  private getViewExtent() {
    const viewExtent = this.host.instance.getView().calculateExtent(this.host.instance.getSize());
    const { width, height } = extentDimensions(viewExtent);
    const viewWidth = width * 0.8;
    const viewHeight = height * 0.8;

    const bottomLeft = new Point(getCenter(viewExtent));
    bottomLeft.translate(-viewWidth / 2, -viewHeight / 2);

    const topRight = new Point(getCenter(viewExtent));
    topRight.translate(viewWidth / 2, viewHeight / 2);

    return [...bottomLeft.getCoordinates(), ...topRight.getCoordinates()] as Extent;
  }

  private clipRectangle(extent: Extent) {
    const feature = this.getBoxFeature(extent);

    this.sketchLayer.getSource().addFeatures([feature]);
    this.applyMask(feature);
    this.host.instance.addLayer(this.sketchLayer);

    this.instance = new Transform({
      layers: [this.sketchLayer],
      features: new Collection([feature]),
      scale: true,
      translate: true,
      stretch: false,
      rotate: false,
      translateFeature: false,
    });

    addInteraction(this.host.instance, this.instance);

    this.instance.select(feature, true);
    this.instance.on('select', (e: SelectEvent) => {
      if (e?.feature === undefined) {
        this.instance.select(feature, true);
      }
    });

    this.instance.on(['scaleend', 'scaling', 'translateend', 'translating'], (e: SelectEvent) => {
      this.extent = e.feature?.getGeometry()?.getExtent();
      this.event$.next(this.extent);
    });

    this.event$.next(this.extent);
  }

  private getBoxFeature(extent: Extent): Feature {
    const bboxCoordinates = getExtentCoordinates(extent);
    return new Feature(new Polygon([bboxCoordinates]));
  }

  private applyMask(feature: Feature) {
    this.cleanPreviousMaskIfExists();

    this.mask = new Mask({
      feature,
      inner: false,
      fill: new Fill({ color: [0, 0, 0, 0.4] }),
    });

    (this.sketchLayer as any).addFilter(this.mask);
  }

  private cleanPreviousMaskIfExists() {
    if (this.mask) {
      (this.sketchLayer as any).removeFilter(this.mask);
    }
  }
}
