import { ProjectionLike } from 'ol/proj';

import { projectionCode, reprojectGeometry } from '../reproject';
import { mercatorArea } from './area-mercator';
import { sphericalArea } from './area-spherical';

export function area(feature: GeoJSON.Feature | GeoJSON.Geometry, from: ProjectionLike, to?: ProjectionLike) {
  const projection = to ? projectionCode(to) : projectionCode(from);

  let geometry: GeoJSON.Geometry = feature['geometry'] ? feature['geometry'] : feature;

  if (projectionCode(from) !== projection) {
    geometry = reprojectGeometry(geometry, from, to);
  }

  return projection === 'EPSG:4326' ? sphericalArea(geometry) : mercatorArea(geometry);
}
