import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import Style, { createEditingStyle } from 'ol/style/Style';
import { MapInteractionAbstract } from '../../abstracts/map-interaction.abstract';
import { FeatureDefinition } from '../../models/style.model';
import { buildMeasurementsStyles } from '../../styles/measurements.styles';
import { buildTMarkStyles } from '../../styles/t-mark.styles';
import { defineGeometryProperties } from '../../utils/draw.utils';
import { toGeoJSON } from '../../utils/feature.utils';
import { addInteraction } from '../../utils/interactions.utils';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-draw',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDrawComponent extends MapInteractionAbstract implements OnInit, OnDestroy {
  @Input() definition: FeatureDefinition;
  @Input() layers: string[] = [];
  @Input() properties: { [key: string]: any };

  @Output() drawEnd = new EventEmitter<{ layerId: string; feature: GeoJSON.Feature }>();

  private instance: Draw;

  private editingStyles: { [key: string]: Style[] };

  constructor(protected host: MapComponent) {
    super(host);
  }

  ngOnInit(): void {
    this.editingStyles = this.createEditingStyle();

    const layers = this.layers.map((id) => this.getLayerById(id));
    const features = layers.reduce<Feature[]>((acc, layer) => {
      if (layer && layer instanceof VectorLayer) {
        acc.push(...layer.getSource().getFeatures());
      }
      return acc;
    }, []);

    const layerToDrawOn = layers[0];

    if (layerToDrawOn && layerToDrawOn instanceof VectorLayer) {
      this.instance = new Draw({
        source: layerToDrawOn.getSource(),
        type: this.definition.geometryType as GeometryType,
        style: (feature: Feature, resolution: number) => this.interactionStyle(feature, resolution),
        ...defineGeometryProperties(this.definition, features),
      });

      addInteraction(this.host.instance, this.instance);

      this.instance.on('drawstart', (event) => this.onDrawStart(event));
      this.instance.on('drawend', (event) => this.onDrawEnd(event, layerToDrawOn));
    }
  }

  ngOnDestroy(): void {
    if (this.instance) {
      this.host.instance.removeInteraction(this.instance);
      this.instance = undefined;
    }
  }

  private onDrawStart(event: DrawEvent) {
    event.feature.setProperties({ laFeatureType: this.definition.id });
  }

  private onDrawEnd(event: DrawEvent, layer: VectorLayer) {
    this.enrichFeature(event.feature);

    if (this.properties) {
      event.feature.setProperties(this.properties);
    }

    this.drawEnd.emit({
      layerId: layer.getProperties().id,
      feature: toGeoJSON(event.feature),
    });
  }

  private interactionStyle(feature: Feature, resolution: number) {
    const additionalStyles = [];

    const featureStyles = this.editingStyles[feature.getGeometry().getType()];
    const measureStyles = buildMeasurementsStyles(feature, resolution, this.host.projection.view, this.host.projection.calculate);

    if (this.definition.id === 't_mark') {
      return [...featureStyles, ...additionalStyles, ...buildTMarkStyles(feature, featureStyles)];
    } else if (feature.getGeometry().getType() !== 'LineString' || this.definition.geometryType === 'LineString') {
      return [...featureStyles, ...additionalStyles, ...measureStyles];
    }

    return [];
  }

  private createEditingStyle(): { [key: string]: Style[] } {
    const styles = createEditingStyle();

    styles[GeometryType.POLYGON] = styles[GeometryType.POLYGON].concat(styles[GeometryType.LINE_STRING]);

    return styles;
  }
}
