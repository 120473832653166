export const environment = {
  production: true,
  version: '1.0.1',
  ngrx: {
    devtools: true,
    maxAge: 25,
  },
  intercom: {
    id: 'pwrlvwu7',
    delay: 200,
  },
  chargebee: {
    site: 'thelandapp-dev-app-test',
    delay: 200,
  },
  gtag: {
    enabled: true,
    key: 'G-T2GJDF2JRT',
    category: 'dev',
    debug: false,
  },
  natureReporting: {
    landManager: true,
  },
  amplitude: {
    apiKey: 'fe6c375983595d56f961770fac834405',
  },
  api: {
    clientToken: 'dGxhLWZyb250ZW5kOnNlY3JldEAxMjM=',
    authUrl: 'https://auth-dev.thelandapp.com',
    mapUrl: 'https://map-dev.thelandapp.com/web',
    mediaUrl: 'https://map-dev.thelandapp.com/web',
    geometryUrl: 'https://map-dev.thelandapp.com/web',
    layerUrl: 'https://layer-dev.thelandapp.com',
    debounce: 250,
  },
  socket: {
    enabled: true,
    url: 'https://socket-dev.thelandapp.com',
  },
  layers: {
    bingKey: 'AsvgmvQgA1dylwU5ylqdrpHpXcO9hnXZEQmsveIdmUctVs7ZD3jgilrnuxKlAQvC',
  },
  organisation: {
    maxTeams: 10,
  },
  tiles: {
    loadAuditTime: 1000,
  },
  featureFlags: {
    publish: true,
    addons: {
      mobile: true,
      integrationAPI: true,
    },
    cycleSwitch: true,
  },
  print: {
    dpi: 194,
  },
  limits: {
    maps: 4,
    teamTags: 10,
  },
};
