<mat-tab-group
  [ngClass]="{ 'hide-tabs': styleType === 'line' || styleType === 'arrow' || styleType === 'text' }"
  [formGroup]="featureStylesForm"
  [disablePagination]="true"
>
  <mat-tab label="Fill" *ngIf="styleType === 'polygon'">
    <div class="tab">
      <div class="feature-style-options">
        <section>
          <la-slider formControlName="fillOpacity" label="Opacity" [min]="0" [max]="100"></la-slider>
        </section>
      </div>
      <la-color-picker formControlName="fillColour"></la-color-picker>
    </div>
  </mat-tab>

  <mat-tab label="Pattern" *ngIf="styleType === 'polygon'">
    <div class="tab">
      <div class="feature-style-options">
        <section>
          <la-slider formControlName="fillPatternOpacity" label="Opacity" [min]="0" [max]="100"></la-slider>
        </section>
      </div>
      <div class="patterns-container">
        <div class="pattern-row" *ngFor="let patternRow of PATTERNS">
          <div
            class="pattern-preview"
            [ngClass]="{ selected: pattern === featureStylesForm.controls.fillPattern.value }"
            (click)="onFillPatternChanged(pattern)"
            [style.background-image]="getPattern(pattern)"
            *ngFor="let pattern of patternRow"
          ></div>
        </div>
        <div class="pattern-button-wrapper">
          <button (click)="onNoPatternClicked()" mat-stroked-button>No pattern</button>
        </div>
      </div>
      <la-color-picker formControlName="fillPatternColour"></la-color-picker>
    </div>
  </mat-tab>

  <mat-tab label="Border" *ngIf="styleType === 'polygon' || styleType === 'line' || styleType === 'arrow'">
    <div class="tab">
      <div class="feature-style-options">
        <section *ngIf="!isFromLegend">
          <la-slider formControlName="strokeWidth" label="Width" [min]="1" [max]="10"></la-slider>
        </section>
        <section>
          <la-slider formControlName="strokeOpacity" label="Opacity" [min]="0" [max]="100"></la-slider>
        </section>
        <section class="line-type">
          <la-line-type-dropdown formControlName="strokeDash" label="Line Type"></la-line-type-dropdown>
        </section>
      </div>
      <la-color-picker formControlName="strokeColour"></la-color-picker>
    </div>
  </mat-tab>

  <mat-tab label="Fill" *ngIf="styleType === 'point'">
    <div class="tab">
      <div class="feature-style-options">
        <section *ngIf="!isFromLegend">
          <la-slider formControlName="imageRadius" label="Radius" [min]="4" [max]="10"></la-slider>
        </section>
        <section>
          <la-slider formControlName="imageFillOpacity" label="Opacity" [min]="0" [max]="100"></la-slider>
        </section>
      </div>
      <la-color-picker formControlName="imageFillColour"></la-color-picker>
    </div>
  </mat-tab>

  <mat-tab label="Border" *ngIf="styleType === 'point'">
    <div class="tab">
      <div class="feature-style-options">
        <section *ngIf="!isFromLegend">
          <la-slider formControlName="imageStrokeWidth" label="Width" [min]="0" [max]="10"></la-slider>
        </section>
        <section>
          <la-slider formControlName="imageStrokeOpacity" label="Opacity" [min]="0" [max]="100"></la-slider>
        </section>
      </div>
      <la-color-picker formControlName="imageStrokeColour"></la-color-picker>
    </div>
  </mat-tab>

  <mat-tab label="Rotate" *ngIf="styleType === 'text'">
    <div class="tab">
      <div class="feature-style-options">
        <section>
          <la-slider formControlName="textRotation" label="Rotation" [min]="0" [max]="360"></la-slider>
        </section>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
