import { Injectable } from '@angular/core';
import { omit, isObject } from 'lodash';
import { Extent } from 'ol/extent';
import { Select } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import Map from 'ol/Map';
import { ProjectionLike } from 'ol/proj';
import VectorSource from 'ol/source/Vector';

import { validateExtent } from '~/libs/geometry/extent';
import { reprojectExtent } from '~/libs/geometry/reproject';
import { area as calculateArea } from '~/libs/geometry/area';
import { perimeter as calculatePerimeter } from '~/libs/geometry/perimeter';

import { fromGeoJSON, geometryFromGeoJSON, toGeoJSON } from '../utils/feature.utils';
import { getObjectId } from '../utils/id.utils';

export interface MapProjection {
  view: string;
  result: string;
  calculate: string;
}

export interface AdvancedFilter {
  geometryType?: string;
  excludeAssigned?: boolean;
  include?: { [key: string]: any };
  exclude?: { [key: string]: any };
}

@Injectable()
export class MapManagerService {
  public instance: Map;
  public projection: MapProjection;

  createFeatures(layerId: string, data: GeoJSON.Feature[], keepId: boolean = false) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();
      const features = data.map((json) => fromGeoJSON(this.enrichFeature(keepId ? json : omit(json, ['id']))));

      source.addFeatures(features);

      return features.map((feature) => toGeoJSON(feature));
    }

    return data.map((json) => this.enrichFeature(keepId ? json : omit(json, ['id'])));
  }

  deleteFeatures(layerId: string, filter: { [key: string]: string } | (string | number)[]) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();

      const features = source.getFeatures().filter((feature) => {
        if (Array.isArray(filter)) {
          return filter.includes(feature.getId());
        }

        const properties = feature.getProperties();
        const key = Object.keys(filter)[0];

        return properties[key] && properties[key] === filter[key];
      });

      return features.map((feature) => {
        source.removeFeature(feature);
        return toGeoJSON(feature);
      });
    }

    return null;
  }

  deleteFeaturesWithMedia(layerId: string, media: string) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();

      const features = source.getFeatures().filter((feature) => {
        const properties = feature.getProperties();

        return properties['laMediaIds']?.includes(media);
      });

      return features.map((feature) => {
        const id = feature.getId();

        source.removeFeature(feature);

        return id;
      });
    }

    return null;
  }

  updateFeature(layerId: string, featureId: string | number, data: Partial<GeoJSON.Feature>, force = false) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();
      const feature = source.getFeatureById(featureId);

      if (feature) {
        if (data.geometry) {
          feature.setGeometry(geometryFromGeoJSON(data.geometry));

          if (!data.properties) {
            feature.setProperties(this.calculateMeasurements(data.geometry), true);
          }
        }

        if (data.properties) {
          const properties = data.geometry ? { ...data.properties, ...this.calculateMeasurements(data.geometry) } : data.properties;

          if (force) {
            const keys = Object.keys(feature.getProperties()).filter((key) => key !== 'geometry');
            keys.forEach((key) => feature.unset(key, true));
          }

          feature.setProperties(properties, true);
        }

        feature.changed();

        return this.enrichFeature(toGeoJSON(feature));
      }

      return null;
    }

    return null;
  }

  unsetFeatureProperties(layerId: string, featureId: string | number, keys: string[]) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();
      const feature = source.getFeatureById(featureId);

      if (feature) {
        keys.forEach((key) => feature.unset(key));
        const properties = feature.getProperties();
        if (properties.laNotes?.length) {
          const laNotes = properties.laNotes.filter((note: string) => !keys.includes(note));
          feature.setProperties({ laNotes }, true);
        }

        feature.changed();

        return this.enrichFeature(toGeoJSON(feature));
      }

      return null;
    }

    return null;
  }

  selectFeatures(layerId: string, filter: (string | number)[] | AdvancedFilter[]) {
    if (!filter) {
      return [];
    }

    const layer = this.getLayerById(layerId);
    const selectInteraction = this.instance
      .getInteractions()
      .getArray()
      .find((interaction) => interaction instanceof Select);

    if (selectInteraction && layer && layer instanceof VectorLayer) {
      const features = layer
        .getSource()
        .getFeatures()
        .filter((feature) => {
          if (this.isCollectionOfIds(filter)) {
            return filter.map((f) => f.toString()).includes(feature.getId().toString());
          } else {
            const props = feature.getProperties();

            for (const filterBy of filter) {
              let result = false;

              if (filterBy.geometryType) {
                const featureGeometryType =
                  feature.getGeometry().getType().replace('Multi', '') === filterBy.geometryType.replace('Multi', '');
                result = filterBy.excludeAssigned ? featureGeometryType && !feature.getProperties().laFeatureType : featureGeometryType;
              }

              if (filterBy.include) {
                const findResult = this.findUsingRegexp(props, filterBy.include);
                result = filterBy.geometryType ? result && findResult : findResult;
              }

              if (filterBy.exclude) {
                const negationResult = !this.findUsingRegexp(props, filterBy.exclude);
                result = filterBy.geometryType || filterBy.include ? result && negationResult : negationResult;
              }

              if (result) {
                return true;
              }
            }

            return false;
          }
        });

      selectInteraction['select'](features, layer);

      return features.map((feature) => feature.getId());
    }

    return [];
  }

  deselectFeatures() {
    const selectInteraction = this.instance
      .getInteractions()
      .getArray()
      .find((interaction) => interaction instanceof Select);

    if (selectInteraction) {
      selectInteraction['select']();
    }
  }

  zoomToLayer(layerId: string, animate: boolean = false) {
    const layer = this.getLayerById(layerId);

    if (layer && layer instanceof VectorLayer) {
      const extent = layer.getSource().getExtent();

      if (!extent.some((n) => n === Infinity || isNaN(n))) {
        this.instance.getView().fit(extent, {
          maxZoom: 15,
          duration: animate ? 1000 : 0,
          padding: [100, 100, 100, 100],
        });
      }
    }
  }

  zoomToFeatures(layerId: string, featureIds: Array<string | number>, animate: boolean = false) {
    const layer = this.getLayerById(layerId);

    if (layer && layer instanceof VectorLayer) {
      const features = featureIds.map((id) => layer.getSource().getFeatureById(id)).filter((f) => !!f);

      if (!features || !features.length) {
        return;
      }

      const vectorSource = new VectorSource({ features });
      const extent = vectorSource.getExtent();

      this.instance.getView().fit(extent, {
        maxZoom: 12,
        duration: animate ? 1000 : 0,
        padding: [100, 100, 100, 100],
      });
    }
  }

  zoomToExtent(extent: Extent, projection: ProjectionLike, animate = true) {
    const result = reprojectExtent(extent, projection, this.instance.getView().getProjection());

    if (validateExtent(result, this.instance.getView().getProjection().getExtent())) {
      this.instance.getView().fit(result, {
        maxZoom: 12,
        duration: animate ? 1000 : 0,
        padding: [10, 10, 10, 10],
      });
    }
  }

  highlightPickedFeatures(layerId: string, featureIds: (string | number)[]) {
    const layer = this.getLayerById(layerId);

    if (layer) {
      layer.setProperties({ picked: featureIds });
      layer.changed();
    }
  }

  renderFeature(layerId: string, feature: GeoJSON.Feature) {
    const layer = this.getLayerById(layerId);

    if (layer instanceof VectorLayer) {
      const source = layer.getSource();
      const found = source.getFeatureById(feature.id);

      if (!found) {
        source.addFeature(fromGeoJSON(this.enrichFeature(feature)));
      } else {
        this.updateFeature(layerId, feature.id, feature);
      }
    }
  }

  private enrichFeature(json: GeoJSON.Feature): GeoJSON.Feature {
    return {
      ...json,
      id: json.id ? json.id : getObjectId(),
      properties: {
        ...json.properties,
        ...this.calculateMeasurements(json.geometry),
      },
    };
  }

  private calculateMeasurements(geometry: GeoJSON.Geometry) {
    let measurements: { area?: number; perimeter?: number } = {};

    switch (geometry.type) {
      case 'GeometryCollection':
      case 'MultiPolygon':
      case 'Polygon':
        measurements = {
          area: calculateArea(geometry, this.projection.view, this.projection.calculate),
          perimeter: calculatePerimeter(geometry, this.projection.view, this.projection.calculate),
        };
        break;
      case 'MultiLineString':
      case 'LineString':
        measurements = {
          perimeter: calculatePerimeter(geometry, this.projection.view, this.projection.calculate),
        };
        break;
    }

    return measurements;
  }

  private getLayerById(id: string) {
    return this.instance
      .getLayers()
      .getArray()
      .find((layer: TileLayer | VectorLayer | VectorTileLayer) => {
        const properties = layer.getProperties();
        return properties && properties.id === id;
      }) as TileLayer | VectorLayer | VectorTileLayer;
  }

  private isCollectionOfIds(filter: (string | number | AdvancedFilter)[]): filter is (string | number)[] {
    return filter.filter((val) => isObject(val)).length === 0;
  }

  private findUsingRegexp(data: { [key: string]: any }, filter: { [key: string]: any }) {
    const keys = Object.keys(filter);

    return keys.reduce((accumulator, key) => {
      const value = filter[key];

      if ((value.test && value.test(data[key])) || value === data[key]) {
        accumulator = true;
      }

      return accumulator;
    }, false);
  }
}
