import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isArray } from 'lodash';

export type YesNoData = { title: string; content: string | string[]; ok: string; cancel: string; okButtonColor?: string };

@Component({
  selector: 'la-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: YesNoData, public dialogRef: MatDialogRef<YesNoDialogComponent, boolean>) {}

  get isContentArray() {
    return isArray(this.data.content);
  }

  get okButtonColor() {
    return this.data.okButtonColor ? this.data.okButtonColor : 'warn';
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }
}
