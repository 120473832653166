import { TinyColor } from '@ctrl/tinycolor';
import { get, isNil } from 'lodash';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { FeatureProperties } from '../models/properties.model';
import { hiddenStyle } from './static.styles';

export function buildTextPointStyle(feature: Feature<Geometry>, resolution: number, scale: number = 1): Style {
  const { description, laStyle, laStyleType }: FeatureProperties = feature['values_'] || {};

  const toRadians = (angle: number) => angle * (Math.PI / 180);
  const isCustom = laStyleType === 'custom';

  if (isCustom && laStyle.textMinResolution && laStyle.textMinResolution > resolution) {
    return hiddenStyle;
  }

  if (isCustom && laStyle.textMaxResolution && laStyle.textMaxResolution < resolution) {
    return hiddenStyle;
  }

  const size = isCustom && !isNil(laStyle.textSize) ? laStyle.textSize || 14 : 14;
  const rotation = isCustom && !isNil(laStyle.textRotation) ? toRadians(laStyle.textRotation) : 0;
  const fillColor = isCustom && laStyle['textFillColour'] ? get(laStyle, ['textFillColour'], '#000000') : '#000000';
  const fillOpacity = isCustom && !isNil(laStyle.textFillOpacity) ? laStyle.textFillOpacity : 100;
  const shadowColor = isCustom && laStyle['textShadowColour'] ? get(laStyle, ['textShadowColour'], '#ffffff') : '#ffffff';
  const shadowOpacity = isCustom && !isNil(laStyle.textShadowOpacity) ? laStyle.textShadowOpacity : 100;
  const shadowWidth = isCustom && !isNil(laStyle.textShadowWidth) ? laStyle.textShadowWidth : Math.ceil(size * 0.4);

  return new Style({
    text: new Text({
      font: `${size}px Roboto`,
      text: description ? description : 'text-point',
      offsetY: 0,
      offsetX: -3,
      fill: new Fill({ color: new TinyColor(fillColor).setAlpha(fillOpacity / 100).toRgbString() }),
      stroke: new Stroke({
        color: new TinyColor(shadowColor).setAlpha(shadowOpacity / 100).toRgbString(),
        width: shadowWidth,
      }),
      rotation,
      scale,
    }),
  });
}
