import { ColorLike } from 'ol/colorlike';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import { Stroke, Style } from 'ol/style';

export function buildTMarkStyles(feature: Feature<Geometry>, styles: Style[]) {
  const geometry: LineString = feature.getGeometry() as LineString;
  const coordinates = geometry.getCoordinates();

  if (typeof coordinates[0] === 'number') {
    return [];
  } else {
    const dash = getTDash(coordinates[0] as [number, number], coordinates[1] as [number, number]);
    return styles.map((style) => getTDashStyle(dash, style.getStroke().getColor(), style.getStroke().getWidth()));
  }
}

function getTDashStyle(geometry: LineString, color: ColorLike | number[], width: number | undefined): Style {
  return new Style({
    geometry: () => geometry,
    stroke: new Stroke({
      color,
      width,
    }),
  });
}

function getTDash(A: [number, number], B: [number, number]): LineString {
  const ABx = A[0] - B[0];
  const ABy = A[1] - B[1];

  const BCx = ABy / 2;
  const BCy = -ABx / 2;

  const Cx = B[0] + BCx;
  const Cy = B[1] + BCy;

  const BDx = -ABy / 2;
  const BDy = ABx / 2;

  const Dx = B[0] + BDx;
  const Dy = B[1] + BDy;

  const C = [Cx, Cy];
  const D = [Dx, Dy];

  return new LineString([C, D]);
}
