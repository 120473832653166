<mat-card>
  <mat-card-content>
    <div class="left-column">
      <div class="header">
        <img src="assets/layout/os_partner_logo.png" />
        <span>Ordnance Survey Premium Data</span>
      </div>
      <p>Some tiles are blank because you have insufficient credits.</p>
    </div>
    <button mat-flat-button color="primary" (click)="openTopUpDialog.emit()">Add OS Credits</button>
  </mat-card-content>
</mat-card>
