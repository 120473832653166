import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigApiService, Sector } from '@core/api';
import { PASSWORD_PATTERN } from '@core/common';
import { COUNTRY_CODES } from '~/shared/country-codes';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'la-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
  hide = true;

  public isUserMigrating = false;
  public form: UntypedFormGroup;
  public sectors: Sector[];

  public readonly COUNTRY_CODES = COUNTRY_CODES;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configApiService: ConfigApiService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      switch (status.type) {
        case 'new':
          this.form = this.createFormForNewUsers();
          break;
        case 'old':
          this.isUserMigrating = true;
          this.form = this.createFormForMigratingUsers();
          break;
      }
    });

    this.configApiService
      .getSectors(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((sectors) => {
        this.sectors = sectors;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleVisibility() {
    this.hide = !this.hide;
  }

  public submit() {
    if (this.route.snapshot.params.token) {
      if (this.form.value.countryCode && this.form.value.phoneNumber) {
        this.form.value.phoneNumber = `+${this.form.value.countryCode}${this.form.value.phoneNumber}`;
      }

      this.authenticationService.confirmPasswordChange(this.route.snapshot.params.token, this.form.value).subscribe(async () => {
        await this.router.navigateByUrl('/login', { state: { passwordReset: true } });
      });
    }
  }

  public valueAscOrder(a: KeyValue<string, string>, b: KeyValue<string, string>): number {
    return a.value.localeCompare(b.value);
  }

  private createFormForNewUsers() {
    return new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(64), Validators.pattern(PASSWORD_PATTERN)]),
    });
  }

  private createFormForMigratingUsers() {
    return new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(64), Validators.pattern(PASSWORD_PATTERN)]),
      sector: new UntypedFormControl(null, [Validators.required]),
      countryCode: new UntypedFormControl('44'),
      phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(20)]),
    });
  }
}
