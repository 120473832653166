import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import { Attribution, Zoom } from 'ol/control';
import { createEmpty, extend, isEmpty } from 'ol/extent';
import { defaults as defaultInteractions } from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { Subject, of } from 'rxjs';
import { takeUntil, timeoutWith } from 'rxjs/operators';

import { MapManagerService } from '../../services/manager.service';
import { AVAILABLE_PROJECTIONS } from '../../utils/projections.utils';

export interface MapProjection {
  view: string;
  result: string;
  calculate: string;
}

@Component({
  selector: 'la-map-renderer',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [MapManagerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() controls = true;
  @Input() projection: MapProjection = { view: 'EPSG:27700', result: 'EPSG:27700', calculate: 'EPSG:27700' };
  @Input() initialExtent: [number, number, number, number];
  @Input() scale: number = 1;
  @Input() photoVisibility: boolean = true;
  @Input() photoScale: number = 1;

  @Output() init = new EventEmitter<MapManagerService>();
  @Output() resolution = new EventEmitter<number>();

  @Output() renderComplete = new EventEmitter<void>();

  public instance: Map;

  private source$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(private el: ElementRef, private manager: MapManagerService) {}

  ngOnInit(): void {
    const layers = [];
    const target = this.el.nativeElement.firstElementChild;
    const view = new View(AVAILABLE_PROJECTIONS[this.projection.view]);
    const controls = this.controls ? [new Zoom(), new Attribution({ collapsible: false })] : [];
    const interactions = defaultInteractions({
      doubleClickZoom: false,
      mouseWheelZoom: this.controls,
      shiftDragZoom: this.controls,
      dragPan: this.controls,
    });

    this.instance = new Map({
      controls,
      interactions,
      layers,
      target,
      view,
    });

    this.instance.once('rendercomplete', () => this.source$.next());

    this.instance.getView().on('change:resolution', (event) => this.resolution.emit(event.target.getResolution()));

    this.instance.on('pointermove', (event) => {
      try {
        // Catch false positive case when hovering features while deleting them.
        const hit = this.instance.hasFeatureAtPixel(event.pixel, { layerFilter: (layer) => layer.getProperties().selectable });
        this.instance.getViewport().style.cursor = hit ? 'pointer' : '';
      } catch (error) {
        return;
      }
    });

    this.manager.instance = this.instance;
    this.manager.projection = this.projection;

    this.init.emit(this.manager);

    this.source$.pipe(timeoutWith(15000, of(null)), takeUntil(this.destroy$)).subscribe(() => this.renderComplete.emit());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const rects = (this.el.nativeElement.firstElementChild as HTMLDivElement).getClientRects();
      const { width, height } = rects.item(0);

      if (this.initialExtent) {
        this.instance.getView().fit(this.initialExtent, { size: [width, height] });
      } else {
        const extent = this.getCombinedExtent();

        if (extent) {
          this.instance.getView().fit(extent, { maxZoom: 13, size: [width, height], padding: [100, 100, 100, 100] });
        }
      }

      this.instance.updateSize();
    });
  }

  ngOnDestroy() {
    if (this.instance) {
      this.instance.setTarget(null);
      this.instance.dispose();
      this.instance = undefined;

      this.manager.instance = undefined;
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes?.scale && !changes?.scale?.firstChange) ||
      (changes?.photoScale && !changes?.photoScale?.firstChange) ||
      (changes?.photoVisibility && !changes?.photoVisibility?.firstChange)
    ) {
      if (!this.instance) {
        return;
      }

      this.flattenLayers().forEach((layer) => layer.changed());

      if (this.initialExtent) {
        const rects = (this.el.nativeElement.firstElementChild as HTMLDivElement).getClientRects();
        const { width, height } = rects.item(0);

        this.instance.getView().fit(this.initialExtent, { size: [width, height] });
      }
    }
  }

  private getCombinedExtent() {
    const fullExtent = createEmpty();

    this.flattenLayers()
      .filter((layer) => layer instanceof VectorLayer)
      .map((layer) => (layer as VectorLayer).getSource().getExtent())
      .filter((nextExtent) => !nextExtent.some((n) => n === Infinity))
      .filter((nextExtent) => !nextExtent.some((n) => isNaN(n)))
      .forEach((nextExtent) => extend(fullExtent, nextExtent));

    return isEmpty(fullExtent) ? null : fullExtent;
  }

  private flattenLayers() {
    const layers: BaseLayer[] = [];

    this.instance
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if (layer instanceof LayerGroup) {
          layer
            .getLayers()
            .getArray()
            .forEach((subLayer) => layers.push(subLayer));
        } else {
          layers.push(layer);
        }
      });

    return layers;
  }
}
