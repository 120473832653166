import rhumbBearing from '@turf/rhumb-bearing';

function toRadians(angle: number) {
  return angle * (Math.PI / 180);
}

function toDegrees(radians: number) {
  return radians * (180 / Math.PI);
}

export function sphericalGeneratePoint(a: number[], b: number[], width: number): number[] {
  const bearing = toRadians(rhumbBearing(a, b));
  const R = 6378.137;

  const lon1 = toRadians(a[0]);
  const lat1 = toRadians(a[1]);

  const lat2 = Math.asin(Math.sin(lat1) * Math.cos(width / R) + Math.cos(lat1) * Math.sin(width / R) * Math.cos(bearing));
  const lon2 =
    lon1 + Math.atan2(Math.sin(bearing) * Math.sin(width / R) * Math.cos(lat1), Math.cos(width / R) - Math.sin(lat1) * Math.sin(lat2));

  return [toDegrees(lon2), toDegrees(lat2)];
}
