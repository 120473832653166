import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap } from 'rxjs/operators';

import { catchAppError, CreditsApiService, UserApiService } from '@core/api';
import { ChargebeeService } from '@core/providers';
import { paymentApiActions } from '@core/store';
import { creditsActions, creditsApiActions } from '../actions/credits.actions';
import { CreditsDialogsService } from '../services/credits-dialogs.service';

@Injectable()
export class CreditsEffects {
  fetchCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(creditsActions.fetchCredits),
      switchMap(() =>
        this.creditsApiService.fetch().pipe(
          map(({ balance }) => creditsApiActions.fetchCreditsSuccess({ balance })),
          catchAppError((error) => creditsApiActions.fetchCreditsFails({ error }))
        )
      )
    )
  );

  openBuyCreditsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(creditsActions.openBuyCreditsDialog),
      exhaustMap(() =>
        this.dialogs
          .openTopUpDialog()
          .afterClosed()
          .pipe(
            filter((status) => !status),
            map(() => creditsActions.cancelBuyCreditsDialog())
          )
      )
    )
  );

  buyCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(creditsActions.buyCredits),
      switchMap(({ credits }) =>
        this.creditsApiService.buy(credits).pipe(
          map(({ balance }) => creditsApiActions.buyCreditsSuccess({ balance })),
          catchAppError((error) => creditsApiActions.buyCreditsFails({ error }))
        )
      )
    )
  );

  updatePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(creditsActions.updatePaymentMethod),
      switchMap(() =>
        this.chargebee.updatePaymentMethod().pipe(
          switchMap(() =>
            this.userApiService.getPaymentMethodValidity().pipe(
              map(() => paymentApiActions.fetchPaymentSuccess()),
              catchAppError((error) => paymentApiActions.fetchPaymentFail({ error }))
            )
          ),
          catchAppError((error) => paymentApiActions.fetchPaymentFail({ error }))
        )
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialogs: CreditsDialogsService,
    private chargebee: ChargebeeService,
    private userApiService: UserApiService,
    private creditsApiService: CreditsApiService
  ) {}
}
