import { TinyColor } from '@ctrl/tinycolor';
import Feature from 'ol/Feature';
import GeometryCollection from 'ol/geom/GeometryCollection';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import RenderFeature from 'ol/render/Feature';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { flatten } from '~/libs/geometry/flatten';

export const selectStyle: Style = new Style({
  stroke: new Stroke({
    color: new TinyColor('#ffffff').setAlpha(0.8).toRgbString(),
    width: 20,
    lineCap: 'round',
    lineJoin: 'round',
  }),
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ffffff',
    }),
    stroke: new Stroke({
      color: new TinyColor('#ffffff').setAlpha(0.8).toRgbString(),
      width: 15,
    }),
  }),
});

export const pickedStyle: Style = new Style({
  fill: new Fill({ color: 'rgba(242, 133, 34, 0.25)' }),
  stroke: new Stroke({ color: '#f28522', width: 1 }),
  image: new Circle({
    radius: 4,
    fill: new Fill({ color: 'rgba(242, 133, 34, 0.25)' }),
    stroke: new Stroke({ color: '#f28522' }),
  }),
});

export const modifyStyle = new Style({
  geometry: (geomFeature: Feature | RenderFeature) => {
    const geometry = geomFeature.getGeometry();

    if (
      geometry instanceof Point ||
      geometry instanceof MultiPoint ||
      geometry instanceof LineString ||
      geometry instanceof MultiLineString ||
      geometry instanceof Polygon ||
      geometry instanceof MultiPolygon
    ) {
      const points = flatten(geometry.getCoordinates());
      return points ? new MultiPoint(points) : undefined;
    }

    if (geometry instanceof GeometryCollection) {
      let points = [];

      geometry.getGeometries().forEach((singleGeometry) => {
        if (
          singleGeometry instanceof Point ||
          singleGeometry instanceof LineString ||
          singleGeometry instanceof Polygon ||
          singleGeometry instanceof MultiPolygon
        ) {
          points = [...points, ...flatten(singleGeometry.getCoordinates())];
        }
      });

      return new MultiPoint(points);
    }

    return undefined;
  },
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ffffff',
    }),
    stroke: new Stroke({
      color: '#d40058',
      width: 2,
    }),
  }),
});

export const modificationPointStyle: Style = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#d40058',
    }),
    stroke: new Stroke({
      color: '#d40058',
      width: 2,
    }),
  }),
  zIndex: Infinity,
});

export const rotateAnchorStyle: Style = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#ffffff',
    }),
    stroke: new Stroke({
      color: '#d40058',
      width: 2,
    }),
  }),
  zIndex: Infinity,
});

export const rotateArrowStyle: Style = new Style({
  stroke: new Stroke({
    color: '#d40058',
    width: 5,
    lineDash: [5, 10],
  }),
  text: new Text({
    font: '14px sans-serif',
    offsetX: 25,
    offsetY: -25,
    fill: new Fill({
      color: 'blue',
    }),
    stroke: new Stroke({
      color: '#ffffff',
      width: 3,
    }),
  }),
  zIndex: Infinity,
});
