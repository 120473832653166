import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Snap } from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';

import { MapComponent } from '../map/map.component';

@Component({
  selector: 'la-map-snap',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSnapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() active: boolean;

  private readonly interactions: { [key: string]: Snap } = {};

  constructor(private host: MapComponent) {}

  ngOnInit(): void {
    const layers = this.host.instance.getLayers();

    layers.forEach((layer) => this.addInteraction(layer));

    layers.on('add', (event) => this.addInteraction(event.element));
    layers.on('remove', (event) => this.removeInteraction(event.element));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && !changes.active.firstChange) {
      for (const layerId of Object.keys(this.interactions)) {
        this.setActiveState(this.getLayerById(layerId));
      }
    }
  }

  ngOnDestroy(): void {
    Object.keys(this.interactions).forEach((id) => {
      this.host.instance.removeInteraction(this.interactions[id]);
      delete this.interactions[id];
    });
  }

  private addInteraction(layer: BaseLayer) {
    if (layer instanceof VectorLayer) {
      const interaction = new Snap({ source: layer.getSource() });
      interaction.setActive(this.active);
      this.host.instance.addInteraction(interaction);
      this.interactions[layer.getProperties().id] = interaction;
      this.setActiveState(layer);

      layer.on('change:visible', (event) => this.setActiveState(event.target));
    }
  }

  private removeInteraction(layer: BaseLayer) {
    if (layer instanceof VectorLayer) {
      const layerId = layer.getProperties().id;
      const interaction = this.interactions[layerId];
      this.host.instance.removeInteraction(interaction);
      delete this.interactions[layerId];
    }
  }

  private setActiveState(layer: BaseLayer) {
    if (layer instanceof VectorLayer) {
      const { id } = layer.getProperties();
      const visible = layer.getVisible();

      if (this.interactions[id]) {
        this.interactions[id].setActive(this.active ? visible : false);
      }
    }
  }

  private getLayerById(id: string) {
    return this.host.instance
      .getLayers()
      .getArray()
      .find((layer: BaseLayer) => {
        const properties = layer.getProperties();
        return properties && properties.id === id;
      }) as BaseLayer;
  }
}
