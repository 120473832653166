import Feature from 'ol/Feature';
import GeoJSONFormat from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import GeometryCollection from 'ol/geom/GeometryCollection';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';

const format = new GeoJSONFormat();

export function toGeoJSON(feature: Feature): GeoJSON.Feature {
  return format.writeFeatureObject(feature);
}

export function fromGeoJSON(json: GeoJSON.Feature): Feature {
  return format.readFeature(json);
}

export function geometryFromGeoJSON(json: GeoJSON.Geometry): Geometry {
  return format.readGeometry(json);
}

export function toGeoJSONGeometry(feature: Feature | Geometry): GeoJSON.Geometry {
  const geometry = feature instanceof Feature ? feature.getGeometry() : feature;

  if (geometry instanceof Point) {
    return { type: 'Point', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof MultiPoint) {
    return { type: 'MultiPoint', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof LineString) {
    return { type: 'LineString', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof MultiLineString) {
    return { type: 'MultiLineString', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof Polygon) {
    return { type: 'Polygon', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof MultiPolygon) {
    return { type: 'MultiPolygon', coordinates: geometry.getCoordinates() };
  }

  if (geometry instanceof GeometryCollection) {
    return { type: 'GeometryCollection', geometries: geometry.getGeometries().map((g) => toGeoJSONGeometry(g)) };
  }

  return undefined;
}
