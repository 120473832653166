import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogContentComponent } from './components/dialog-content/dialog-content.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule],
  declarations: [YesNoDialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent],
  exports: [MatDialogModule, YesNoDialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent],
})
export class DialogsModule {}
