import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ErrorsModule } from '~/shared/errors';
import { LayoutModule } from '~/shared/layout';

import { AuthRoutingModule } from './auth-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ActivationRequiredComponent } from './components/notifications/activation-required/activation-required.component';
import { ResetPasswordRequiredComponent } from './components/notifications/reset-password-required/reset-password-required.component';
import { ActivatePageComponent } from './pages/activate-page/activate-page.component';
import { ChangeUserEmailSuccessComponent } from './pages/change-user-email-success/change-user-email-success.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { JoinOrganizationPageComponent } from './pages/join-organization-page/join-organization-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { UserSetupPageComponent } from './pages/user-setup-page/user-setup-page.component';
import { effects, featureKey, reducers } from './store';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ActivationRequiredComponent,
    ResetPasswordRequiredComponent,
    ActivatePageComponent,
    ForgotPasswordPageComponent,
    UserSetupPageComponent,
    LoginPageComponent,
    LogoutPageComponent,
    RegisterPageComponent,
    ResetPasswordPageComponent,
    JoinOrganizationPageComponent,
    ChangeUserEmailSuccessComponent,
  ],
  imports: [
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(effects),
    CommonModule,
    ErrorsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    LayoutModule,
    MatCheckboxModule,
  ],
})
export class AuthModule {}
